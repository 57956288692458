import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/appContext';
import style from './style.module.scss';
import 'swiper/swiper.scss';

import Footer from '../../components/Footer';
import RewardCard from '../../components/RewardCard';
import ArticleListPreview from '../../components/ArticleListPreview';
import { Loyalty } from '../../Api';
import './gigya-form.css';

const RewardCategory = () => {
    let { rewardCategoryId } = useParams();
    const { state, dispatch } = useContext(AppContext);
    const [reward, setRewards] = useState([]);
    const [userTotalPoints, setUserTotalPoints] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [loading, setLoading] = useState(true);
    let type = '';

    const openDialog = (points, rewardId, link, spesaMin) => {
        if (userTotalPoints < points) {
            dispatch({
                type: 'OPEN_PRIZE_MODAL',
                payload: {
                    onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                    type: 'type_coupon_3',
                    text:
                        'Non hai abbastanza punti per redimere questo premio. Partecipa alle altre iniziative per accumulare punti D',
                },
            });
        } else {
            switch (categoryName) {
                case 'Pacco prodotto':
                    type = 'material';
                    break;
                case 'Sconto Henkel':
                    type = 'coupon';
                    break;
                case 'Esperienze':
                    type = 'code';
                    break;
                case 'Codici sconto':
                    type = 'code';
                    break;
                default:
            }

            if (type == 'material' && categoryName == 'Pacco prodotto') {
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text: `<p>Vuoi riscattare il pacco prodotti Henkel per il valore di 12.000 punti D?</p> 
                                <p>Nella tua box, troverai un assortimento dei seguenti prodotti: Dixan, Pril, Nelsen, Vernel,
                                L’Acchiappacolore, Bref WC, Neutromed e Gliss.</p>
                                <p>Che aspetti?</p>`,
                        onClick: () => {
                            Loyalty.post('/buy-reward', {
                                reward_id: rewardId,
                                type: type,
                            }).then((res) => {
                                if(res.data.success){
                                    var element = document.getElementById("form-container");
                                    element.style.display = "block";
                                    dispatch({
                                        type: 'OPEN_PRIZE_MODAL',
                                        payload: {
                                            text:
                                                `<p>Grazie per aver riscattato il premio.</p>
                                                <p>Compila il form con tutti i dati richiesti per riceverlo entro massimo 180 giorni direttamente all'indirizzo da te indicato</p>`,                                
                                            onClick: () => {
                                                window.location.href = '#form-container';
                                                //formEventListner();
                                                dispatch({ type: 'CLOSE_PRIZE_MODAL' });
                                            },
                                            disableClose: true,
                                            type: 'type_coupon_7',
                                        },
                                        
                                    });
                                }                   
                            });
                        },
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_6',
                    },
                });
            } else if (type == 'coupon' && categoryName == 'Sconto Henkel') {
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text: `<p>Puoi trovare il buono sconto selezionato nella <b>tua area personale «I miei coupon»</b> su MyDonnaD</p>`,
                        onClick: () => {
                            Loyalty.post('/buy-reward', {
                                reward_id: rewardId,
                                type: type,
                            }).then((res) => {
                                if (res.data.success) {
                                    window.location.href = '/coupons';
                                }
                            });
                        },
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_2',
                    },
                });
            } else if (type == 'code' && categoryName == 'Codici sconto') {
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text: `<p>Puoi trovare il codice sconto selezionato nella <b>tua area personale «I miei codici»</b> su MyDonnaD</p>`,
                        onClick: () => {
                            Loyalty.post('/buy-reward', {
                                reward_id: rewardId,
                                type: type,
                            }).then((res) => {
                                if (res.data.success) {
                                    let code = res.data.code_value;
                                    dispatch({
                                        type: 'OPEN_PRIZE_MODAL',
                                        payload: {
                                            text:
                                                `<p>Ecco il tuo codice da usare<br> Ricordati di copiarlo e conservarlo con cura:</p>` +
                                                code,
                                            onClick: () => {
                                                window.location.href = link;
                                            },
                                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                                            type: 'type_coupon_5',
                                            shopping: spesaMin,
                                        },
                                    });
                                    
                                }
                            });
                        },
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_2',
                    },
                });
                
            } else if (type == 'code' && categoryName == 'Esperienze') {
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text: `<p>Puoi trovare il codice sconto selezionato nella <b>tua area personale «I miei codici»</b> su MyDonnaD</p>`,
                        onClick: () => {
                            Loyalty.post('/buy-reward', {
                                reward_id: rewardId,
                                type: type,
                            }).then((res) => {
                                if (res.data.success) {
                                    let code = res.data.code_value;
                                    dispatch({
                                        type: 'OPEN_PRIZE_MODAL',
                                        payload: {
                                            text:
                                                `<p>Ecco il tuo codice!<br> Ricordati di copiarlo e conservarlo con cura:</p>` +
                                                code,
                                            onClick: () => {
                                                window.location.href = 'https://it.rewardsforall.com/';
                                            },
                                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                                            type: 'type_coupon_4',
                                        },
                                    });
                                }
                            });
                        },
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_2',
                    },
                });
                
            }
        }
    };

    const getUserFanStatus = () => {
        Loyalty.get('/status').then(({ data }) => {
            setUserTotalPoints(data.data.points);
        });
    };

    const getReward = () => {
        Loyalty.get('/rewards', {
            params: {
                categories: rewardCategoryId,
                active: true,
            },
        }).then((response) => {
            setRewards(response.data.items);
            setCategoryName(
                response.data.items[0].categoryNames[response.data.items[0].categories]
            );
            setLoading(false);
        });
    };

    const showForm = () => {
        window.gigya.accounts.showScreenSet({
            screenSet: 'Restyle2017-ProfileUpdate',
            startScreen: 'gigya-update-profile-prod-Loyalty2023-PrizeBoxRequest',
            containerID: 'gigya-form-container',
        });
    };

    /* const formEventListner = () => {
        let gigyaForm = document.getElementById("gigya-profile-form");
        gigyaForm.addEventListener("submit", function(e){
            //e.preventDefault();    //stop form from submitting
            console.log(e);
            console.log("submit");
            let element = document.querySelectorAll('input[type=submit]');
            console.log(element);
            element.classList.add("disabled");
            setTimeout(()=>{
                element.classList.remove("disabled");
            },6000);
        });
    }; */

    const gigyaSaveUserCampaigns = () => {
        let formData = new FormData();
        formData.append('name', '2023 - LOYALTY PACCO PRODOTTO');
        formData.append('category', "LAUNDRY");
        formData.append('type', "BRANDCONTEST");
        formData.append('brand', "DIXAN");
        formData.append('wave', "2306");

        Loyalty.post('/gigya-save-user-campaigns',formData).then((response) => {
            if(response.data.status.statusCode == 200){
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Grazie per aver compilato il form correttamente con i tuoi dati. Riceverai il pacco prodotti Henkel all'indirizzo da te indicato entro massimo 180 giorni.</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),                    
                        type: 'type_coupon_8',
                    },
                });
            }else{
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Ops, c'è stato un problema, contatta il supporto</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_8',
                    },
                });
            }
        });
    };

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'prizes' } });
        if (state.user) {
            //refreshPrizes();
            getUserFanStatus();
            getReward(); 
        }
        showForm();
        document.addEventListener( "click", someListener );
        function someListener(event){
            var element = event.target;
            if(element.classList.contains("gigya-input-submit")){
                element.classList.add("disabled");
                setTimeout(()=>{
                    element.classList.remove("disabled");
                },6000);
            }   
        }
        window.addEventListener('gigyaScreensetAfterSubmit', function (e) {
            if(e.returnValue){
                gigyaSaveUserCampaigns();
                var element = document.getElementById("form-container");
                element.style.display = "none";
            }else{
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Ops, c'è stato un problema, contatta il supporto</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_8',
                    },
                });
            } 
        });
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                {categoryName != 'Esperienze' ? (
                    <title>Buoni sconto - Prodotti Henkel</title>
                ) : (
                    <title>Codici Esperienze</title>
                )}
            </Helmet>
            <div className="content">
                <div className={style.data}>
                    {categoryName == 'Esperienze' ? (
                        <p dangerouslySetInnerHTML={{ __html: `Scegli la tua esperienza:` }}></p>
                    ) : categoryName == 'Sconto Henkel' ? (
                        <p dangerouslySetInnerHTML={{ __html: `Scegli il tuo buono sconto:` }}></p>
                    ) : categoryName == 'Pacco prodotto' ? (
                        <p dangerouslySetInnerHTML={{ __html: `Riscatta il tuo pacco:` }}></p>
                    ) : (
                        <p dangerouslySetInnerHTML={{ __html: `Scegli il tuo codice sconto:` }}></p>
                    )}
                </div>
                <div className={style.cardContainer}>
                    {!loading? 
                        reward.map((re, j) => (
                        <RewardCard
                            key={j}
                            image={
                                `${process.env.REACT_APP_STATIC_CONTENT}` +
                                '/asset/reward/' +
                                re.rewardId +
                                '.png?'+ Math.floor(Math.random() * 100000)
                            }
                            points={re.costInPoints}
                            description={re.shortDescription}
                            name={categoryName != 'Esperienze' ? re.name : ''}
                            cta={
                                categoryName === 'Pacco prodotto'
                                ? {
                                      label: 'OTTIENI IL PACCO',
                                      onClick: () => openDialog(re.costInPoints, re.rewardId),
                                      modifiers: ['bg-corporate'],
                                  }:
                                re.usageLeft > 0 && categoryName === 'Esperienze'
                                    ? {
                                          label: 'OTTIENI IL CODICE',
                                          onClick: () => openDialog(re.costInPoints, re.rewardId),
                                          modifiers: ['bg-corporate'],
                                      }
                                    : re.usageLeft > 0 && categoryName === 'Codici sconto'
                                    ? {
                                          label: 'OTTIENI SCONTO',
                                          onClick: () =>
                                              openDialog(
                                                  re.costInPoints,
                                                  re.rewardId,
                                                  re.labels[1].value,
                                                  re.labels[2].value
                                              ),
                                          modifiers: ['bg-corporate'],
                                      }
                                    : re.usageLimit.general == -1 || re.usageLeft > 0
                                    ? {
                                          label: 'OTTIENI SCONTO',
                                          onClick: () => openDialog(re.costInPoints, re.rewardId),
                                          modifiers: ['bg-corporate'],
                                      }
                                    : categoryName != 'Esperienze'
                                    ? {
                                          label: 'COUPON ESAURITI',
                                          disabled: true,
                                      }
                                    : {
                                          label: 'CODICI ESAURITI',
                                          disabled: true,
                                      }
                            }
                        ></RewardCard>
                    )): <ArticleListPreview />}
                </div>               
            </div>
            <div id="form-container" className={style.form}>
                <h3 className={style.form_title}>Compila il form con i dati richiesti per ricevere il pacco prodotti Henkel direttamente all’indirizzo da te indicato</h3>
                <div id="gigya-form-container"></div>
            </div>
            <Footer />
        </div>
    );
};

export default RewardCategory;
