import React from 'react';
import style from './style.module.scss';

const HeadingPage = ({title, text , anchor}) => {
    return (
        <div className={style.el} id={anchor}>
            <span dangerouslySetInnerHTML={{__html: title}}></span>
            { text && <p dangerouslySetInnerHTML={{__html: text}}></p> }
        </div>
    );
}

export default HeadingPage;
