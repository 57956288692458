import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import Cta from '../Cta';
import LinkTo from '../LinkTo';

const CategoryCard = ({ title, textTitle, points, description, cta, image, modifiers, link, info, active }) => {
    let styleCard = [style.card];

    if (modifiers) {
        styleCard.push(modifiers.map((modifier) => style[modifier]));
    }
    
    const classes = classNames(styleCard, {
        [style.active]: active,
    });

    return (
        <div className={classes}>
            <div className={style.image}>
                <div className={style.background}>
                    <LazyImg src={image && (image.src)} alt="backgroundImage" />               
                </div>
                <h3>{active? textTitle : "Coming Soon"}</h3>
            </div>
            <div className={style.content}>
                <div className={style.text}>
                    {<p>{description[0]? description[0] : ""}</p>}
                    {<p>{description[1]? description[1] : ""}</p>}
                    {<p>{description[2]? description[2] : ""}</p>} 
                </div>       
                {cta && <Cta {...cta} />}
                {link && <LinkTo {...link} />}
            </div>
        </div>
    );
};

export default CategoryCard;
