import React, { useEffect, useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import AppContext from '../../contexts/appContext';
import { isLoyaltyActive } from '../../utils';
import { Loyalty as LoyaltyAPI } from '../../Api';
import UserDashboard from '../../components/UserDashboard';
import Footer from '../../components/Footer';
import Wheel from '../../components/Wheel';
import BrandCards from '../../components/BrandCards';
import TextureCard from '../../components/TextureCard';
import Card from '../../components/Card';
import CardReward from '../../components/CardReward';
import AccordionComponent from '../../components/AccordionComponent';

import LoyaltyCard from '../../components/LoyaltyCard';
import LoyaltyCardExpired from '../../components/LoyaltyCardExpired';
import WelcomeCard from '../../components/WelcomeCard';

import HoroscopeHp from './horoscope';
import EventsHp from './events';
import Latest from './latest';
import ToolCards from './toolCards';
import InstallApp from './installApp';
import {installAndroidApp, appInstalledEvents} from '../../utils/InstallApp';
import { isIOS } from '../../utils';
import style from './style.module.scss';
import Actions from '../../components/LoyaltySwitch';
import LoyaltySwitch from '../../components/LoyaltySwitch';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Home = () => {
    let query = useQuery();
    const { state, dispatch } = useContext(AppContext);
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' }, undefined, () => {
        window._neoHenkel && window._neoHenkel.B.refresh();
    });

    const [deferredPrompt, setDeferredPrompt] = useState(false);

    
    const [loyaltySwitch, setLoyaltySwitch] = useState(1);
    const [actions, setActions] = useState([]);
    const actionsCompleted = useRef([]);
    const [categories, setCategories] = useState([]);
    const [points, setPoints] = useState('');
    const [preferredRewards, setPreferredRewards] = useState([]);

    let loyaltyEndDateBeforeToday = false;
    if (state.loyalty_status === false && typeof state.end_prizes !== 'undefined') {
        const d1 = new Date(state.end_prizes).getTime();
        const d2 = new Date().getTime();
        if (d1 < d2) {
            loyaltyEndDateBeforeToday = true;
        }
    }

    useEffect(() => {
        window._neoHenkel.B.setLocationBase('hen_don_hom_all')
            .setFormat('fmt_personalarea_henkel')
            .addPosition('970x250', 'DotnAd_mh_u')
            .addPosition('300x250_right2', 'DotnAd_box_right2')
            .addPosition('300x600_right1', 'DotnAd_box_right1')
            .addPosition('300x250_right3', 'DotnAd_box_right3')
            .addPosition('ovl_u', 'DotnAd_ovl_u');
        window._neoHenkel.B.refresh();
    }, []);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'home' } });

        if(!!state.user && state.loyalty_status === true && isLoyaltyActive(state)) {

            LoyaltyAPI.get('/status').then(({ data }) => {
                setPoints(data.data.points);
            });

            //AZIONI
            if (window.gigya && !!state.user && !!state.user.id) {
                var url = `${process.env.REACT_APP_DONNAD_DOMAIN}/loyalty-member-get-member?UID=${state.user.id}&name=${encodeURIComponent(state.user.firstName)}`;
            }
            
            var actions_list = [
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-scontrino.png',
                        name: 'scontrino',
                    },
                    points: '600',
                    category: 'azioni',
                    title: 'Non buttarlo, caricalo!',
                    description: 'Carica lo scontrino con acquisti Henkel. Per te 600 punti D.',
                    cta: {
                        label: 'Carica lo scontrino',
                        onClick: () => dispatch({ type: 'TOGGLE_RECEIPTS' }),
                        modifiers: ['bg-corporate','small'],
                    },
                    event_name: 'receipt accepted',
                    completed: {
                        title: 'Hai caricato uno scontrino',
                        description: 'Il tuo scontrino è stato validato e accettato dai nostri sistemi. Ricorda di caricarne altri per guadagnare punti D.',
                        cta: {
                            label: 'Visualizza scontrini',
                            onClick: () => { window.location.href = '/scontrini' },
                            modifiers: ['bg-corporate','small'],
                        }, 
                    }
                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-friends.png',
                        name: 'scontrino',
                    },
                    points: '500',
                    category: 'azioni',
                    title: 'Più amici, più punti D',
                    description: 'Per ogni amico che si registra su DonnaD e partecipa al programma fedeltà, ricevi 500 punti D, mentre il tuo amico ne riceverà 250.',
                    cta: {
                        label: 'Invita un amico',
                        onClick: () => dispatch({ type: 'TOGGLE_SOCIALSHARE', payload: { url: url } }),
                        modifiers: ['bg-corporate','small'],
                    },
                    id: 'friends',
                    event_name: 'member get member'/*'mgm referral'*/,
                    completed: {
                        title: 'La Dfamily ha un amico/a in più',
                        description: 'Hai guadagnato 500 punti D.',
                    }
                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-5scontrini.png',
                        name: 'scontrino',
                    },
                    points: '100',
                    category: 'azioni',
                    title: '5 scontrini sono meglio di 1',
                    description: 'Carica 5 scontrini di prodotti Henkel e guadagnerai subito 100 punti D extra.',
                    cta: {
                        label: 'Carica i tuoi scontrini',
                        onClick: () => dispatch({ type: 'TOGGLE_RECEIPTS' }),
                        modifiers: ['bg-corporate','small'],
                    },
                    event_name: 'receipt accepted - points after 5 uploads',
                    completed: {
                        title: 'Hai caricato 5 scontrini',
                        description: 'Hai ricevuto 100 punti D bonus per i 5 scontrini caricati',
                    }
                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-profile.png',
                        name: 'scontrino',
                    },
                    points: '1000',
                    category: 'azioni',
                    title: 'Raccontaci un po’ di te',
                    description: 'Completa il tuo profilo inserendo le informazioni che ti riguardano. Farsi conoscere ti premia con 1000 punti D. Riceverai i punti dopo 1 giorno dal completamento del profilo.',
                    cta: {
                        label: 'Completa il profilo',
                        onClick: () => { window.location.href = '/utente' },
                        modifiers: ['bg-corporate','small'],
                    },
                    event_name: 'profile completed',
                    completed: {
                        title: 'Hai completato il tuo profilo',
                        description: 'Grazie per averci raccontato un po’ di te.'
                    }

                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-concorsi.png',
                        name: 'scontrino',
                    },
                    points: '300',
                    category: 'azioni',
                    title: 'Scopri i concorsi che ti fanno guadagnare punti D',
                    description: 'Non perderti i concorsi lanciati dalla tua Amica Fidata. Scopri i concorsi che ti fanno guadagnare punti D seguendo il bollino Dfamily.',
                    cta: {
                        //label: 'Vedi tutti i concorsi',
                        label: 'Vai ai concorsi',
                        onClick: () => { window.location.href = 'https://concorsi.donnad.it/' },
                        modifiers: ['bg-corporate','small'],
                    },
                    event_name: 'concorsi',
                    completed: {
                        title: 'Hai partecipato al concorso ',
                        description: 'Non perderti i concorsi lanciati dalla tua Amica Fidata', 
                    }
                },
                {
                    show_action_todo: false,
                    image: {
                        src:'/imgs/texture/action-specchiomagico.png',
                        name: 'scontrino',
                    },
                    points: '200',
                    category: 'azioni',
                    title: 'Cosa nasconde lo Specchio Magico?',
                    description: 'Togli il vapore dallo Specchio Magico e guadagni 200 punti D.',
                    event_name: 'scratch game',
                    completed: {
                        title: 'Hai partecipato allo Specchio Magico',
                        description: '',
                    }            
                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-login.png',
                        name: 'scontrino',
                    },
                    points: '100',
                    category: 'azioni',
                    title: 'Il login ti fa guadagnare punti D',
                    description: 'Rimani attivo su DonnaD e partecipa alle tante iniziative e promozioni. Ogni due settimane riceverai 100 punti D per premiarti della tua partecipazione. Che aspetti?',
                    event_name: 'points login donnad',
                    completed: {
                        title: 'Ti sei loggato su DonnaD nelle ultime due settimane',
                        description: 'Grazie per rimanere sempre attivo e partecipare alle iniziative',
                    }
                },
                {
                    show_action_todo: true,
                    image: {
                        src:'/imgs/texture/action-celebration.png',
                        name: 'scontrino',
                    },
                    points: '200',
                    category: 'azioni',
                    title: 'Un\'amicizia da festeggiare',
                    description: 'Per noi la tua fedeltà ha un grande valore. Per questo dopo 6 mesi dall\'iscrizione alla Dfamily guadagni automaticamente 200 punti D.',
                    event_name: 'loyalty member celebration',
                    completed: {
                        title: 'Grazie per essere con noi da 6 mesi',
                        description: 'Per noi la tua fedeltà ha un grande valore. Per questo dopo 6 mesi dall’iscrizione hai guadagnato 300 punti D.',
                    } 
                },
                {
                    show_action_todo: false,
                    image: {
                        src:'/imgs/texture/action-mobileapp.png',
                        name: 'scontrino',
                    },
                    points: '150',
                    category: 'azioni',
                    title: '',
                    description: '',
                    event_name: 'welcome',
                    completed: {
                        title: 'Grazie per aver aderito alla Dfamily',
                        description: 'È bellissimo averti con noi! Non perderti tutte le azioni per guadagnare punti D e redimere i premi.',
                    }
                },
                {
                    show_action_todo: false,
                    image: {
                        src:'/imgs/texture/action-mobileapp.png',
                        name: 'scontrino',
                    },
                    points: '300',
                    category: 'azioni',
                    title: 'Porta MyDonnaD in giro con te',
                    description: 'Scarica MyDonnaD sul tuo cellulare e porta la Dfamily sempre con te. Guadagni 300 punti D.',
                    cta: {},
                    event_name: 'app installed',
                    completed: {
                        title: 'Hai scaricato MyDonnaD',
                        description: 'La tua Amica Fidata è sempre al tuo fianco! Grazie per portare MyDonnaD sempre con te sul tuo cellulare.'
                    }
                },
                {
                    show_action_todo: false,
                    image: {
                        src:'/imgs/texture/action-scontrino.png',
                        name: 'scontrino',
                    },
                    points: '200',
                    category: 'azioni',
                    title: '',
                    description: '',
                    cta: {},
                    event_name: 'member get member - receipt accepted',
                    completed: {
                        title: 'Il tuo/a amico/a ha caricato il suo primo scontrino!',
                        description: 'Grazie per avere portato nuovi amici nella Dfamily! Un tuo amico/a ha caricato il suo primo scontrino... 200 punti per te!'
                    }
                },
                {
                    show_action_todo: false,
                    image: {
                        src:'/imgs/texture/action-friends.png',
                        name: 'scontrino',
                    },
                    points: '100',
                    category: 'azioni',
                    title: '',
                    description: '',
                    cta: {},
                    event_name: 'member get member - points after 5 referee',
                    completed: {
                        title: 'Hai invitato 5 amici',
                        description: 'Grazie per avere portato 5 nuovi amici nella Dfamily! Hai ricevuto 100 punti D extra.'
                    }
                },
                {
                    show_action_todo: false,
                    image: {
                        src: '/imgs/texture/action-quiz.png',
                        name: 'scontrino',
                    },
                    points: '300',
                    category: 'quiz e sondaggi',
                    title: '',
                    description: '',
                    cta: {
                        label: 'Partecipa',
                        onClick: () => {},
                        modifiers: ['bg-corporate','small'],
                    }, 
                    event_name: 'survey completed',
                    completed: {
                        title: 'Hai completato la survey',
                        description: 'Grazie per aver partecipato al sondaggio'
                    }
                }
                
            ];

            //mobile app
            var isInstallable = false;
            var showMobileAction = false;
            if(!isIOS()) {
                window.addEventListener('beforeinstallprompt', (event) => {
                    // Impedisci al browser di mostrare la prompt predefinita per l'installazione dell'app
                    event.preventDefault();
                    // Memorizza l'evento per poterlo utilizzare in seguito
                    setDeferredPrompt(event);
                    // Mostra un pulsante o un messaggio per consentire all'utente di installare l'app
                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'PWA',
                            category: 'PWA',
                            action: 'Install prompt',
                            label: 'Custom installation banner',
                            noninteractive: true,
                        });
                    }
                    isInstallable = true;
                });
        
                window.addEventListener('appinstalled', (event) => {
                    appInstalledEvents();       
                });
            }
            

            showMobileAction = false;
            var cta = {};
            window.gigya.accounts.getAccountInfo({
                callback: (response) => {
                    if (response.status === 'OK') {
                        if(!response.data.membership.pwaInstallDate){
                            var isInstalled = false;
                        }else{
                            var isInstalled = true;
                        }
                        if(isInstalled){
                            showMobileAction = true;     
                            cta = {
                                label: 'portala con te',
                                modifiers: ['bg-corporate','small'],
                                onClick: () => dispatch({ 
                                    type: 'TOGGLE_MESSAGE_ALERT', 
                                    payload: {
                                        message: {
                                            title: "Hai già scaricato MyDonnaD sul tuo cellulare.",
                                            description: "Torna indietro e scopri le altre azioni per guadagnare punti D." }
                                        }                     
                                }),
                            };
                        }else{
                            if(isIOS()){
                                isInstallable = true;
                                showMobileAction = true;
                                //ios
                                cta = {
                                    label: 'portala con te',
                                    modifiers: ['bg-corporate','small'],
                                    onClick: () => { 
                                        dispatch({ type: 'OPEN_APPBANNER' });
                                        appInstalledEvents();
                                    },
                                };
                            }else{
                                //android
                                if(isInstallable){
                                    showMobileAction = true;
                                    cta = {
                                        label: 'portala con te',
                                        modifiers: ['bg-corporate','small'],
                                        onClick:  () => { installAndroidApp(deferredPrompt) },
                                    }
                                }         
                            }
                        }
                        var mobile_action = {
                            show_action_todo: showMobileAction,
                            image: {
                                src:'/imgs/texture/action-mobileapp.png',
                                name: 'scontrino',
                            },
                            points: '300',
                            category: 'azioni',
                            title: 'Porta MyDonnaD in giro con te',
                            description: 'Scarica MyDonnaD sul tuo cellulare e porta la Dfamily sempre con te. Guadagni 300 punti D.',
                            cta: cta,
                            event_name: 'app installed',
                            completed: {
                                title: 'Hai scaricato MyDonnaD',
                                description: 'La tua Amica Fidata è sempre al tuo fianco! Grazie per portare MyDonnaD sempre con te sul tuo cellulare.'
                            }
                        };
                        actions_list.push(mobile_action);
                        setActions([...actions_list]);
                    } else {
                        console.log('An error occurred');
                    }
                },
            });

            //AZIONI COMPLETATE
            LoyaltyAPI.get('/events', {
                params: {
                    _itemsOnPage: 50,
                },
            }).then(({ data }) => {
                var events = data.events.items;
                LoyaltyAPI.get('/history', {
                    params: {
                        _itemsOnPage: 50,
                        type: 'PointsWereAdded',
                    },
                }).then(({ data }) => {
                    var actions_completed = [];
                    var milestones = data.items;
                    var mgm_users = [];
                    milestones.forEach((milestone) => {
                        actions_list.forEach((action) => {
                            if(milestone.flatVariables.comment==action.event_name){
                                var newAction = {...action};
                                let newCompleted = {...newAction.completed};
                                newCompleted['date'] = dateCustom(milestone.createdAt);
                                newCompleted['points'] = milestone.flatVariables.points;
                                if(milestone.flatVariables.comment=='concorsi'){
                                    let eventBody = findEventBody(events, "concorsi", milestone.createdAt);
                                    let shown_as = eventBody.shown_as;
                                    newCompleted.title = newCompleted.title + ' ' + shown_as;                        
                                }
                                if(milestone.flatVariables.comment=='member get member'){
                                    let eventBody = findEventBody(events, "mgm referral", milestone.createdAt);
                                    let referred_uid = eventBody.referred_uid;
                                    if(referred_uid){
                                        newCompleted['referred_uid'] = referred_uid;
                                        mgm_users.push(referred_uid);
                                    }                        
                                }
                                if(milestone.flatVariables.comment=='survey completed'){
                                    let eventBody = findEventBody(events, "survey completed", milestone.createdAt);
                                    let title = eventBody.title;
                                    let image_path = eventBody.image_path;
                                    let survey_id = eventBody.survey_id;
                                    if(title && image_path){
                                        var newAction2 = {
                                            image: {
                                                src: image_path,
                                                name: 'scontrino',
                                            },
                                            category: 'quiz e sondaggi',
                                            event_name: 'survey completed',
                                            survey_id: survey_id,
                                            completed: {
                                                description: 'Grazie per aver partecipato al sondaggio',
                                            }
                                        };
                                        newAction = newAction2;
                                        newCompleted['title'] = 'Hai completato la survey ' + title;
                                    }
                                }
                                if(milestone.flatVariables.comment=='scratch game'){
                                    let eventBody = findEventBody(events, "scratch game", milestone.createdAt);
                                    let campaign_name = eventBody.campaign_name;
                                    if(campaign_name){            
                                        newAction['campaign_name'] = campaign_name;
                                    }
                                }
                                newAction['completed'] = newCompleted;
                                actions_completed.push(newAction);
                            }
                        });
                    });
                    if(mgm_users.length>0){  
                        LoyaltyAPI.get('/get-names', {
                            params: {
                                users: mgm_users,
                            },
                        }).then(({ data }) => {
                            var users = data.users;
                            actions_completed.forEach((action) => {
                                users.forEach((user) => {
                                    if(action.completed.referred_uid==user.UID){
                                        action.completed.title = action.completed.title + ": grazie per aver invitato " + user.identities[0].firstName + ".";
                                    }
                                });
                            });
                            actionsCompleted.current = actions_completed;                
                        });
                    }else{
                        actionsCompleted.current = actions_completed;
                    } 
                    //SURVEY
                    var options = {};
                    if(process.env.REACT_APP_DONNAD_DOMAIN == 'https://qa.donnad.it') {
                        options = {
                            withCredentials: true,
                        };
                    }
                    axios.get(process.env.REACT_APP_DONNAD_DOMAIN + '/api/getSurvey', options).then(({ data }) => {
                        let surveys = data;
                        surveys.forEach((survey) => {
                            let surveyAction = {
                                show_action_todo: true,
                                new: survey.new=="1" ? true : false,
                                image: {
                                    src: survey.image_path ? survey.image_path : '/imgs/texture/action-quiz.png',
                                    name: 'scontrino',
                                },
                                points: survey.islong=="1" ? '600' : '300',
                                category: 'quiz e sondaggi',
                                title: survey.title,
                                description: survey.description ? survey.description : '',
                                expiration_date: survey.expiration_date ? survey.expiration_date : '',
                                cta: {
                                    label: 'Partecipa',
                                    onClick: () => { surveyCtaClick(survey.survey_id) },
                                    modifiers: ['bg-corporate','small'],
                                }, 
                                event_name: 'survey completed',
                                completed: {
                                    title: 'Hai completato la survey',
                                    description: 'Grazie per aver partecipato al sondaggio'
                                }
                            }
                            actions_list.unshift(surveyAction);
                        });
                        setActions([]);
                        setActions([...actions_list]);
                    }).catch(error => {
                        // Gestisci gli errori qui
                        console.error('Si è verificato un errore nella richiesta:', error);
                    });
                    //SPECCHIO MAGICO
                    axios.get(process.env.REACT_APP_DONNAD_DOMAIN + '/api/getSpecchioMagico', options).then(({ data }) => {
                        let games = data;
                        games.forEach((game) => {
                            let gameAction = {
                                show_action_todo: true,
                                new: game.new=="1" ? true : false,
                                image: {
                                    src: '/imgs/texture/action-specchiomagico.png',
                                    name: 'scontrino',
                                },
                                points: '200',
                                category: 'azioni',
                                title: 'Cosa nasconde lo Specchio Magico?',
                                description: 'Togli il vapore dallo Specchio Magico e guadagni 200 punti D.',
                                expiration_date: game.expiration_date ? game.expiration_date : '',
                                cta: {
                                    label: 'Partecipa',
                                    onClick: () => { specchioMagiocoCtaClick(game.campaign_name, game.url) },
                                    modifiers: ['bg-corporate','small'],
                                },
                                event_name: 'scratch game',
                                completed: {
                                    title: 'Hai partecipato allo Specchio Magico',
                                    description: '',
                                }
                            }
                            actions_list.unshift(gameAction);
                        });
                        setActions([]);
                        setActions([...actions_list]);
                    }).catch(error => {
                        // Gestisci gli errori qui
                        console.error('Si è verificato un errore nella richiesta:', error);
                    });      
                });
            });

            //REWARDS
            LoyaltyAPI.get('/reward-categories', {
                params: {
                    active: true,
                    '_orderBy[sortOrder]': 'asc',
                },
            }).then(({ data }) => {
                var categories = data.categories.items;
                LoyaltyAPI.get('/rewards', {
                    params: {
                        active: true,
                        _itemsOnPage: 50,
                    },
                }).then(({ data }) => {
                    var rewards = data.rewards.items;
                    var rewardsImageUrl = data.rewardImageUrl;
                    rewards.forEach((reward) => {
                        categories.forEach((category) => {
                            if (category.rewardCategoryId === reward.categories[0]) {
                                
                                if (!('rewards' in category)) {                            
                                    category.rewards = [];
                                }
                                category.rewards.push(reward);
                            }
                        })
                        reward['rewardImageUrl'] = rewardsImageUrl;
                    });     
                    setCategories(categories);
                    checkPreferredReward(categories);
                });
            });

        }
        
    }, [JSON.stringify(state)]); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePreferredReward = (user,categories_list) => {
        
            var targetRewardId = user.data.loyalty[process.env.REACT_APP_LOYALTY_VERSION].preferredReward;
            categories_list.forEach((category) => {
                let rewards_list = [...category.rewards];
                rewards_list.forEach((reward) => {
                    if(reward.rewardId==targetRewardId){
                        reward.target = true;
                        setPreferredRewards([]);
                        setPreferredRewards([reward]);
                    }else{
                        reward.target = false;
                    }
                });
            });
            if(targetRewardId==""){setPreferredRewards([]);}
            setCategories(categories_list);
        
    }

    const checkPreferredReward = (categories_list = []) => {
        if(categories_list.length==0){
            categories_list = categories;
            window.gigya.accounts.getAccountInfo({
                callback: (response) => {
                    if (response.status === 'OK') {
                        updatePreferredReward(response,categories_list);
                    } else {
                        console.log('An error occurred');
                    }
                },
            });
        }else{
            let user = state.user;
            updatePreferredReward(user,categories_list);
        }
    }


    const findEventBody = (events, event_to_find, milestone_date) => {
        let eventBody = '';
        events.forEach((event) => {
            if(event.eventName==event_to_find){
                let primaData = new Date(event.createdAt).getTime();
                let secondaData = new Date(milestone_date).getTime();
                if (primaData <= secondaData && (primaData + 50000) >= secondaData ) {
                    eventBody = event.body;
                    return eventBody;
                }      
            }
        })

        return eventBody;
        
    };

    const surveyCtaClick = (survey_id) => {

        let already_participated = false;
        actionsCompleted.current.forEach((action) => {
            if(action.survey_id==survey_id){
                already_participated = true;
                dispatch({
                    type: 'TOGGLE_MESSAGE_ALERT', 
                    payload: {
                        message: {
                            title: "Hai già partecipato a questo sondaggio!",
                            description: "Torna indietro e scopri le altre azioni per guadagnare punti D." 
                            }
                        }    
                });
            }
        });
        if(!already_participated){
            window.location.href = process.env.REACT_APP_DONNAD_DOMAIN + '/survey/' + survey_id + '/start';
        }
        
    };

    const specchioMagiocoCtaClick = (campaign_name, url) => {
        let already_participated = false;
        actionsCompleted.current.forEach((action) => {
            if(action.campaign_name===campaign_name){
                already_participated = true;
                dispatch({
                    type: 'TOGGLE_MESSAGE_ALERT', 
                    payload: {
                        message: {
                            title: "Hai già partecipato a questo gioco!",
                            description: "Torna indietro e scopri le altre azioni per guadagnare punti D." 
                            }
                        }    
                });
            }
        });
        if(!already_participated){
            window.location.href = process.env.REACT_APP_DONNAD_DOMAIN + '/' + url;
        }
    };

    const dateCustom = (dataOraISO) => {
        let dataOra = new Date(dataOraISO);
        let giorno = dataOra.getDate();
        let mese = dataOra.getMonth() + 1; // Aggiungiamo 1 perché i mesi partono da 0
        let anno = dataOra.getFullYear();
        return `${giorno < 10 ? '0' : ''}${giorno}/${mese < 10 ? '0' : ''}${mese}/${anno}`;
    };
    

    const onPointsTicketClick = () => {
        dispatch({ type: 'TOGGLE_RECEIPTS' });
    };

    const handleLoyaltySwitch = (data) => {
        setLoyaltySwitch(data);
    };

    return (
        <div className={style.el}>
            {/*@TODO refactor ADV*/}
            <div className={style.homeAdv}>
                <div id="DotnAd_mh_u"></div>
            </div>
            
            {!!state.user && !isLoyaltyActive(state) && isLoyaltyActive(state, true) && (
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>I punti D della Dfamily scadranno il 22/12 alle ore 12.00, poi saranno azzerati. Riscatta subito i premi del programma fedeltà.</p>
            )}

            <div className={style.cols}>
                <div className={style.main}>
                    {!isLoyaltyActive(state) && state.loyalty_status === true && !!state.user && (
                        <LoyaltyCard logged={!!state.user} />
                    )}

                    {state.loyalty_status === false && loyaltyEndDateBeforeToday === true && (
                        <LoyaltyCardExpired logged={!state.user} />
                    )}

                    {/*      {!!state.user && (
                        <WelcomeCard onCtaClick={() => dispatch({ type: 'OPEN_TUTORIAL' })} />
                    )}
*/}

                    {!!!state.user && state.loyalty_status === true && (
                        <LoyaltyCard
                            logged={!!state.user}
                            scrollTo={
                                !!!state.user &&
                                query.get('goto') === 'partecipa-chi-trova-un-amica-trova-un-tesoro'
                            }
                        />
                    )}

                    {/*@TODO refactor ADV*/}
                    {/* {!isDesktop && (
                        <div className={style.homeAdv}>
                            <div id="DotnAd_box_right2"></div>
                        </div>
                    )} */}
                    <BrandCards />
                    <div className={style.twocol} id="loyalty-section">

                        {!!state.user && isLoyaltyActive(state, true) && (
                            <UserDashboard
                                updated={state.user.updated}
                                username={state.user.firstName}
                                onPointsTicketClick={onPointsTicketClick}
                                points={points}
                            />
                        )}
                        {!!state.user && isLoyaltyActive(state) && state.loyalty_status === true &&
                            <div className={style.actionsContainer}>
                                <LoyaltySwitch loyatySwitch={handleLoyaltySwitch}/>
                                {loyaltySwitch==1 && 
                                    <div className={style.actions}>
                                        {actions.length>0 && actions.map((action,j) =>
                                            
                                            (action.show_action_todo && <Card  action={action}/>)
                                            
                                        )}                         
                                    </div>
                                }
                                {loyaltySwitch==2 && 
                                    <div className={style.actions}>
                                        {actionsCompleted.current.length>0 && actionsCompleted.current.map((actionCompleted,j) =>                                          
                                            <Card  action={actionCompleted} completed={true} />                                           
                                        )}                    
                                    </div>
                                }
                                {loyaltySwitch==3 && 
                                    <div>
                                        <AccordionComponent  header={"Il tuo obiettivo"} Component={CardReward} items={preferredRewards} points={points} preferredRewardId={preferredRewards && preferredRewards.length>0 ? preferredRewards[0].rewardId : ''} updateCardRewardsAccordion={checkPreferredReward}/>
                                        {categories.length>0 && categories.map((category,k) =>                                                                  
                                            (category.rewards && category.rewards.length>0 && <AccordionComponent  key={k} header={category.name} Component={CardReward} items={category.rewards} points={points} preferredRewardId={preferredRewards && preferredRewards.length>0 ? preferredRewards[0].rewardId : ''} updateCardRewardsAccordion={checkPreferredReward}/>)                        
                                        )}                                                                      
                                    </div>
                                }                                         
                            </div>                            
                        }
                        <Latest title={'Gli ultimi articoli'} hasPrivateContent={true} />

                    </div>

                    {/* {isLoyaltyActive(state) && <InstallApp loggedIn={!!state.user} />} */}

                    <HoroscopeHp
                        onFeedbackSent={() => dispatch({ type: 'UPDATE_USER_DASHBOARD' })}
                    />

                    <EventsHp />

                    <ToolCards />
                </div>

                {isDesktop && (
                    <div className={style.sidebar}>
                        <div id="DotnAd_box_right1"></div>
                        <div className={style.banners}>
                            <div id="DotnAd_box_right2"></div>
                            <div id="DotnAd_box_right3"></div>
                        </div>
                    </div>
                )}
                {!isDesktop && (
                    <div className={style.sidebar} style={{ display: 'none' }}>
                        <div id="DotnAd_box_right1"></div>
                        <div className={style.banners}>
                            <div id="DotnAd_box_right3"></div>
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default Home;
