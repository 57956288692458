import React from 'react';
import style from './style.module.scss';

const ArticlelList = () => {

    return (
        <div className={style.wrapper}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default ArticlelList;
