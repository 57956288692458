import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import LazyImg from '../LazyImg';
import './style.module.scss';

import Cta from '../Cta';
import LinkTo from '../LinkTo';
import CheckboxLabel from '../CheckboxLabel';

import AppContext from '../../contexts/appContext';
import Spinner from "../Spinner";

// install Swiper components
SwiperCore.use([Pagination]);

const Membership = () => {
    const { state, dispatch } = useContext(AppContext);
    const [terms, setTerms] = useState(false);
    const [userInterests, setUserInterests] = useState([]);
    const [tutorialFlow, setTutorialFlow] = useState(null);

    useEffect(() => {
        if (!state.loading) {
            if (state.user) {
                state.user.data.legal && state.user.data.legal.profiling
                    ? setTerms(true)
                    : setTerms(false);
                if (state.user.data.interests && state.user.data.interests.length) {
                    setUserInterests(state.user.data.interests);
                }
            }

            if (typeof window.dataLayer != 'undefined' && !!state.tutorial) {
                window.dataLayer.push({
                    event: 'PWA',
                    category: 'PWA',
                    action: 'Tutorial shown',
                    label: 'PWA Tutorial shown',
                    noninteractive: true,
                });
            }
        }
    }, [dispatch, state.user, state.loading, state.tutorial]); // eslint-disable-line react-hooks/exhaustive-deps

    const favourites = [
        'fitness',
        'casa pulita',
        'riciclo',
        'bellezza',
        'interior design',
        'giardinaggio',
        'idee creative',
        'bambini',
        'cucina',
        'animali',
        'viaggi',
        'fai da te',
        'moda',
    ];

    const saveData = () => {
        if (!terms || !userInterests.length) {
            return;
        }

        window.gigya.accounts.setAccountInfo({
            data: {
                interests: userInterests,
            },
            callback: (response) => {
                if (response.status === 'OK') {
                    window.gigya.accounts.setAccountInfo({
                        data: { legal: { profiling: true } },
                    });
                    setTerms(true);
                }
                closeMembershipModal();
            },
        });
    };

    const closeMembershipModal = () => {
        if (state.user && (!state.user.data.membership || !state.user.data.membership.tutorial)) {
            window.gigya.accounts.setAccountInfo({
                data: {
                    membership: {
                        tutorial: new Date().toISOString(),
                    },
                },
                callback: (response) => dispatch({ type: 'CLOSE_INTERESTS' }),
            });
        } else {
            dispatch({ type: 'CLOSE_INTERESTS' });
        }
    };

    const classes = classNames('membership', {
        active: !!state.interestsModal || !!state.tutorial,
        nologged: !state.user,
    });

    const interestsClass = classNames('interests', {
        disabled: !terms,
    });

    return (
        <div className={classes}>
            <div className="mask">
                <div className="background">
                    <LazyImg src="/imgs/texture/membership.jpg" alt="background" />
                </div>
                <Swiper
                    onSwiper={setTutorialFlow}
                    longSwipesMs={1000}
                    allowSlidePrev={state.tutorial ? true : false}
                    allowSlideNext={state.tutorial ? true : false}
                    spaceBetween={20}
                    slidesPerView={1}
                    watchOverflow={true}
                    pagination={{ clickable: false }}
                >
                    {state.tutorial && (
                        <SwiperSlide>
                            <div className="wrapper">
                                <LazyImg src="/imgs/membership/slides/1.svg" alt="background" />
                                    {typeof state.loyalty_status === 'undefined' && <Spinner />}
                                    {state.loyalty_status === true && (
                                        <div>
                                            <p className="title">Sei su My DonnaD: fatti premiare!</p>
                                            <p className="text">
                                                DonnaD ti dà il benvenuto su My DonnaD, il magazine femminile
                                                che premia il tuo divertimento. Partecipa a “Chi trova un’Amica
                                                trova un tesoro” e trasforma ciò che ami in un mondo di sconti,
                                                premi e vantaggi.
                                            </p>
                                        </div>
                                    )}
                                    {state.loyalty_status === false && (
                                        <div>
                                            <p className="title">Sei su My DonnaD!</p>
                                            <p className="text">
                                                DonnaD ti dà il benvenuto su My DonnaD, il magazine femminile
                                                che premia il tuo divertimento.
                                            </p>
                                        </div>
                                    )}
                                <Cta
                                    onClick={() => tutorialFlow.slideNext()}
                                    label="avanti"
                                    modifiers={['secondary-negative']}
                                />
                                <LinkTo
                                    label="Chiudi e inizia subito!"
                                    arrow={true}
                                    modifier="negative"
                                    onClick={closeMembershipModal}
                                />
                            </div>
                        </SwiperSlide>
                    )}

                    {state.tutorial && state.loyalty_status === true && (
                        <SwiperSlide className="loyalty">
                            <LazyImg src="/imgs/texture/loyalty.jpg" alt="background" />
                            <div className="wrapper">
                                <LazyImg
                                    src="/imgs/membership/slides/tutorial_loyalty.svg"
                                    alt="background"
                                />
                                <p className="title">Conquista punti ogni giorno!</p>
                                <p className="text">
                                    Partecipando ogni giorno al nostro programma fedeltà, avrai la
                                    possibilità di trasformare i tuoi punti in sconti e premi! Leggi
                                    gli articoli, carica gli scontrini, rispondi ai
                                    questionari…conquistare punti è facilissimo!
                                </p>
                                <Cta
                                    onClick={() => tutorialFlow.slideNext()}
                                    label="avanti"
                                    modifiers={['secondary-negative']}
                                />
                                <LinkTo
                                    label="Chiudi e inizia subito!"
                                    arrow={true}
                                    modifier="negative"
                                    onClick={closeMembershipModal}
                                />
                            </div>
                        </SwiperSlide>
                    )}

                    {state.tutorial && (
                        <SwiperSlide>
                            <div className="wrapper">
                                <LazyImg src="/imgs/membership/slides/2.svg" alt="background" />
                                <p className="title">Il tuo mondo sempre più tuo</p>
                                <p className="text">
                                    Fitness, casa pulita, riciclo, bellezza, interior design,
                                    giardinaggio, bambini, fai da te, moda, cucina, animali, viaggi.
                                    Dicci cosa ami e costruiremo attorno a te il tuo mondo My DonnaD
                                    da esplorare ogni giorno.
                                </p>
                                {state.user ? (
                                    <React.Fragment>
                                        <Cta
                                            onClick={() => tutorialFlow.slideNext()}
                                            label="avanti"
                                            modifiers={['secondary-negative']}
                                        />
                                        <LinkTo
                                            label="Chiudi e inizia subito!"
                                            arrow={true}
                                            modifier="negative"
                                            onClick={closeMembershipModal}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <Cta
                                        onClick={closeMembershipModal}
                                        label="buon divertimento"
                                        modifiers={['primary-negative']}
                                    />
                                )}
                            </div>
                        </SwiperSlide>
                    )}

                    {state.user && (
                        <SwiperSlide>
                            <div className="wrapper">
                                <p className="title">Seleziona i tuoi interessi</p>
                                <p className="textinterest">
                                    Seleziona gli argomenti che ti piacciono di più. DonnaD ti
                                    mostrerà quelli più in linea con i tuoi interessi.
                                </p>

                                {!state.user.data.legal.profiling && (
                                    <label className="legals">
                                        <input
                                            type="checkbox"
                                            value="profiling"
                                            name="profiling"
                                            checked={terms}
                                            onChange={(e) => setTerms(e.target.checked)}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="label">
                                            Autorizzo il trattamento dei miei dati personali per
                                            permettere a Henkel di effettuare analisi su attività
                                            svolte e preferenze di consumo{' '}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="http://www.donnad.it/privacy"
                                            >
                                                (Visualizza privacy policy)
                                            </a>
                                        </span>
                                    </label>
                                )}

                                <div className={interestsClass}>
                                    {favourites.map((item, i) => (
                                        <CheckboxLabel
                                            key={i}
                                            emit={(isChecked) => {
                                                isChecked
                                                    ? setUserInterests([...userInterests, item])
                                                    : setUserInterests(
                                                          userInterests.filter((i) => i !== item)
                                                      );
                                            }}
                                            name="interests"
                                            label={item}
                                            checked={
                                                state.user.data.interests &&
                                                state.user.data.interests.indexOf(item) >= 0
                                            }
                                        />
                                    ))}
                                </div>

                                <Cta
                                    label="salva"
                                    disabled={!terms || !userInterests.length}
                                    onClick={saveData}
                                    modifiers={['primary-negative']}
                                />
                                <LinkTo
                                    label={!terms ? 'Non ho interessi' : 'Salta'}
                                    arrow={true}
                                    modifier="negative"
                                    onClick={closeMembershipModal}
                                />
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    );
};

export default Membership;
