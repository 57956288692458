import React, { Component } from 'react';
import style from './style.module.scss';

import { timePicker } from "analogue-time-picker";
import { useEffect, useState } from "react";
import './analogue-time-picker-style.css';

class MyDTPicker extends Component {
    /*constructor(props) {
        super(props);
        this.state = {
            
            timeSelected: false,
        };
    }*/
  
      
    onTimeChanged(hour, minute){
        let orarioScontrino = document.getElementById('orarioScontrino');
        if(hour.toString().length < 2){
            hour = '0'+hour.toString();
        }
        if(minute.toString().length < 2){
            minute = '0'+minute.toString();
        }
        orarioScontrino.value = hour + ':' + minute;
        let ev = new Event('click', { bubbles: true });
        orarioScontrino.dispatchEvent(ev)
    }

    timePickerInput(){
        let container = document.getElementById("timePickerContainer");
        //timePicker({ element: container, onTimeChanged: (args)  => {console.log(args)} });
        let hour = 0;
        let minute = 0;
        let orarioScontrino = document.getElementById('orarioScontrino');
        
        if(orarioScontrino.value !== undefined && orarioScontrino.value.length == 5){
            let array = orarioScontrino.value.split(':');
            hour = array[0];
            minute = array[1];
        }
        
        const tP = timePicker({ element: container, time: { hour: hour, minute: minute }});
        tP.onTimeChanged((hour, minute) => {
            this.onTimeChanged(hour, minute);
        })
    };
      
    
    
    inputProps = {
        placeholder: 'Inserisci orario dello scontrino',
        className: 'orarioScontrinoInner',
        required: true,
    };
    componentDidMount(){
        this.timePickerInput();
    }
    componentWillUnmount(){
        //let container = document.getElementById("timePickerContainer");
        //while (container.firstChild) {
        //    container.removeChild(container.firstChild);
        //}
    }
    render() {
        return (
            
            <div id='timePickerContainer' >
                
                
            </div>
        );
    }
}

export default MyDTPicker;
