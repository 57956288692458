import React from 'react';
import ScontrinoCard from '../ScontrinoCard';
// import Cta from '../Cta';
import style from './style.module.scss';

const ScontriniList = ({ items }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <h2 className={style.title}>I tuoi scontrini</h2>
                <p className={style.text}>
                    Se il tuo scontrino non viene accettato, puoi provare a ricaricare l'immagine cliccando su "Modifica" per un massimo di due volte!
                </p>
            </div>
            <div className={style.list}>
                {items.map((item, i) => (
                    <div key={i} className={style.article}>
                        <ScontrinoCard {...item} />
                    </div>
                ))}
            </div>
            {/*            <div className={style.actions}>
                <Cta label="load more" />
            </div>*/}
        </div>
    );
};

export default ScontriniList;
