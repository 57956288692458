import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import Cta from '../Cta';
import LinkTo from '../LinkTo';

const TextureCard = ({ title, textTitle, points, text, cta, image, modifiers, link, info }) => {
    let styleCard = [style.card];

    if (modifiers) {
        styleCard.push(modifiers.map((modifier) => style[modifier]));
    }

    const classes = classNames(styleCard);

    return (
        <div className={classes} >
            <div className={style.image}>
                <div className={style.head}>
                    {title && <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>}
                    {points && (
                        <p>
                            {points} <span>punti</span>
                        </p>
                    )}
                    {info != 'sondaggi' && <small>{info}</small>}
                </div>
                <div className={info == 'sondaggi' ? style.background2 : style.background}>
                    <LazyImg src={image && (image.src)} alt="backgroundImage" />
                </div>
                {/*image && (
                    <div className={style.media}>
                        <LazyImg src={image.src} alt={image.name} />
                    </div>
                )*/}
            </div>
            <div className={style.content}>
                <p>{textTitle}</p>
                {text && (
                    <p                 
                        dangerouslySetInnerHTML={{ __html: text }}
                    ></p>
                )}
                {cta && <Cta {...cta} />}
                {link && <LinkTo {...link} />}
            </div>
        </div>
    );
};

export default TextureCard;
