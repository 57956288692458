import React, { useState, useContext, useEffect, useRef } from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { isLoyaltyActive } from '../../utils';
import { UserArticles as UserArticlesAPI, Share as ShareArticlesAPI } from '../../Api.js';
import ArticleHeader from '../../components/ArticleHeader';
import Avatar from '../Avatar';
import Menu from '../Menu';
import ShareBar from '../ShareBar';
import HeaderNav from '../HeaderNav';
import { Bell } from '../Icons';

import style from './style.module.scss';

import AppContext from '../../contexts/appContext';

const Header = ({ lastNoteDate }) => {
    const { state, dispatch } = useContext(AppContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [notificationAlert, setNotificationAlert] = useState(false);
    const menuRef = useRef(null);

    const is_frontpage = useLocation().pathname === '/';

    const toggleSettings = () => {
        dispatch({ type: 'TOGGLE_SETTINGS' });
    };

    const onLoginClick = () => {
        setMenuOpen(false);
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                onHide: () => {
                    dispatch({ type: 'UNSET_LOADING', payload: null });
                },
            },
        });
    };

    const onUploadTicketClick = () => {
        dispatch({ type: 'TOGGLE_RECEIPTS' });
    };

    const onShare = (source, e) => {
        if (typeof window.dataLayer != 'undefined') {
            window.dataLayer.push({
                event: 'PWA',
                category: 'PWA',
                action: 'Article shared',
                label: `${source} - ${state.current_article.slug}`,
            });
        }
        if (!!state.user) {
            ShareArticlesAPI.post().catch(({ response }) => {
                if (response.status === 503) {
                    console.log('Gigya Limit Reached');
                }
            });
        }
    };

    const onBookmarkClick = () => {
        dispatch({ type: 'SET_LOADING' });

        UserArticlesAPI.post('', {
            drupal_nid: state.current_article.nid,
            bookmarked: true,
        })
            .then(({ data }) => {
                dispatch({
                    type: 'UPDATE_CURRENT_ARTICLE_USER_DATA',
                    payload: data.data || null,
                });
                dispatch({ type: 'UNSET_LOADING' });
            })
            .catch(({ response }) => {
                if (response.data.error.code === 204) {
                    //User alraedy interacted with post
                    UserArticlesAPI.patch(`/${state.current_article.data.id}`, {
                        drupal_nid: state.current_article.nid,
                        bookmarked: state.current_article.data
                            ? !state.current_article.data.bookmarked
                            : true,
                    }).then(({ data }) => {
                        dispatch({
                            type: 'UPDATE_CURRENT_ARTICLE_USER_DATA',
                            payload: data.data || null,
                        });
                        dispatch({ type: 'UNSET_LOADING' });
                    });
                }
            })
            .then(() => {
                if (typeof window.dataLayer != 'undefined') {
                    window.dataLayer.push({
                        event: 'PWA',
                        category: 'PWA',
                        action: 'Article bookmarked',
                        label: `${
                            (
                                state.current_article.data
                                    ? !state.current_article.data.bookmarked
                                    : true
                            )
                                ? 'added'
                                : 'removed'
                        } - ${state.current_article.slug}`,
                    });
                }
            });
    };

    const headerClasses = classNames({
        [style.mainHeader]: true,
    });

    const wrapperClasses = classNames({
        [style.wrapper]: true,
        [style.article]: state.current_page && state.current_page.type !== 'home',
    });

    useEffect(() => {
        if (menuOpen) {
            disableBodyScroll(menuRef);
        } else {
            enableBodyScroll(menuRef);
        }
        setShareOpen(false);
        if (!!lastNoteDate) {
            const last_note_ls = localStorage.getItem('last_note');
            setNotificationAlert(last_note_ls && last_note_ls !== lastNoteDate);
        }
    }, [state.current_page, lastNoteDate, menuOpen]);

    return (
        <React.Fragment>
            <div className={style.el + ' ' + style.rs22}>
                <div className={wrapperClasses}>
                    <div className={style.inner}>
                        <div className={headerClasses}>
                            <header className={style.header}>
                                <div className={style.headerNav}>
                                    <h1 className={style.logo}>
                                        <Link to={`/`}></Link>
                                    </h1>
                                </div>
                                <div className={style.actions}>
                                    {state.user ? (
                                        <div className={style.toggler_wrapper} onClick={() => {
                                            setMenuOpen(true);
                                        }}
                                        >
                                            <span className={style.greetings}>{state.user.firstName ? 'Ciao, ' + state.user.firstName + '!' : 'Ciao!'}</span>
                                            <Avatar
                                                image={state.user.thumbnailURL || state.user.photoURL}
                                                data={state.user.data}
                                                user={state.user ? state.user : null}
                                            />
                                        </div>
                                    ) : !!window.gigya ? (
                                        <span onClick={onLoginClick} className={style.login}>
                                            Accedi / Registrati
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                    {state.user && (
                                        <Link className={style.notifications} to={`/notifiche`}>
                                            {notificationAlert && <span></span>}
                                            <Bell />
                                            <small>Le mie notifiche</small>
                                        </Link>
                                    )}
                                </div>
                            </header>
                            {!!state.user && (
                                <HeaderNav
                                    onUploadTicketClick={onUploadTicketClick}
                                    loyalty={isLoyaltyActive(state)}
                                    loyalty_prizes={isLoyaltyActive(state,true)}
                                />
                            )}
                        </div>
                        <div className={style.articleHeader}>
                            <ArticleHeader
                                loggedIn={!!state.user}
                                article={state.current_article}
                                onShareClick={() => setShareOpen(!shareOpen)}
                                settings={
                                    !!state.current_page &&
                                    state.current_page.type === 'notifications'
                                }
                                onSettingsClick={toggleSettings}
                                onBookmarkClick={() => onBookmarkClick()}
                            />
                        </div>
                    </div>
                </div>
                <CSSTransition
                    in={menuOpen && !!state.user}
                    timeout={200}
                    classNames="header-menu"
                    unmountOnExit
                    ref={menuRef}
                >
                    <Menu
                        onCloseClick={() => setMenuOpen(false)}
                        user={state.user}
                        links={[
                            {
                                label: 'i miei scontrini',
                                target: 'app',
                                href: '/scontrini',
                            },
                            {
                                label: 'i miei coupon e codici',
                                target: 'app',
                                href: '/coupons',
                            },
                            {
                                label: 'i miei segnalibri',
                                target: 'app',
                                href: '/preferiti',
                            },
                            {
                                label: 'le mie notifiche',
                                target: 'app',
                                href: '/notifiche',
                            },
                            {
                                label: 'il mio profilo',
                                target: 'app',
                                href: '/utente',
                            },
                        ]}
                        service={[
                            {
                                label: 'Help / FAQ',
                                target: 'app',
                                href: '/faq',
                            },
                            {
                                label: 'contatta donnaD',
                                href: 'https://www.donnad.it/contatti',
                            },
                        ]}
                    />
                </CSSTransition>
                {state.current_article && (
                    <CSSTransition
                        in={shareOpen}
                        timeout={1000}
                        classNames="share-menu"
                        unmountOnExit
                    >
                        <ShareBar onShareClick={onShare} url={state.current_article.url} />
                    </CSSTransition>
                )}
            </div>
            <div className={style.spacer + (is_frontpage ? ' ' + style.is_frontpage : '') }></div>
        </React.Fragment>
    );
};

export default Header;
