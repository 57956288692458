import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { BlockCards as BlockCardsAPI } from '../../Api.js';

import ArticleCard from '../../components/ArticleCard';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import style from './style.module.scss';

SwiperCore.use([Pagination, Navigation]);

const ToolCards = () => {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        BlockCardsAPI.get('', {
            params: {
                fields: '*,default_image.data,mobile_image.data',
                'filter[card_type]': 'tool',
                'filter[schedule_starts_on][lte]': 'now',
                'filter[schedule_ends_on][gte]': 'now',
            },
        }).then(({ data }) => {
            setCards(data.data);
            setLoading(false);
        });
    }, []);

    return (
        <div
            className={classNames(style.toolsCardsWrapper, {
                [style.hideactions]: cards.length <= 1,
            })}
        >
            <Swiper spaceBetween={10} navigation={true} pagination={true} watchOverflow={true}>
                {!loading ? (
                    cards.map((c, k) => (
                        <SwiperSlide key={k}>
                            <ArticleCard
                                title={c.title}
                                link={c.link}
                                text={c.text}
                                tag={c.tags[0]}
                                image={{
                                    src: c.mobile_image && c.mobile_image.data.full_url,
                                    alt: c.title,
                                }}
                                key={k}
                            />
                        </SwiperSlide>
                    ))
                ) : (
                    <div />
                )}
            </Swiper>
        </div>
    );
};

export default ToolCards;
