import React from 'react';
import style from './style.module.scss';

import { Close } from '../Icons';

const Notification = ({text, title, onRemoveClick}) => {

    return (
        <div className={style.el}>
            <button aria-label="chiudi" onClick={onRemoveClick}>
                <Close />
            </button>
            <p className={style.title}>{title}</p>
            <div className={style.data} dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
    );
}

export default Notification;
