import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import AppContext from '../../contexts/appContext';
import { Coupons as CouponsAPI, Codes as CodesAPI } from '../../Api.js';
import Footer from '../../components/Footer';
import HeadingPage from '../../components/HeadingPage';
import CouponList from './couponList';
import CodeList from './codeList';

import style from './style.module.scss';

const Coupons = () => {
    const [coupons, setCoupons] = useState([]);
    const [codes, setCodes] = useState([]);
    const [activeTab, setActiveTab] = useState('coupon');
    const { state, dispatch } = useContext(AppContext);

    const today = dateFormat(new Date(), 'yyyy-mm-dd');

    const getCoupons = () => {
        CouponsAPI.get('', {
            params: {
                fields: '*.*,coupon_master_id.*.*',
                'filter[coupon_master_id.status][like]': 'enabled',
                'filter[coupon_master_id.shop_redeemable_ends_on][gte]': today,
            },
        }).then(({ data }) => {
            dispatch({ type: 'UNSET_LOADING' });
            setCoupons(data.data);
        });
    };

    const getCodes = () => {
        CodesAPI.get('').then(({ data }) => {
            setCodes(
            data
                .filter(c => c.code_master) // Only proceed if c.code_master exists
                .map(c => {
                    return {
                        image: c.code_master.image,
                        code: c.value,
                        date: c.validity_to_on,
                        title: c.code_master.title,
                        url: c.code_master.url,
                    };
                })
        );
        });
    };

    useEffect(() => {
        state.user && getCoupons();
        state.user && getCodes();
        dispatch({ type: 'SET_PAGE', payload: { type: 'coupon' } });
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Coupon | DonnaD</title>
            </Helmet>

            <HeadingPage
                title="<h1>I miei coupon e codici</h1>"
                text="Qui trovi tutti i tuoi coupon e codici per fare shopping in negozio e online."
            />

            <ul className={style.switch}>
                <li
                    className={`${activeTab === 'coupon' ? style.active : undefined} `}
                    onClick={() => setActiveTab('coupon')}
                >
                    I miei coupon
                </li>
                <li
                    className={`${activeTab === 'codes' ? style.active : undefined} `}
                    onClick={() => setActiveTab('codes')}
                >
                    I miei codici
                </li>
            </ul>

            <div className="content">
                {activeTab === 'coupon' && (
                    <CouponList items={coupons} onCouponGenerated={getCoupons} />
                )}
                {activeTab === 'codes' && <CodeList items={codes} />}
            </div>
            <Footer />
        </div>
    );
};

export default Coupons;
