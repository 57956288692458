import React from 'react';
import dateFormat from 'dateformat';
import Cta from '../../components/Cta';
import PrizeCard from '../../components/PrizeCard';
import HeadingPage from '../../components/HeadingPage';

import style from './style.module.scss';

const Coupons = ({ items }) => {
    return (
        <div className={style.page}>
            <div className="content">
                <div>
                    <HeadingPage text="Puoi utilizzare i codici che hai raccolto per avere sconti sui tuoi acquisti online. Buono shopping!" />

                    {items.length > 0 ? (
                        <div className={style.list}>
                            {items.map((item, i) => (
                                <div className={style.prize} key={i}>
                                    <div className={style.details}>
                                        <div className={style.image}>
                                            <PrizeCard image={item.image && item.image.data && item.image.data.full_url} />
                                        </div>
                                        <div className={style.data}>
                                            {item.title && (
                                                <div className={style.validity}>
                                                    <strong>{item.title}</strong>
                                                </div>
                                            )}
                                            <strong>{item.code_master}</strong>
                                            {item.code && (
                                                <div className={style.validity}>
                                                    Codice: <strong>{item.code}</strong>
                                                </div>
                                            )}
                                            {item.date && (
                                                <div className={style.validity}>
                                                    Validità entro il:{' '}
                                                    <strong>
                                                        {dateFormat(item.date, 'dd/mm/yyyy')}
                                                    </strong>
                                                </div>
                                            )}
                                            {item.url && (
                                                <div className={style.validity}>
                                                    Da utilizzare sul seguente sito: <strong><a href={item.url} target="_blank">{item.url}</a></strong>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className={style.nores}>
                            <React.Fragment>
                                <p>Hey, sembra che tu non abbia riscattato ancora nessun codice.</p>
                                <Cta label="Richiedi Premi" url="/premi" />
                            </React.Fragment>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Coupons;
