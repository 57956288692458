export const Sections = [
    {
        title: 'Dfamily',
        items: [
            {
                title: "Cos'è il programma ”Dfamily”?",
                text:
                    '<p>È il programma fedeltà di DonnaD che premia le tue attività su DonnaD. Per esempio, ogni volta che caricherai i tuoi scontrini o inviterai un’amica o un amico a partecipare otterrai punti D che potrai usare per richiedere dei premi. Buon divertimento!</p>',
            },
            {
                title: 'Come si partecipa al programma fedeltà DonnaD?',
                text:
                    '<p>Se hai già un account DonnaD - cioè se hai completato la tua registrazione - vai su <a href="/">MyDonnaD</a> e accedi con le tue credenziali (click su “ACCEDI” in alto a destra) e partecipa  tramite banner dedicato al programma fedeltà Dfamily.<br>Se non sei ancora registrato a DonnaD, crea un account e completa la tua registrazione confermando il tuo indirizzo e-mail. Il tuo account deve essere unico, autentico e strettamente personale; ci riserviamo di eseguire controlli sulla veridicità delle informazioni fornite.</p>',
            },
            {
                title: 'Chi può partecipare al programma fedeltà?',
                text:
                    '<p>Possono partecipare tutti gli utenti iscritti a DonnaD di età superiore ai 18 anni.</p>',
            },
            {
                title: 'Quanto dura il programma fedeltà?',
                text:
                    '<p>Potrai raccogliere punti D fino alle 18.00 del 13 dicembre 2024 e riscattare i tuoi premi fino alle 12.00 del 18 dicembre 2024</p>',
            },
            {
                title: 'Ho cambiato indirizzo, telefono o altri dati personali: come faccio a modificarli?',
                text:
                    '<p>Puoi controllare e modificare in ogni momento i dati associati al tuo account navigando la sezione “<a href="/utente">Il tuo profilo</a>”</p>',
            },
            {
                title: 'Non riesco a modificare l\'indirizzo email. Come faccio?',
                text:
                    '<p>Il campo email non è modificabile, ma se desideri cambiare la mail associata al tuo account DonnaD, ti sarà sufficiente comunicarci il nuovo indirizzo di posta elettronica e lo faremo noi per te. <a href="https://www.donnad.it/contatti">Vai alla pagina contatti</a>.</p>',
            },
            {
                title: 'Come faccio a raccogliere punti D?',
                text:
                    '<p>In <a href="/">MyDonnaD</a> hai tanti modi per farlo:<br><ul><li>invitando i tuoi amici a registrarsi su DonnaD e partecipare alla Dfamily. Quando aderiscono alla Dfamily, riceverai i punti D.</li><li>caricando prove di acquisto di prodotti Henkel (scontrini) dei seguenti brand: Dixan, Bio Presto, Vernel, Perlana, Pril, Bref, Coloreria Italiana, L’Acchiappacolore, Brillance, Schwarzkopf, Natural&Easy, Palette, Gliss, Got2B.</li><li>rispondendo alle nostre domande (quiz e sondaggi disponibili)</li><li>facendo il login e rimanendo attivi su DonnaD</li><li>scaricando MyDonnaD sul tuo cellulare</li><li>giocando allo “Specchio Magico”</li><li>partecipando ai concorsi segnalati con il logo Dfamily</li><li>completando <a href="/utente">il mio profilo</a> con tutte le informazioni richieste.</li><li>raggiungendo i 6 mesi dall’adesione alla Dfamily</li></ul></p> <p style="display:none;">… ma non è tutto! Vuoi conoscere nel dettaglio tutte le azioni digitali che ti fanno guadagnare punti D? <a href="/come-guadagnare-punti">Vai alla pagina dedicata</a>, dove saranno riportate tutte le iniziative attive al momento.</p>',
            },
            {
                title: 'A cosa servono i punti D che ho raccolto?',
                text:
                    '<p><a href="/premi">Vai alla pagina dedicata</a> per rimanere aggiornato.<br>I punti D che hai raccolto possono servire per:<br> <ul><li style="display: none; ">Riscattare codici sconto da usare sui siti web di LG, Lagostina, Rowenta e Moulinex.</li><li>Riscattare un pacco con i prodotti Henkel, consegnato direttamente a casa tua</li><li>Ottenere buoni sconto Henkel del valore compreso tra 1€ e 3€ validi sui prodotti Dixan, Vernel, Pril, Bref, L\'Acchiappacolore, Bio Presto, Perlana, Coloreria Italiana, Gliss, Palette Colorazione, Palette Styling, Nelsen.</li><li>Ottenere una Gift Card di 10€ da utilizzare su EcoBnB</li><li>Ottenere un codice per ricevere la Family Card MondoParchi</li><li>Ottenere un Abbonamento di 3 settimane gratuite a <a href="https://www.melarossa.it/">Melarossa.it</a> per una dieta personalizzata</li><li>Ottenere uno sconto del 15% su <a href="https://www.carpisa.it/">Carpisa.it</a></li><li>Ottenere uno sconto di 25€ o 50€ sui prodotti Moulinex su una spesa minima di 99€ e 199€</li><li>Ottenere uno sconto di 25€ o 50€ sui prodotti Rowenta su una spesa minima di 99€ e 199€</li><li>Ottenere uno sconto di 25€ o 50€ sui prodotti Lagostina su una spesa minima di 99€ e 199€</li><li>Ottenere un mese gratis di Infinity+</li></ul></p>',
            },
            {
                title: 'Posso utilizzare i miei punti D solo in parte o devo spenderli tutti insieme?',
                text:
                    '<p>Puoi utilizzare i tuoi punti D come preferisci, anche in momenti diversi.</p>',
            },
        ],
    },
    {
        title: 'Scontrini',
        items: [
            {
                title: 'Se carico scontrini mi vengono assegnati dei punti D?',
                text:
                    '<p>Sì, riceverai 600 punti D per <b>ciascuno degli scontrini validi</b> che hai contenenti almeno 1 dei prodotti delle famiglie Dixan, Bio Presto, Vernel, Perlana, Pril, Bref, Coloreria Italiana, L’Acchiappacolore, Brillance, Schwarzkopf, Natural&Easy, Palette, Gliss, Got2B.</p>',
            },
            {
                title: 'Quali scontrini sono considerati validi per acquisire punti D?',
                text:
                    "<p>Vengono convalidati solo gli <b>scontrini “parlanti”</b>, cioè quelli che contengono il dettaglio del punto vendita (nome e indirizzo), dei prodotti acquistati (tipo, marca, formato, varietà, quantità e prezzo), la data d’acquisto e il totale della spesa. La foto dello scontrino deve essere caricata <b>in verticale</b>, con una buona qualità d'immagine. Gli scontrini dovranno essere datati tra <b>il 4 marzo 2024 dalle 14.00 e il 13 dicembre 2024 alle 18.00</b>.</p>",
            },
            {
                title: 'Perché devo caricare lo scontrino?',
                text:
                    '<p>Con la fotografia dello scontrino “parlante” (vedi risposta precedente), possiamo verificare se hai acquistato i prodotti Henkel e assegnarti i punti D. Lo scontrino, quindi, è fondamentale e va conservato con cura, senza scarabocchi, pieghe eccessive o sbiaditure.</p>',
            },
            {
                title: 'Quanti scontrini posso caricare al giorno?',
                text: '<p>In un giorno puoi caricare tutti gli scontrini che vuoi.</p>',
            },
            {
                title: 'Quale parte dello scontrino devo fotografare? Quali parti devo oscurare?',
                text:
                    '<p>Lo scontrino deve essere fotografato per intero, compresa l’intestazione e la parte finale (puoi farlo anche caricando più scatti). Deve riportare:</p><ul><li>nome e indirizzo del supermercato;</li><li>tutti i prodotti acquistati (tipo, marca, formato, varietà, quantità, prezzo);</li><li>il totale;</li><li>la data e l’ora.</li><li>Se lo scontrino riporta sul retro informazione dei tuoi acquisti, ricordati di fotografarlo.</li></ul><p><br>Se hai acquistato prodotti che danno origine a dati sensibili, ricordati di oscurarli.</p>',
            },
            {
                title: 'Posso fotografare uno scontrino molto lungo? ',
                text:
                    '<p>Certo! Se il tuo scontrino è troppo lungo, puoi dividerlo in due scatti.</p>',
            },
            {
                title: 'Quali sono i prodotti Henkel con cui guadagno punti D?',
                text:
                    '<p>I prodotti che valgono punti D sono quelli delle famiglie Dixan, Bio Presto, Vernel, Perlana, Pril, Bref, Coloreria, L’Acchiappacolore, Brillance, Schwarzkopf, Natural&Easy, Palette, Gliss, Got2B.</p>',
            },
            {
                title: 'Quanti punti D guadagno caricando uno scontrino?',
                text:
                    '<p>Ti verranno assegnati 600 punti D per ciascuno degli scontrini caricati validati. <br>Per ogni 5 scontrini caricati, e validati, riceverai inoltre un bonus di ulteriori 100 punti D che ti verranno assegnati.<br>Sono validi i prodotti a marchio:<br><ul><li>Dixan</li><li>Bio Presto</li><li>Vernel</li><li>Perlana</li><li>Pril</li><li>Bref</li><li>Coloreria Italiana</li><li>L’Acchiappacolore</li><li>Brillance</li><li>Schwarzkopf</li><li>Natural&Easy</li><li>Palette</li><li>Gliss</li><li>Got2b</li></ul></p>',
            },
            {
                title: 'Cosa succede dopo che ho caricato lo scontrino?',
                text:
                    '<p>In qualche ora elaboriamo la foto dello scontrino, ci assicuriamo che sia valido e ti accreditiamo i relativi punti D.</p>',
            },
            {
                title: 'Posso inviare la fotografia della spesa a casa o spesa online?',
                text:
                    "<p>Al momento accettiamo solo scontrini regolarmente emessi nel punto vendita; non sono valide fatture, ricevute o altre prove d'acquisto.</p>",
            },
            {
                title: 'Come capisco se uno scontrino è stato validato e mi sono stati accreditati punti D?',
                text:
                    '<p>Nella pagina ‘<a href="/scontrini">I miei scontrini</a>’ compariranno tutti gli scontrini che hai caricato. Troverai tre colori diversi per descrivere lo stato di ogni scontrino:</p><ul><li>Bollino verde: lo scontrino è stato approvato e ti verranno accreditati i punti D;</li><li>Bollino grigio: ancora in fase di approvazione</li><li>Bollino rosso: lo scontrino è stato annullato</li></ul><p></p>',
            },
            {
                title: 'Perché il mio scontrino è stato annullato?',
                text:
                    '<p>Nel caso in cui lo scontrino fosse annullato (Bollino rosso), riceverai una email con le istruzioni per contattare il Customer Care.<br>Le motivazioni potrebbero essere: </p><ul><li>La foto ha una bassa qualità o è sfocata; prova a inviarne una nuova</li><li>È di un formato non valido (ad esempio è una ricevuta di spesa online o una fattura)</li></ul><p></p>',
            },
            {
                title: 'Devo conservare lo scontrino?',
                text:
                    "<p>Il nostro sistema salva tutte le immagini che hai caricato nella sezione 'I miei scontrini'. Ti consigliamo comunque di conservare per sei mesi gli scontrini cartacei che hai usato, perché potremmo chiederti di inviarci l’originale per eventuali verifiche.</p>",
            },
        ],
    },
    {
        title: 'Premi e Coupon',
        items: [
            {
                title: 'Che premi posso richiedere con il programma fedeltà?',
                text:
                    '<p>Puoi richiedere buoni sconto, codici sconto, esperienze e pacchi prodotti a marchio Henkel, che puoi richiedere con i punti D che hai guadagnato ogni giorno con le tue azioni digitali (vedi risposta successiva). <a href="/premi">Vai alla pagina dedicata</a>, dove troverai il catalogo aggiornato con i premi attivi.</p>',
            },
            {
                title: 'Quando posso iniziare a utilizzare i miei punti D?',
                text:
                    '<p>Al raggiungimento della soglia minima per richiedere il premio interessato</p>',
            },
            {
                title: 'Quanto tempo ci vuole per vedersi accreditare i punti D?',
                text:
                    "<p>Ci vuole qualche ora per accreditare i punti D accumulati grazie alle attività nel sito. Puoi controllare il tuo saldo punti D nell'area personale MyDonnaD.</p>",
            },
            {
                title: 'Dove posso controllare il mio saldo punti D?',
                text:
                    '<p>Puoi trovare il saldo punti D in diverse parti del sito:</p><ul><li>nella tua homepage</li><li>nella pagina Storico attività a cui accedi dalla tua Bacheca</li><li>nella pagina Richiedi premi</li></ul><p></p>',
            },
            {
                title: 'Come posso invitare i miei amici a partecipare?',
                text:
                    '<p>All’interno della tua Homepage troverai un box dedicato all’attività: copia il link e invialo alla persona che vuoi invitare a registrarsi a DonnaD e partecipare al programma fedeltà Dfamily. <br>Scopri tutti i dettagli dell’azione nella tua area personale Dfamily, dopo avervi aderito su <a href="/"> MyDonnaD</a>. </p>',
            },
            {
                title:
                    'Cosa succede se la persona che ho invitato non utilizza i link che gli ho mandato?',
                text:
                    "<p>Se la persona che hai invitato non utilizza il link che gli hai inviato, purtroppo non ti verranno accreditati i punti D. Prova con un'altra amica!</p>",
            },
            {
                title:
                    'Dopo quanto tempo riceverò il premio “Pacco prodotti”?',
                text:
                    "<p>Riceverai il premio “Pacco prodotti” dopo 180 giorni dalla richiesta del premio.</p>",
            },
        ],
    },
    {
        title: 'MyDonnaD',
        items: [
            {
                title: "Cos'è MyDonnaD?",
                text:
                    '<p>È l\'applicazione digitale di DonnaD che <b>premia la tua voglia di divertirti ogni giorno.</b> MyDonnaD ti offre l\'iniziativa “Dfamily” il <b>programma fedeltà a punti D</b> con cui ottenere tanti fantastici premi e coupon (vedi le risposte dedicate).</p>',
            },
            {
                title: 'Per fare parte di MyDonnaD devo versare una quota associativa?',
                text:
                    '<p>Assolutamente no! Registrarsi alla community e aderire al programma a punti D “Dfamily” è completamente gratuito. Come lo è anche partecipare a iniziative speciali e godere di promozioni dedicate.</p>',
            },
        ],
    },
];
