import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style.module.scss';

const Points = ({ label, date, texture = 'points', value, onCardClick, link, text , name }) => {
    let classes = classNames({
        [style.el]: true,
        [style.interactive]: !!onCardClick || !!link,
    });

    const ConditionalLink = !!link ? Link : 'div';
    const goToActions = () => {
        let container = document.getElementById("loyalty-switch");
                
        console.log('Go to actions ' + style.loyaltyswitch);
        console.log(container.children);

        let children = container.children;
        console.log('Button');
        console.log(children);

        children[1].click();
    }
    return (
        <a className={classes} href={link} onClick={goToActions}>
        {/*  <img src={`/imgs/texture/${texture}.jpg`} alt="backgroundImage" /> */}  
            <div className={style.head}>
                <p className={style.label} dangerouslySetInnerHTML={{ __html: label }}></p>
            </div>
            <div className={style.content}>
                {value && (
                    <p className={style.points} >
                        {!isNaN(value) ? new Intl.NumberFormat('it-IT').format(value) : value}
                    </p>
                )}
                {text && <p className={style.text}>{text}</p>}
                {date && <p className={style.date}>{date}</p>}
                <p className={style.disclaimer} dangerouslySetInnerHTML={{ __html: name }} />
            </div>
        </a>
    );
};

export default Points;
