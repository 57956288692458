import React, { useState, useEffect, useContext } from 'react';
import style from './style.module.scss';
import { Helmet } from 'react-helmet';

import HeadingPage from '../../components/HeadingPage';
import PointsGallery from '../../components/PointsGallery';
import Footer from '../../components/Footer';

import AppContext from '../../contexts/appContext';

import { PointsGalleryData, PointsSections } from './data';

const HowToGainPoints = () => {
    const { dispatch } = useContext(AppContext);
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'prizes' } });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Come guadagnare punti | DonnaD</title>
            </Helmet>
            <div className="content">
                <HeadingPage
                    title="<h1>Come<br /> guadagnare punti</h1>"
                    text="Sapere che ci sei vicina ogni giorno per noi è importante e per questo ti regaliamo punti per ogni azione che compi su MyDonnaD. Buon divertimento!"
                />
            </div>

            <div className="wrap-hidden">
                <div className="content">
                    <PointsGallery {...PointsGalleryData} />
                </div>
            </div>

            {PointsSections &&
                PointsSections.map(({ title, items }, i) => (
                    <div className="content" key={i}>
                        <HeadingPage title={title} />
                        <button className={style.bottone} onClick={() => setOpen(!isOpen)}>{!isOpen ? 'MOSTRA' : 'CHIUDI'}</button>
                         <div id="accordionContent" className={isOpen ? style.apri : style.chiudi}>
	                    <div className={style.list}>
                            {items.map((item, j) => (
                                <div className={style.item} key={j}>
                                    <div
                                        className={style.name}
                                        dangerouslySetInnerHTML={{ __html: item.title }}
                                    ></div>
                                    <div
                                        className={style.times}
                                        dangerouslySetInnerHTML={{ __html: item.times }}
                                    ></div>
                                    {item.info && (
                                        <div>
                                            <div
                                            className={style.name}
                                            dangerouslySetInnerHTML={{ __html: item.title }}
                                        ></div>
                                            <div
                                                className={style.times}
                                                dangerouslySetInnerHTML={{ __html: item.times }}
                                            ></div>
                                        </div>
                                        )}
                                        {item.points && (
                                            <div className={style.points}>
                                                {item.points} <span>punti</span>
                                            </div>
                                        )}
                                        {item.info && (
                                            <div
                                                className={style.pointsinfo}
                                                dangerouslySetInnerHTML={{ __html: item.info }}
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

            <Footer />
        </div>
    );
};

export default HowToGainPoints;
