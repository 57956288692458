import React from 'react';
import style from './style.module.scss';

const Title = ({title}) => {
    return (
        <div className={style.data}>
            <p  dangerouslySetInnerHTML={{
                                __html: title,
                            }}></p>
        </div>
    );
};

export default Title;
