import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

const Cta = ({ label, url, modifiers = ['primary'], disabled = false, onClick, icon, isBlank }) => {
    let buttonProps = {};
    if (disabled) {
        modifiers.push('disabled');
        buttonProps.disabled = true;
    }

    if (onClick) buttonProps.onClick = onClick;

    let classes = classNames(
        style.cta,
        modifiers.map((modifier) => style[modifier]),
        modifiers.map((modifier) => modifier)
    );

    return url ? (
        !isBlank ? (
            <Link className={classes} to={url} onClick={onClick}>
                <span>{label}</span>
                {icon && icon}
            </Link>
        ) : (
            <a className={classes} href={url} target="_blank" rel="noopener noreferrer">
                <span>{label}</span>
                {icon && icon}
            </a>
        )
    ) : (
        <button className={classes} {...buttonProps}>
            <span>{label}</span>
            {icon && icon}
        </button>
    );
};

export default Cta;
