import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { find } from 'lodash';
import { Close } from '../Icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Loyalty as LoyaltyAPI } from '../../Api.js';
import classNames from 'classnames';
import style from './style.module.scss';

const Loyalty = ({ user, questions }) => {
    const [index, setIndex] = useState(1);
    const [instance, setInstance] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [baloon, setBaloon] = useState(true);
    let history = useHistory();

    const next = (q, answer) => {
        setLoading(true);
        const today = new Date(Date.now()).toISOString();
        window.gigya.accounts.getAccountInfo({
            callback: (response) => {
                const { data } = response;
                let fieldsLastUpdate = data.fieldsLastUpdate ? [...data.fieldsLastUpdate] : [];

                q.fields.forEach((f) => {
                    const field = find(fieldsLastUpdate, (flu) => flu.fieldName === f);
                    if (field) {
                        fieldsLastUpdate = fieldsLastUpdate.map((flu) => {
                            const lastUpdate = flu.fieldName === f ? today : flu.lastUpdate;
                            return {
                                fieldName: flu.fieldName,
                                lastUpdate: lastUpdate,
                            };
                        });
                    } else {
                        fieldsLastUpdate = [
                            {
                                fieldName: f,
                                lastUpdate: today,
                            },
                            ...fieldsLastUpdate,
                        ];
                    }
                });

                window.gigya.accounts.setAccountInfo({
                    data: {
                        fieldsLastUpdate: fieldsLastUpdate,
                    },
                    callback: () => {
                        if (answer === 'Si') {
                            if (!!q.rule) {
                                LoyaltyAPI.post('/yesno-answer', {
                                    earningRule: q.rule,
                                });
                            }
                            setLoading(false);
                            setIndex((i) => i + 1);
                            instance.slideTo(index);
                        } else {
                            history.push('/utente');
                            window.location.reload();
                        }
                    },
                });
            },
        });
    };

    const close = () => {
        window.location.reload();
    };

    const open = () => {
        setIsOpen(true);
        document.body.classList.add('lock');
    };

    const wrapperClasses = classNames({
        [style.wrapper]: true,
        [style.loading]: loading,
    });

    return (
        <div className={wrapperClasses}>
            {isOpen ? (
                <div className={style.container}>
                    <span className={style.backdrop}></span>
                    <div className={style.inner}>
                        <span className={style.close} onClick={close}>
                            <Close />
                        </span>
                        <div className={style.avatar}>
                            <img src="/imgs/surveys/avatar.png" alt="Survey" />
                        </div>
                        <div className={style.content}>
                            {index <= questions.length && (
                                <div
                                    className={style.counter}
                                >{`Domanda ${index} di ${questions.length}`}</div>
                            )}
                            <Swiper
                                onInit={setInstance}
                                simulateTouch={false}
                                allowTouchMove={false}
                                autoHeight={true}
                                watchOverflow={true}
                            >
                                {questions.map((q, k) => (
                                    <SwiperSlide key={k}>
                                        <div className={style.question}>
                                            <div className={style.title}>Ciao {user.firstName}</div>
                                            <div className={style.profileQuestion}>{q.title}</div>
                                            <div className={style.profileValue}>{q.value}</div>
                                            <div className={style.ctas}>
                                                <div className={style.switch}>
                                                    {['Si', 'No'].map((a, j) => (
                                                        <span
                                                            key={j}
                                                            onClick={(e) => next(q, a)}
                                                            className={style.btn}
                                                        >
                                                            {a}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                <SwiperSlide>
                                    <div className={style.question}>
                                        <div className={style.title}>
                                            <br />
                                            <br />
                                            Grazie di aver risposto!
                                            <br />
                                        </div>
                                        <div className={style.inputList}></div>
                                        <div className={style.ctas}>
                                            <div onClick={close} className={style.next}>
                                                Chiudi
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={style.trigger}>
                    <div className={style.triggerWrapper} onClick={open}>
                        <img src="/imgs/surveys/avatar.png" alt="" />
                    </div>
                    {baloon && (
                        <div className={style.baloon}>
                            <strong>Aggiorna profilo!</strong>
                            <p>
                                {/* Vuoi guadagnare nuovi punti? */}
                                Vogliamo conoscerti meglio
                                <br /> Rispondi a una domanda!
                            </p>
                            <span className={style.cta} onClick={open}>
                                Vai alla domanda
                            </span>
                            <span className={style.close} onClick={() => setBaloon(false)}>
                                <Close />
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Loyalty;
