import React, { useEffect, useState } from 'react';
import { filter, find } from 'lodash';
import { Surveys as SurveysAPI, SurveysAnswers as SurveysAnswersAPI } from '../../Api.js';

import Loyalty from './Loyalty';
import Profile from './Profile';

const Survey = ({ user, loyalty }) => {
    const [survey, setSurvey] = useState(false);
    const [profile, setProfile] = useState([]);
    const [questions, setQuestions] = useState([]);

    const getSurvey = (exclude = []) => {
        SurveysAPI.get('', {
            params: {
                limit: 1,
                fields: '*.*.*',
                'filter[survey_start_date][lte]': 'now',
                'filter[survey_end_date][gte]': 'now',
            },
        }).then(({ data }) => {
            const survey = data.data[0];
            if (survey) {
                const questions = filter(survey.questions, (q) => exclude.indexOf(q.id) === -1);
                if (questions.length > 0) {
                    setSurvey(survey);
                    setQuestions(questions);
                } else {
                    getProfileSurvey();
                }
            } else {
                getProfileSurvey();
            }
        });
    };

    const getProfileSurvey = () => {
        if (!!user && !!window.gigya) {
            window.gigya.accounts.getAccountInfo({
                extraProfileFields: 'address',
                callback: (response) => {
                    if (response.data.checks && response.data.checks.length > 0) {
                        let { fieldsLastUpdate } = response.data;
                        let questions = [];
                        const { checks } = response.data;
                        if (!fieldsLastUpdate) {
                            fieldsLastUpdate = [];
                        }
                        try{
                            questions = checks
                                .filter((c) => {
                                    let fieldIsUpToDate = false;
                                    c.fields.forEach((f) => {
                                        const fieldLastUpdate = find(
                                            fieldsLastUpdate,
                                            (flu) => flu.fieldName === f
                                        );
                                        if (fieldLastUpdate) {
                                            const today = Date.now();
                                            const lastUpdate = new Date(
                                                fieldLastUpdate.lastUpdate
                                            ).getTime();
                                            if ((today - lastUpdate) * 0.00000000038 < 3) {
                                                fieldIsUpToDate = true;
                                            } else {
                                                fieldIsUpToDate = false;
                                            }
                                        } else {
                                            fieldIsUpToDate = false;
                                        }
                                    });
                                    return !fieldIsUpToDate;
                                })
                                .map((q) => {
                                    return {
                                        title: q.title,
                                        fields: q.fields,
                                        rule: q.earningRule,
                                        value: q.fields
                                            .map((f) => {
                                                const props = f.split('.');
                                                if (props.length === 3) {
                                                    return response[props[0]][props[1]][props[2]];
                                                } else {
                                                    return response[props[0]][props[1]];
                                                }
                                            })
                                            .filter((f) => !!f)
                                            .join(' '),
                                    };
                                })
                                .filter((q) => !!q.value.trim());
                            setProfile(questions);
                        } catch (e) {}
                    }
                },
            });
        } else {
            setProfile(false);
        }
    };

    useEffect(() => {
        if (!!user) {
            SurveysAnswersAPI.get('', {
                params: {
                    'filter[user_id]': user.id,
                },
            }).then(({ data }) => {
                getSurvey(data.data.map((entry) => entry.question_id));
            });
        } else {
            SurveysAnswersAPI.get('', {
                params: {
                    'filter[session_id]': localStorage.getItem('session_id'),
                },
            }).then(({ data }) => {
                getSurvey(data.data.map((entry) => entry.question_id));
            });
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {!!survey && questions.length > 0 && (
                <Loyalty user={user} survey={survey} questions={questions} loyalty={loyalty} />
            )}
            {!!profile.length > 0 && <Profile user={user} questions={profile} />}
        </div>
    );
};

export default Survey;
