import React from 'react';
// import style from './style.module.scss';


const Logo = ({inverse}) => {
    if(!!inverse) {
        return <svg viewBox="0 0 22 22"><path d="M15.4 3.7c-.4 0-.8-.1-1.2-.1H5.4v10.1h2.1c1.8 0 2.7-.8 3.3-1.8V8.3h1.7c2.8 0 3.7 1.7 4.1 3 .1.5.2 1 .2 1.4 0 1.7-.5 4.6-3.8 4.6h-.1c-1.3.7-3 1.1-5 1.1H5.4V22h7.7c6.1 0 8.9-3.6 8.9-9.2 0-4.3-1.8-8.5-6.6-9.1z" fill="#ffffff"/><path d="M12.4 8.3h-1.2c0 .3.1.6.1.8 0 .8-.1 1.9-.6 2.8-.5 1-1.5 1.8-3.3 1.8h-2V3.6h8.8c.4 0 .8 0 1.2.1C14.2 1.5 12.1 0 8.8 0H0v18.4H7.8c2 0 3.7-.4 5-1.1 2.1-1.2 3.3-3.2 3.7-5.9-.3-1.4-1.3-3.1-4.1-3.1z"/></svg>
    } else {
        return <svg viewBox="0 0 22 22"><path d="M15.4 3.7c-.4 0-.8-.1-1.2-.1H5.4v10.1h2.1c1.8 0 2.7-.8 3.3-1.8V8.3h1.7c2.8 0 3.7 1.7 4.1 3 .1.5.2 1 .2 1.4 0 1.7-.5 4.6-3.8 4.6h-.1c-1.3.7-3 1.1-5 1.1H5.4V22h7.7c6.1 0 8.9-3.6 8.9-9.2 0-4.3-1.8-8.5-6.6-9.1z" fill="#e20714"/><path d="M12.4 8.3h-1.2c0 .3.1.6.1.8 0 .8-.1 1.9-.6 2.8-.5 1-1.5 1.8-3.3 1.8h-2V3.6h8.8c.4 0 .8 0 1.2.1C14.2 1.5 12.1 0 8.8 0H0v18.4H7.8c2 0 3.7-.4 5-1.1 2.1-1.2 3.3-3.2 3.7-5.9-.3-1.4-1.3-3.1-4.1-3.1z"/></svg>
    }
}

export default Logo;
