import React, { useState, useContext } from 'react';
import dateFormat from 'dateformat';
import classNames from 'classnames';

import style from './style.module.scss';
import LazyImg from '../LazyImg';
// import { Link } from 'react-router-dom';
import Cta from '../Cta';
import LinkTo from '../LinkTo';
import ShareBar from '../ShareBar';
import { Share, Edit, Plus, Minus, Close, Locker } from '../Icons';

import AppContext from '../../contexts/appContext';

const HoroscopeCard = ({
    label,
    image,
    title,
    labelDate,
    text,
    feedback,
    zodiacs = [],
    isLogged = false,
    isClubMember,
    onFriendAdd,
    onFriendSelect,
    onFriendRemove,
    onFeedbackClick,
    userHoroscope,
    signs,
}) => {
    const { dispatch } = useContext(AppContext);
    const [cardOpen, setcardOpen] = useState(false);
    const [zodiacChanged, setZodiacChanged] = useState(false);
    const [editableZodiac, setEditableZodiac] = useState(false);
    const [friendModalOpen, setFriendModalOpen] = useState(false);
    const [shareBar, setShareBar] = useState(false);

    const cardClasses = classNames({
        [style.card]: true,
        [style.active]: !!cardOpen,
    });

    const actionClasses = classNames(style.actions, {
        [style.active]: !!editableZodiac,
    });

    const onFriendSubmit = (e) => {
        e.preventDefault();
        onFriendAdd(new FormData(e.target), () => setFriendModalOpen(false));
    };

    const onFriendDelete = (id) => {
        onFriendRemove(id, () => setEditableZodiac(false));
    };

    const onLoginClick = () => {
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                onHide: () => {
                    dispatch({ type: 'UNSET_LOADING', payload: null });
                },
            },
        });
    };

    const onShare = () => {
        if (typeof window.dataLayer != 'undefined') {
            window.dataLayer.push({
                event: 'PWA',
                category: 'PWA',
                action: 'Horoscope shared',
                label: 'horoscope share',
            });
        }
    };

    return (
        <div className={cardClasses}>
            <div className={style.image}>
                <p className={style.label}>{label}</p>
                <p className={style.title}>{title}</p>
                <p className={style.date}>{labelDate}</p>
                <div className={style.background}>
                    <LazyImg src="/imgs/texture/red.jpg" alt="backgroundImage" />
                </div>
                {image && (
                    <div className={style.media}>
                        <LazyImg
                            src={image.url}
                            lowres={zodiacChanged && image.url}
                            alt={image.name}
                        />
                    </div>
                )}
            </div>
            <div className={style.wrapper}>
                <div className={style.content}>
                    <div className={style.data}>
                        <div className={style.text} dangerouslySetInnerHTML={{ __html: text }} />
                        {!isLogged && (
                            <LinkTo
                                inApp={false}
                                url="https://www.donnad.it/in-intimita/oroscopo-e-sogni/oroscopo-annuale"
                                label="scopri tutti i segni"
                                arrow={true}
                                modifier="secondary"
                            />
                        )}
                    </div>
                    <div className={actionClasses}>
                        {!isLogged ? (
                            <div>
                                <p className={style.text}>Vuoi vedere subito il tuo segno?</p>
                                <Cta onClick={onLoginClick} label="Accedi o Registrati" />
                            </div>
                        ) : (
                            <div>
                                <div className={style.zodiac}>
                                    {!isClubMember && (
                                        <div className={style.membership}>
                                            <Locker />
                                            <p>Questa è una funzionalità per i "club"</p>
                                            <LinkTo
                                                url="/faq"
                                                label="Scopri come diventare Club Member"
                                                arrow={true}
                                                modifier="secondary"
                                            />
                                        </div>
                                    )}
                                    {isClubMember && friendModalOpen && (
                                        <form
                                            onSubmit={onFriendSubmit}
                                            className={style.friendModal}
                                        >
                                            <span
                                                className={style.close}
                                                onClick={(e) => setFriendModalOpen(false)}
                                            >
                                                <Close />
                                            </span>
                                            <strong>Aggiungi un nuovo segno del cuore</strong>
                                            <div className={style.form}>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="friend_name"
                                                        required
                                                        placeholder="Nome"
                                                    />
                                                    <select name="birthday" required>
                                                        {signs.map((s, j) => (
                                                            <option
                                                                key={j}
                                                                value={dateFormat(
                                                                    new Date(
                                                                        `${s.period_starts_on
                                                                            .split('/')
                                                                            .reverse()
                                                                            .join('/')}/1960`
                                                                    ),
                                                                    'yyyy-mm-dd'
                                                                )}
                                                            >
                                                                {s.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <Cta
                                                    modifiers={['primary-negative']}
                                                    onClick={(e) => 'ciao'}
                                                    label="Aggiungi"
                                                />
                                            </div>
                                        </form>
                                    )}
                                    <div className={style.header}>
                                        <p>
                                            Gli altri segni del cuore
                                            {zodiacs.length > 0 && !editableZodiac && isClubMember && (
                                                <button
                                                    aria-label="modifica"
                                                    onClick={(e) =>
                                                        setEditableZodiac(!editableZodiac)
                                                    }
                                                >
                                                    <Edit />
                                                </button>
                                            )}
                                            {editableZodiac && (
                                                <button
                                                    aria-label="chiudi"
                                                    onClick={(e) =>
                                                        setEditableZodiac(!editableZodiac)
                                                    }
                                                >
                                                    <Close />
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                    <div className={style.list}>
                                        {userHoroscope && (
                                            <button
                                                className={style.item}
                                                onClick={() => {
                                                    onFriendSelect(null);
                                                    setZodiacChanged(true);
                                                }}
                                            >
                                                <span>
                                                    <LazyImg
                                                        src={userHoroscope.image}
                                                        alt={userHoroscope.name}
                                                    />
                                                </span>
                                                <span>{userHoroscope.name}</span>
                                            </button>
                                        )}
                                        {zodiacs.map((l, k) => (
                                            <button
                                                key={k}
                                                className={style.item}
                                                onClick={() => {
                                                    onFriendSelect(l.sign_id);
                                                    setZodiacChanged(true);
                                                }}
                                            >
                                                <span>
                                                    <LazyImg src={l.image} alt={l.name} />
                                                </span>
                                                <span
                                                    className={style.remove}
                                                    onClick={() => onFriendDelete(l.id)}
                                                >
                                                    <Minus />
                                                </span>
                                                <span>{l.name}</span>
                                            </button>
                                        ))}
                                        {zodiacs.length < 3 && isClubMember && (
                                            <button
                                                onClick={() => setFriendModalOpen(true)}
                                                className={classNames(style.item, style.itemplus)}
                                            >
                                                <span>
                                                    <Plus />
                                                </span>{' '}
                                                <span>Aggiungi</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {isLogged && (
                    <div className={style.feedback}>
                        {(!feedback.data || feedback.data.length === 0) && (
                            <div className={style.feedbackForm}>
                                <p>Cosa speri dall'oroscopo di questa settimana?</p>
                                <div>
                                    <Cta
                                        label="che abbia ragione"
                                        onClick={(e) => onFeedbackClick(true)}
                                    />
                                    <Cta
                                        label="che sbagli"
                                        onClick={(e) => onFeedbackClick(false)}
                                    />
                                </div>
                            </div>
                        )}
                        {!!feedback.sum && (
                            <div className={style.feedbackForm}>
                                <p>Quanto ti piacciono le stelle di questa settimana?</p>
                                <div>
                                    <span className={style.count}>
                                        <span>{`${Math.round(feedback.sum.positive)}%`}</span>
                                        <span>Alla grande!</span>
                                    </span>
                                    <span className={style.count}>
                                        <span>{`${Math.round(feedback.sum.negative)}%`}</span>
                                        <span>Tutto sbagliato</span>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className={style.foot}>
                    <span className={`is-hidden-desktop`}>
                        <Cta isLink={false} onClick={(e) => setcardOpen(false)} label="Chiudi" />
                    </span>
                    <button className={style.share} onClick={() => setShareBar(true)}>
                        <span>Condividi</span>
                        <Share />
                    </button>
                    {shareBar && (
                        <div className={style.shareBar}>
                            <ShareBar
                                append={true}
                                onShareClick={onShare}
                                url={`https://my.donnad.it/oroscopo.html?sign=${title.toLowerCase()}`}
                            />
                        </div>
                    )}
                </div>
            </div>
            <span className={style.expand}>
                <Cta isLink={false} onClick={(e) => setcardOpen(true)} label="Scopri di più" />
            </span>
        </div>
    );
};

export default HoroscopeCard;
