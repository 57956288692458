import React, { useEffect, useState, useContext } from 'react';
import { Events as EventsAPI, UserCustom as UserCustomAPI } from '../../Api.js';
import EventCard from '../../components/EventCard';
import AppContext from '../../contexts/appContext';

import { isLoyaltyActive } from '../../utils';

import style from './style.module.scss';

const EventsHp = () => {
    const [feed, setFeed] = useState([]);
    const { state } = useContext(AppContext);
    const [ctaResult, setCtaResult] = useState(null);

    const redeemPoints = (id) => {
        UserCustomAPI.post('/event-card', {
            card_id: id.toString(),
        })
            .then(() => {
                setCtaResult({
                    type: 'points',
                    text: 'Hai ottenuto 100 punti!',
                    url: '/attivita',
                });
            })
            .catch(({ response }) => {
                response.data.error.code === 2
                    ? setCtaResult({
                          type: 'points',
                          text: 'Punti già riscattati',
                          url: '/attivita',
                      })
                    : setCtaResult({
                          type: 'lock',
                          text: 'Scopri come ottenere punti',
                          url: '/faq',
                      });
            });
    };

    useEffect(() => {
        if (state) {
            EventsAPI.get('/items/block_cards', {
                params: {
                    fields: '*,default_image.data,mobile_image.data',
                    'filter[card_type]': 'event',
                    'filter[schedule_starts_on][lte]': 'now',
                    'filter[schedule_ends_on][gte]': 'now',
                },
            }).then(({ data }) => {
                if (data) {
                    setFeed(data.data);
                }
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {feed.length > 0 && (
                <div className={style.eventsWrapper}>
                    {feed.map((item, j) => (
                        <EventCard
                            key={j}
                            id={item.id}
                            date={item.event_date}
                            title={item.title}
                            text={item.text}
                            images={[item.mobile_image.data.full_url, item.default_image]}
                            cta={{ label: item.cta_text, modifiers: [item.cta_color] }}
                            description={item.flipped_text}
                            modifiers={[item.image_position]}
                            textColor={item.all_texts_color}
                            bgColor={[item.background, item.gradient_background]}
                            canRedeem={isLoyaltyActive(state, true)}
                            redeemPoints={redeemPoints}
                            ctaResult={ctaResult}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default EventsHp;
