import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../contexts/appContext';
import style from './style.module.scss';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import {
    Prizes as PrizesAPI,
    Prize as PrizeAPI,
    Loyalty as LoyaltyAPI,
    StaticContent as StaticContentAPI,
} from '../../Api.js';
import ArticleListPreview from '../../components/ArticleListPreview';
import HeadingPage from '../../components/HeadingPage';
import PointsCard from '../../components/PointsCard';
import CategoryCard from '../../components/CategoryCard';
import RewardCard from '../../components/RewardCard';
import Cta from '../../components/Cta';
import Title from '../../components/Title';
import Footer from '../../components/Footer';
import { concat } from 'lodash';

import './style.module.scss';

const Prizes = () => {
    const { state, dispatch } = useContext(AppContext);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userTotalPoints, setUserTotalPoints] = useState(null);
    const [memberRedemptions, setMemberRedemptions] = useState([]);
    const [loadMore, setLoadMore] = useState(1);

    const descrizioni = {
        'Codice sconto Moulinex': {
            description: 'Da usa sul sito <a href="https://www.moulinex.it">https://www.moulinex.it</a>'
        },
        'Codice sconto': {
            description: 'Da utilizzare sul sito <a href="https://it.rewardsforall.com/">https://it.rewardsforall.com/</a>'
        }
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const openPrizeDialog = () => {
        if (userTotalPoints < 12000) {
            alert(
                'Non hai abbastanza punti per redimere questo premio. Partecipa alle altre iniziative per accumulare punti D'
            );
        } else {
            dispatch({
                type: 'OPEN_PRIZE_MODAL',
                payload: {
                    image: '/imgs/static-prize.png',
                    text: `<p>Per richiedere il premio, vai su <a href="https://www.donnad.it/contatti">https://www.donnad.it/contatti</a>, e seleziona «informazioni programma fedeltà».<br> Nel messaggio, indica la richiesta effettuata per il premio «pacco prodotti Henkel», insieme all’indirizzo completo a cui spedirlo.</p><p>Grazie,<br>dalla la tua Amica Fidata</p>`,
                    onClick: () => openInNewTab('https://www.donnad.it/contatti'),
                    onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                    //cta: 'Ottieni il premio',
                },
            });
        }
    };

    const getUserFanStatus = () => {
        LoyaltyAPI.get('/status').then(({ data }) => {
            setUserTotalPoints(data.data.points);
        });
    };

    const getMemberRedemptions = () => {
        LoyaltyAPI.get('/member-redemptions', {
            params: {
                _page: loadMore,
                _itemsOnPage: 2,
            },
        })
            .then(({ data }) => {
                setMemberRedemptions((memberRedemptions) => concat(memberRedemptions, data.items));
                data.total.filtered
                    ? data.total.filtered / 2 == 0
                        ? setLoadMore(null)
                        : setLoadMore(loadMore + 1)
                    : setLoadMore(null);
            })
            .then(() => {
                setLoading(false);
            });
    };

    const getCategoriesInfo = (categories) => {

        StaticContentAPI.get('/asset/reward-categories/categories_v1.json?'+getRandomInt(1000)).then(( res ) => {
            let categoriesInfo = res.data;
            categories.forEach((category) => {
                category.description = categoriesInfo[category.rewardCategoryId].description;
                category.image = categoriesInfo[category.rewardCategoryId].image;
            });
            setCategories(categories);
         
            setLoading(false);
        });
    };

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }

    const getRewardCategories = () => {
        LoyaltyAPI.get('/reward-categories').then(({ data }) => {
            let categories = data.items;
            getCategoriesInfo(categories);
        });
    };

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'prizes' } });

        if (state.user) {
            //refreshPrizes();
            getUserFanStatus();
            getRewardCategories();
            getMemberRedemptions();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Trasforma i tuoi punti in premi | DonnaD</title>
            </Helmet>
            <div className="content">
                <HeadingPage
                    title="<h1>Trasforma i tuoi punti in premi!</h1>"
                    text="Se sei iscritta al programma fedeltà <strong>Dfamily</strong>, usa i punti che hai guadagnato per accedere ai premi istantanei."
                />

                <div className={style.pointscard}>
                    <PointsCard
                        label={`Ciao ${state.user.firstName},<br />Il tuo saldo punti è`}
                        value={userTotalPoints}
                        texture="centered"
                    />
                </div>

                <div className={style.prize}>
                    <div className={style.data}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: `Scopri il <strong style="font-size:18px;color: #e1000f;">catalogo premi della Dfamily</strong>`,
                            }}
                        ></p>
                    </div>

                    <div className={style.cardContainer}>
                        {!loading ? (
                            categories.length > 0 ? (
                                categories.map((category, j) => (
                                    <CategoryCard
                                        key={j}
                                        textTitle={category.name}
                                        cta={category.active  ? {
                                                label: 'RICHIEDI IL PREMIO',
                                                modifiers: ['bg-corporate'],
                                                url: '/premi/'+category.rewardCategoryId
                                            } : category.active ? 
                                             {
                                                label: 'RICHIEDI IL PREMIO',
                                                onClick: () => openPrizeDialog(0),
                                                modifiers: ['bg-corporate']
                                            } : null
                                        }
                                        image={{
                                            src: category.image,
                                            name: 'cartegorie',
                                        }}
                                        description={category.description}
                                        active={category.active}
                                    />
                                ))
                            ) : (
                                <p className={style.nores}>Non ci sono ancora premi attivi.</p>
                            )
                        ) : (
                            <ArticleListPreview />
                        )}
                        {memberRedemptions.length > 0 ? (
                            <Title title='<b style="display:block;margin-top:30px;text-align: center;">Hai già riscattato i seguenti premi:</b>'></Title>
                        ) : (
                            ''
                        )}
                        {memberRedemptions.length > 0
                            ? memberRedemptions.map((ac, j) => (
                                  <RewardCard
                                      key={j}
                                      page="prizes"
                                        image={`${process.env.REACT_APP_STATIC_CONTENT}` +
                                        '/asset/reward/' +
                                        ac.rewardId +
                                        '.png'}
                                      name={ac.name}
                                      description={
                                          'Puoi trovare il tuo codice su https://my.donnad.it/coupons'
                                      }
                                      backgroundColor="#e1000f"
                                  ></RewardCard>
                              ))
                            : ''}

                        {memberRedemptions.length > 0 ? (
                            <Cta label="Mostra di più" onClick={loadMore && getMemberRedemptions} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Prizes;
