const jwtHelper = (() => {
    return {
        get: () => localStorage.getItem('jwt'),
        set: (token) => {
            localStorage.setItem('jwt', token);
        },
        refresh: (callback) => {
            if (window.gigya.accounts) {
                return new Promise((resolve) => {
                    window.gigya.accounts.getJWT({
                        fields: process.env.REACT_APP_GIGYA_JWT_FIELDS,
                        expiration: 60 * 60 * 2,
                        callback: ({ id_token }) => {
                            localStorage.setItem('jwt', id_token);
                            resolve();
                            if (callback) {
                                callback();
                            }
                        },
                    });
                });
            }
        },
    };
})();

export default jwtHelper;
