import React, { useContext, useReducer, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { spring, AnimatedSwitch } from 'react-router-transition';
import vhCheck from 'vh-check';
import yall from 'yall-js';

import jwtHelper from './utils/jwtHelper.js';
import { isOnLine, isLoyaltyActive, randomID } from './utils';
import {setIosAppInstalled} from './utils/InstallApp';
import { LoyaltyActive as LoyaltyActiveAPI, Notifications as NotificationsAPI } from './Api';

import Home from './pages/Home';
import Article from './pages/Article';
import Bookmarks from './pages/Bookmarks';
import Prizes from './pages/Prizes';
import PrizesStatic from './pages/PrizesStatic';
import Activities from './pages/Activities';
import MyAccount from './pages/MyAccount';
import ReceiptsList from './pages/ReceiptsList';
import HowToGainPoints from './pages/HowToGainPoints';
import Faq from './pages/Faq';
import Coupons from './pages/Coupons';
import AccountDeleted from './pages/AccountDeleted';
import NoMatch from './pages/NoMatch';
import Notifications from './pages/Notifications';
import Horoscope from './pages/Horoscope';

import OfflineBanner from './components/OfflineBanner';
import Spinner from './components/Spinner';
import ScrollToTop from './components/ScrollToTop';
import Membership from './components/Membership';
import Receipts from './components/Receipts';
import ArticleTimeBar from './components/ArticleTimeBar';
import ModalBannerPwa from './components/ModalBannerPwa';
import Alert from './components/Alert';
import Survey from './components/Survey';
import PrizeModal from './components/PrizeModal';

import AppContext from './contexts/appContext';
import AppReducer from './reducers/appReducer';

import Header from './components/Header';
import RewardCategory from './pages/RewardCategory/index.js';
import SocialShare from './components/SocialShare';
import MessageAlert from './components/MessageAlert';
// import StickyNav from './components/StickyNav';

const mapStyles = (styles) => {
    return {
        opacity: styles.opacity,
        transform: `translateY(${styles.scale}%)`,
    };
};

const animate = (val) => {
    return spring(val, {
        stiffness: 208,
        damping: 23,
    });
};

const springTransition = {
    atEnter: {
        opacity: 0,
        scale: 100,
    },
    atLeave: {
        opacity: animate(0),
        scale: animate(0.8),
    },
    atActive: {
        opacity: animate(1),
        scale: animate(0),
    },
};

const App = () => {
    const initialState = useContext(AppContext);
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const [userCheck, setUserCheck] = useState(false);
    const [lastNoteDate, setNotification] = useState(false);

    const afterLogin = () => {
        NotificationsAPI.get().then(({ data }) => {
            if (data.data.length > 0) {
                setNotification(data.data[0].created_on);
            }
        });

        setIosAppInstalled();
        
    };

    const userLogin = (response, auto = false) => {
        if (!auto) {
            window.location.reload();
        }
        jwtHelper
            .refresh()
            .then(() => {
                afterLogin();
                dispatch({
                    type: 'USER_LOGGEDIN',
                    payload: {
                        data: response.data,
                        id: response.UID,
                        ...response.profile,
                    },
                });
                setUserCheck(true);
            })
            .catch((error) => console.log(error));
    };

    const generateSessionId = () => {
        if (!localStorage.getItem('session_id')) {
            localStorage.setItem('session_id', randomID());
        }
    };

    const onUploadTicketClick = () => {
        dispatch({ type: 'TOGGLE_RECEIPTS' });
    };

    useEffect(() => {
        if (!isOnLine()) {
            dispatch({ type: 'UNSET_LOADING' });
            setUserCheck(true);
        }

        const onSSOServiceReady = () => {
            window.gigya.accounts.addEventHandlers({ onLogin: userLogin });
            window.gigya.accounts.addEventHandlers({
                onLogout: (response) => {
                    setTimeout(() => {
                        dispatch({ type: 'USER_LOGGEDOUT', payload: response });
                        window.location.href = '/';
                    }, 300);
                },
            });
            dispatch({ type: 'UNSET_LOADING' });
        };

        window.onGigyaServiceReady = onSSOServiceReady;

        vhCheck();

        yall({
            observeChanges: true,
            events: {
                load: function (event) {
                    if (
                        !event.target.classList.contains('lazy') &&
                        event.target.nodeName === 'IMG'
                    ) {
                        event.target.classList.add('-loaded');
                    }
                },
            },
        });

        //Check user is logged in
        if (window.gigya) {
            window.gigya.accounts.getAccountInfo({
                extraProfileFields: 'address',
                callback: (response) => {
                    if (response.status === 'OK') {
                        userLogin(response, true);
                    } else {
                        dispatch({ type: 'UNSET_LOADING' });
                        setUserCheck(true);
                    }
                },
            });
        } else {
            dispatch({ type: 'UNSET_LOADING' });
            setUserCheck(true);
            return;
        }

        //Check loyaty status
        LoyaltyActiveAPI.get('/is-active')
            .then(({ data }) => {
                dispatch({ type: 'LOYALTY_STATE', payload: data.data });
            })
            .catch((error) => {
                dispatch({ type: 'LOYALTY_STATE', payload: 'error' });
            });

        generateSessionId();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            <Router>
                <Header lastNoteDate={lastNoteDate} />
                {!!state.current_article && !!state.user && (
                    <ArticleTimeBar id={state.current_article ? state.current_article.nid : null} />
                )}
                <ScrollToTop />
                {state.loading && <Spinner />}
                {!!userCheck && (
                    <AnimatedSwitch
                        atEnter={springTransition.atEnter}
                        atLeave={springTransition.atLeave}
                        atActive={springTransition.atActive}
                        mapStyles={mapStyles}
                        className="switch-wrapper"
                    >
                        {!!state.user && <Route exact path={`/preferiti`} component={Bookmarks} />}
                        {!!state.user && (
                            <Route exact path={`/notifiche`} component={Notifications} />
                        )}
                        <Route path={`/articoli/:id/:slug`} component={Article} />
                        <Route exact path={`/`} component={Home} />
                        {!!state.user && isLoyaltyActive(state) && (
                            <Route exact path={`/come-guadagnare-punti`} component={HowToGainPoints} />
                        )}
                        <Route exact path={`/faq`} component={Faq} />
                        <Route
                            exact
                            path={`/oroscopo/:sign`}
                            component={() => <Horoscope logged={!!state.user} />}
                        />
                        {!!state.user && (
                            <Route exact path={`/scontrini`} component={ReceiptsList} />
                        )}
                        {!!state.user && (
                            <Route exact path={`/utente/:section?`} component={MyAccount} />
                        )}
                        {!!state.user && isLoyaltyActive(state,true) && (
                            <Route exact path={`/premi`} component={Prizes} /> // page for dynamic listing of prizes
                        )}
                        {!!state.user && isLoyaltyActive(state,true) && (
                            <Route exact path={`/attivita`} component={Activities} />
                        )}
                         {!!state.user && isLoyaltyActive(state, true) && (
                            <Route exact path={`/premi/:rewardCategoryId`} component={RewardCategory} />
                        )}
                        {!!state.user && <Route exact path={`/coupons`} component={Coupons} />}
                        {!!!state.user && (
                            <Route exact path={`/account-cancellato`} component={AccountDeleted} />
                        )}
                        <Route component={NoMatch} />
                    </AnimatedSwitch>
                )}
                <Membership />
                <Receipts context={{ state, dispatch }} />
                <ModalBannerPwa />

                {state.alert && (
                    <Alert
                        text={state.alert.text}
                        visibility={true}
                        primaryCta={
                            state.alert.primaryCta && {
                                label: state.alert.primaryCta.label || 'Conferma',
                                onClick: state.alert.primaryCta.onClick,
                            }
                        }
                        modifiers={state.alert.modifiers}
                        secondaryCta={{
                            label:
                                (state.alert.secondaryCta && state.alert.secondaryCta.label) ||
                                'Annulla',
                            onClick: state.alert.secondaryCta && state.alert.secondaryCta.onClick,
                        }}
                    />
                )}

                {state.currentPrize && (
                    <PrizeModal
                        shopping={state.currentPrize.shopping}
                        title={state.currentPrize.title}
                        visibility={true}
                        type={state.currentPrize.type}
                        image={state.currentPrize.image}
                        code={state.currentPrize.code}
                        content={state.currentPrize.text}
                        onClick={state.currentPrize.onClick}
                        onClose={state.currentPrize.onClose}
                        disableClose={state.currentPrize.disableClose}
                        cta={state.currentPrize.cta ? state.currentPrize.cta : 'Richiedi il premio' }
                    />
                )}

                {!isOnLine() && <OfflineBanner />}
                {!!userCheck && <Survey user={state.user} loyalty={isLoyaltyActive(state, true)} />}
                <SocialShare context={{ state, dispatch }} />
                <MessageAlert context={{ state, dispatch }} />
                {/*!!state.user && (
                    <StickyNav
                        onUploadTicketClick={onUploadTicketClick}
                        loyalty={isLoyaltyActive(state, true) && state.loyalty_status === true}
                    />
                )*/}
            </Router>
        </AppContext.Provider>
    );
};

export default App;
