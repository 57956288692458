import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../contexts/appContext';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import Cta from '../Cta';

import {Loyalty as LoyaltyAPI} from '../../Api.js';

const CardReward = ({ item, points, preferredRewardId, updateCardRewards }) => {

    const { state, dispatch } = useContext(AppContext);
    const [showFullText, setShowFullText] = useState(false);
    const [maxLength, setMaxLength] = useState(200);
    const [description, setDescription] = useState('');
    const [fullText, setFullText] = useState(item.shortDescription);
    const [longText, setLongText] = useState(false);
    
    useEffect(() => {
        if(fullText.length>maxLength){
            setDescription(fullText.substring(0, maxLength) + '...');
            setShowFullText(false);
            setLongText(true);
        }else{
            setDescription(fullText);
            setShowFullText(true);
        }

        window.addEventListener('gigyaScreensetAfterSubmit', function (e) {
            if(e.returnValue){
                //chiudo il form vedi messageAlert
                //salvo la campagna
                gigyaSaveUserCampaigns();
    
            }else{
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Ops, c'è stato un problema, contatta il supporto</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_8',
                    },
                });
            } 
        });

    }, []);

    const toggleShowFullText = () => {
        if(showFullText){
            setDescription(fullText.substring(0, maxLength) + '...');
            setShowFullText(false);         
        }else{
            setDescription(fullText);
            setShowFullText(true);           
        }
    };

    const setCta = () => {
        
        if(points < item.costInPoints){
            return {
                label: 'Guadagna punti D',
                modifiers: ['bg-white','small'],
                onClick: () => onClickCta(),
            };
        }else{
            return {
                label: 'Richiedi il premio',
                modifiers: ['bg-corporate','small'],
                onClick: () => onClickCta(),
            };
        }

    }

    const onClickCta = () => {

        if(points < item.costInPoints){

            dispatch({
                type: 'OPEN_PRIZE_MODAL',
                payload: {
                    onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                    type: 'type_coupon_3',
                    text:
                        'Non hai abbastanza punti D per redimere questo premio. Partecipa alle altre iniziative per accumulare punti D',
                },
            });

        }else{
                    
            let categoryName = item.categoryNames[item.categories[0]];
            switch(categoryName){
                case 'Pacco Prodotti':
                    dispatch({
                        type: 'OPEN_PRIZE_MODAL',
                        payload: {
                            text: `<p>Vuoi riscattare il pacco prodotti Henkel per il valore di 12.000 punti D?</p> 
                                    <p>Nella tua box, troverai un assortimento dei seguenti prodotti: Dixan, Pril, Nelsen, Vernel, L’Acchiappacolore, Bref WC, Schwarzkopf Palette, Gliss e Perlana.</p>
                                    <p>Che aspetti?</p>`,
                            onClick: () => {buyReward('material',categoryName)},
                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                            type: 'type_coupon_6',
                        },
                    });
                    break;
                case 'Buoni Sconto':
                    dispatch({
                        type: 'OPEN_PRIZE_MODAL',
                        payload: {
                            text: `<p>Puoi trovare il buono sconto selezionato nella <b>tua area personale «I miei coupon»</b> su MyDonnaD</p>`,
                            onClick: () => {buyReward('coupon',categoryName)},
                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                            type: 'type_coupon_6',
                        },
                    });
                    break;
                case 'Shopping':
                    dispatch({
                        type: 'OPEN_PRIZE_MODAL',
                        payload: {
                            text: `<p>Puoi trovare il codice sconto selezionato nella <b>tua area personale «I miei codici»</b> su MyDonnaD</p>`,
                            onClick: () => {buyReward('code',categoryName)},
                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                            type: 'type_coupon_2',
                        },
                    });
                    break;
                case 'Divertimento ed Esperienze':
                    dispatch({
                        type: 'OPEN_PRIZE_MODAL',
                        payload: {
                            text: `<p>Puoi trovare il codice sconto selezionato nella <b>tua area personale «I miei codici»</b> su MyDonnaD</p>`,
                            onClick: () => {buyReward('code',categoryName)},
                            onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                            type: 'type_coupon_2',
                        },
                    });
                    break;
                default:
                    console.log('no cta');
            }
        }

    }

    const buyReward = (rewardType,categoryName) => {
        dispatch({ type: 'CLOSE_PRIZE_MODAL' });

        LoyaltyAPI.post('/buy-reward', {
            reward_id: item.rewardId,
            type: rewardType,
        }).then((res) => {
            if (res.data.success) {

                let code = '';
                switch(categoryName){
                    case 'Pacco Prodotti':
                        //window.location.href = '/material';
                        dispatch({ 
                            type: 'TOGGLE_MESSAGE_ALERT', 
                            payload: {
                                message: {
                                    title: "Grazie per aver riscattato il premio.",
                                    description: "Compila il form con tutti i dati richiesti per riceverlo entro massimo 180 giorni direttamente all'indirizzo da te indicato.",
                                    gigya_form: {
                                        screenSet: 'Restyle2017-ProfileUpdate',
                                        startScreen: 'gigya-update-profile-Loyalty2023-PrizeBoxRequest',
                                        containerID: 'gigya-form-container',
                                    }
                                },
                            }                     
                        });
                        break;
                    case 'Buoni Sconto':
                        window.location.href = '/coupons';
                        break;
                    case 'Divertimento ed Esperienze':
                    case 'Shopping':
                        code = res.data.code_value;
                        dispatch({
                            type: 'OPEN_PRIZE_MODAL',
                            payload: {
                                text:
                                    `<p>Come attivare il codice.<br> Ricordati di copiarlo e conservarlo con cura.</p>`
                                    + item.usageInstruction
                                    + code,
                                onClick: () => {
                                    window.location.href = item.labels && item.labels.length >= 2 ? item.labels[1].value : null; //link
                                },
                                onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                                type: 'type_coupon_5',
                                shopping: item.labels && item.labels.length >= 3 ? item.labels[2].value : null, //spesaMin
                            },
                        });
                        break;
                    default:
                }
                //remove preferred reward
                if(preferredRewardId === item.rewardId){
                    setPreferredReward("");
                }
            }
        });
    }

    const gigyaSaveUserCampaigns = () => {
        let formData = new FormData();
        formData.append('name', '2024 - LOYALTY PACCO PRODOTTO');
        formData.append('category', "LAUNDRY");
        formData.append('type', "BRANDCONTEST");
        formData.append('brand', "DIXAN");
        formData.append('wave', "2403");

        LoyaltyAPI.post('/gigya-save-user-campaigns',formData).then((response) => {
            if(response.data.status.statusCode == 200){
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Grazie per aver compilato il form correttamente con i tuoi dati. Riceverai il pacco prodotti Henkel all'indirizzo da te indicato entro massimo 180 giorni.</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),                    
                        type: 'type_coupon_8',
                    },
                });
            }else{
                dispatch({
                    type: 'OPEN_PRIZE_MODAL',
                    payload: {
                        text:
                            `<p>Ops, c'è stato un problema, contatta il supporto</p>`,
                        onClose: () => dispatch({ type: 'CLOSE_PRIZE_MODAL' }),
                        type: 'type_coupon_8',
                    },
                });
            }
        });
    };

    const prop = () => {

        let perc = (100 / item.costInPoints) * points;
        if(perc > 100){
            return '100';
        }
        return perc;

    };

    const setPreferredReward = (rewardId) => {
        if(preferredRewardId === rewardId){
            rewardId = "";
        }
        LoyaltyAPI.post('/preferred-reward', {
            reward_id: rewardId,
        }).then((res) => {
            if(res.data.data == "ok"){
                updateCardRewards();
            }
        });
    }

    return (
        <div className={style.card_container}>
            <div className={longText && showFullText ? style.card + ' ' + style.long : style.card} >
                <div className={style.image}>
                    <LazyImg src={item.rewardImageUrl + item.rewardId + "/photo/" + item.photos[0].id} alt="backgroundImage" />
                </div>
                <div className={style.content}>
                    <div className={style.left}>
                        <p className={style.title}>{item.name}</p>
                        <div className={style.description}>
                            <p dangerouslySetInnerHTML={{ __html: description }}></p>
                            <span>{longText?<button onClick={() => toggleShowFullText()}>{showFullText ? 'Leggi di meno' : 'Leggi di più'}</button>:''}</span>
                        </div>                     
                        <div className={style.progressBar}>
                            <div className={style.progress} style={{ width: prop() + "%" }}></div>
                        </div> 
                        <div className={style.myPoints}>
                            <span>{item.costInPoints-points<=0 ? '' : item.costInPoints-points + ' punti D mancanti'}</span>
                        </div>
                    </div>
                    <div className={style.right}>
                        <button onClick={() => setPreferredReward(item.rewardId)} className={style.ctaPrefferedReward}/*  title='Imposta come premio preferito' */>
                            <img className={style.star} src={'/imgs/loyalty/' + (preferredRewardId === item.rewardId? 'star-checked.png' : 'star-unchecked.png')} alt="" />
                        </button>
                        <p className={style.points}><strong>{item.costInPoints}</strong> Punti D</p>
                        {item.usageLimit.general !==0 ? <Cta {...setCta()} /> : "Premio esaurito"}                                    
                    </div>               
                </div>
            </div>
        </div>
    );
};

export default CardReward;
