import React from 'react';
import { Link } from "react-router-dom";

import LazyImg from '../LazyImg';
import {Bookmarked,Check} from '../Icons';

import style from './style.module.scss';

const ArticleTease = ({id,slug,title,caption,tag,thumbnail,time,labels,bookmark,onBookmarkClick,read}) => {
    return (
        <article className={style.wrapper}>
            <Link to={`/articoli/${id}${slug}`}>
                <div className={style.el}>
                    <div className={style.thumbnail}>
                        <div><LazyImg key={id} src={thumbnail} alt={title}/></div>
                    </div>
                    <div className={style.content}>
                        {caption && <strong className={style.caption}>{caption}</strong>}
                        <h2 className={style.title} dangerouslySetInnerHTML={{__html:title}}/>
                        <p>
                            {labels && labels.map((l,k) => <span key={k} className={style.tag}>{l}</span>)}
                            {read && <span className={`${style.tag} ${style.read}`}><Check/>Letto</span>}
                            {time && <span className={style.time}>{`LEGGI IN ${time}'`}</span>}
                        </p>
                    </div>
                </div>
            </Link>
            {!!bookmark && <span onClick={onBookmarkClick} className={style.bookmark}><Bookmarked/></span>}
        </article>
    );
}

export default ArticleTease;
