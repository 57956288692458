import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import { ArrowDown } from '../Icons';

const Accordion = ({onClick, title, text, isActive}) => {
    const classes = classNames(style.accordion, {
        [style.active] : !!isActive
    });

    return (
        <div onClick={onClick}  className={classes}>
            <div className={style.heading}>
                <h3>{title}</h3>
                <ArrowDown />
            </div>
            <div className={style.content} dangerouslySetInnerHTML={{__html: text}}>
            </div>
        </div>
    );
}

export default Accordion;


