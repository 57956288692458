import React, { useState } from 'react';
import { Loyalty as LoyaltyAPI } from '../Api';
import { isIOS } from './index.js';

export const appInstalledEvents = () =>{

    window.gigya.accounts.getAccountInfo({
        callback: (response) => {
            if (response.status === 'OK') {
                if(!response.data.membership.pwaInstallDate){

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'PWA',
                            category: 'PWA',
                            action: 'PWA installed',
                            label: 'Custom installation banner',
                            noninteractive: true,
                        });
                    }
                
                    if (window.gigya) {
                        window.gigya.accounts.setAccountInfo({
                            data: { membership: { pwaInstallDate: new Date() } },
                            callback: (response) => {
                                if(response.errorCode==0){
                                    LoyaltyAPI.post('/event', {
                                        event_name: 'app_installed',
                                    }).then(({ data }) => {
                                        console.log(data);
                                    });
                                }         
                            },
                        });
                        
                    }

                }
            } else {
                console.log('An error occurred');
            }
        },
    });

}

export const setIosAppInstalled = () => {
    window.gigya.accounts.getAccountInfo({
        callback: (response) => {
            if (response.status === 'OK') {
                if(!response.data.membership.pwaInstallDate){
                    //Verifico se su ios ho installato l'app, se si memorizzo su gigya e rilascio i punti
                    if (isIOS() && window.navigator.standalone === true) {
                        appInstalledEvents();
                    }
                }
            } else {
                console.log('An error occurred');
            }
        },
    });
};

export const installAndroidApp = (deferredPrompt) => {
    console.log(deferredPrompt);
    // Controlla se c'è un evento beforeinstallprompt memorizzato
    if (deferredPrompt) {
        // Mostra la prompt per l'installazione dell'app
        deferredPrompt.prompt();
        // Gestisci la scelta dell'utente
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('L\'utente ha accettato l\'installazione');
                // L'utente ha accettato l'installazione
            } else {
                console.log('L\'utente ha rifiutato l\'installazione');
                // L'utente ha rifiutato l'installazione
            }
            // Pulisci l'evento memorizzato per evitare di mostrarlo nuovamente
            deferredPrompt = null;
        });
    }
};