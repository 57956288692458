import React, {useEffect,useState,useContext} from 'react';
import { Helmet } from "react-helmet";
import {find} from 'lodash';

import AppContext from '../../contexts/appContext';
import {Articles as ArticlesAPI, UserArticles as UserArticlesAPI} from '../../Api.js';
import ArticleList from "../../components/ArticleList";
import Cta from "../../components/Cta";
import ArticleListPreview from "../../components/ArticleListPreview";

import style from './style.module.scss';

const Bookmarks = () => {
    const [bookmarks, setBookmarks] = useState([]);
    const [bookmarksData, setBookmarksData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {state, dispatch } = useContext(AppContext);

    const refreshBookmarks = () => {

        UserArticlesAPI.get('',{
            params: {
                'filter[bookmarked]' : 1,
                'sort': 'modified_on'
            }
        })
        .then(({data}) => {
            setBookmarksData(data.data);
            setBookmarks([]);
            if(data.data.length === 0) {
                setLoading(false);
            } else {
                const calls = data.data.map(b => ArticlesAPI.get(`/node-${b.drupal_nid}.json`))
                calls.forEach((c,j) => {
                    c.then(article => {
                        setBookmarks(bookmarks => {
                            const bks = [...bookmarks,article.data.data];
                            const new_bks = [];
                            data.data.forEach(bd => {
                                const bookmark = find(bks, b => parseInt(b.nid) === bd.drupal_nid);
                                if(bookmark) {
                                    new_bks.push(bookmark);
                                }
                            });
                            return new_bks;
                        })
                        if(j === calls.length - 1) setLoading(false);
                    }).catch(error => {
                        console.log(`${error.response.status} - ${error.response.request.responseURL} - Article not found`);
                        if(j === calls.length - 1) setLoading(false);
                    })
                })
            }
        });
    }

    useEffect(() => {

        dispatch({type: 'SET_PAGE', payload: { type: 'bookmarks' }})

        if(state.user) {
            refreshBookmarks();
        }

    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const onBookmarkClick = nid => {
        dispatch({type: 'SET_LOADING' })

        const data = {
            "drupal_nid": nid,
            "bookmarked": false
        };

        UserArticlesAPI.patch(`/${getBookmarkByNid(nid)}`,data)
        .then(({data}) => {
            refreshBookmarks();
            dispatch({type: 'UNSET_LOADING' })
            if(typeof window.dataLayer!='undefined'){
                window.dataLayer.push({
                    'event':'PWA',
                    'category':'PWA',
                    'action':'Article bookmarked',
                    'label': 'removed'
                });
            }
        });
    }

    const getBookmarkByNid = nid => {
        return find(bookmarksData, ({drupal_nid}) => drupal_nid === +nid).id
    }

    return (
        <div className={style.el}>
            <Helmet>
                <title>Bookmarks | DonnaD</title>
            </Helmet>
            {!loading ? bookmarks.length > 0 ? <div className={style.listWrapper}><ArticleList
                title="I miei segnalibri"
                items={bookmarks.map(f => {
                    return {
                        id: f.nid,
                        slug: f.slug,
                        thumbnail: f.image,
                        caption: f.category.title,
                        title: f.title,
                        labels: f.labels,
                        bookmark: true,
                        time: f.estimated_read_time,
                        onBookmarkClick: () => onBookmarkClick(f.nid)
                    }
                })}
            /></div> : <div className={style.nores}>
                <h2>I miei segnalibri</h2>
                <p>La funzione “Segnalibri” ti permette di salvare nella tua area riservata tutti gli articoli DonnaD che ti interessano.</p>
                <p>Puoi farlo in qualsiasi momento, cliccando sulla prima icona.</p>
                <img alt="bookamark" src="/imgs/boookmarks_tip.png"/><br/>
                <Cta label="Torna alla home" url="/"/>
            </div> : <ArticleListPreview/>}
        </div>
    );
}

export default Bookmarks;
