import React, { useContext } from 'react';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import './style.module.scss';

import { Close } from '../Icons';

import AppContext from '../../contexts/appContext';

const ModalBannerPwa = () => {
    const { state, dispatch } = useContext(AppContext);

    const classes = classNames('modalpwa', {
        active: !!state.pwaBannerModal,
    });

    return (
        <div className={classes}>
            <div className="mask">
                <div className="background">
                    <LazyImg src="/imgs/texture/membership.jpg" alt="background" />
                </div>
                <div className="wrapper">
                    <button className="close" onClick={() => dispatch({ type: 'CLOSE_APPBANNER' })}>
                        <Close />
                    </button>
                    <div className="media">
                        <LazyImg src="/imgs/membership/slides/3.svg" alt="background" />
                    </div>

                    <p className="title">
                        Salva l’app in un
                        <br />
                        attimo!
                    </p>
                    <ul>
                        <li>Fai tap sull’icona di Share.</li>
                        <li>Scegli ‘Aggiungi alla Home’.</li>
                        <li>Controlla il nome dell’app e fai tap su ‘Aggiungi’.</li>
                    </ul>
                    <div className="save">
                        <LazyImg src="/imgs/saveapp.svg" alt="background" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalBannerPwa;
