import React, { useContext } from 'react';
import style from './style.module.scss';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/appContext';

import Logo from '../../components/Logo';
import Cta from '../../components/Cta';
import Latest from '../Home/latest';

const NoMatch = () => {
    const { state, dispatch } = useContext(AppContext);

    const Login = () => {
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                onHide: () => {
                    dispatch({ type: 'UNSET_LOADING', payload: null });
                },
            },
        });
    };

    return (
        <div className={style.el}>
            <Helmet>
                <title>Not Found | DonnaD</title>
            </Helmet>
            <div className={style.head}>
                <Logo />
                {!!state.user && <p className={style.title}>Ops, qui sembra non esserci nulla…</p>}
                {!!!state.user && (
                    <p className={style.title}>
                        Effettua il login per accedere a tutte le funzionalità
                    </p>
                )}
                {!!!state.user && (
                    <div>
                        <div>
                            <Cta label="Accedi / Registrati" onClick={Login} />
                        </div>
                        <p className={style.sectiontitle}>
                            Se non riesci a visionare la pagina desiderata,
                            <br /> ti consigliamo di tornare in homepage e cercare il contenuto
                            desiderato
                        </p>
                        <h2>Oppure prova uno di questi contenuti.</h2>
                    </div>
                )}
            </div>

            <div className={style.articles}>
                {!!state.user && <h2>Prova uno di questi contenuti.</h2>}
                <div className="content">
                    <Latest limit={3} />
                </div>
            </div>

            <div className={style.actions}>
                <Cta label="torna alla home" url="/" />
            </div>
        </div>
    );
};

export default NoMatch;
