export const PointsGalleryData = {
    items: [
        {
            title: 'Partecipa a Dfamily',
            description: 'Ottieni i tuoi primi punti di benvenuto partecipando al programma di fedeltà.',
            points: 150,
            cta: {
                label: 'Vai al profilo',
                url: '/utente',
            },
        },
        {
            title: 'Invita i tuoi amici',
            description:
                'Tu inviti i tuoi amici a iscriversi a DonnaD e a entrare a far parte del programma fedeltà Dfamily, e noi ti regaliamo tanti punti!',
            points: '300 + 100 e 200',
            info:
                '100 punti on top per ogni 5 amici invitati che si registrano a Dfamily e 200 punti per il primo scontrino di prodotti Henkel caricato e riconosciuto dei tuoi amici',
            cta: {
                label: 'Invita un amico',
                url: '/',
            },
        },
        {
            title: 'Carica il tuo scontrino',
            description:
                'Carica la tua spesa. Riceverai 300 punti per ogni prodotto Henkel che carichi su MyDonnaD. Trova sotto la lista completa dei prodotti riconosciuti.',
            points: "300 + 100",
            info: 'Bonus di 100 punti on top ad ogni 5 scontrini caricati e validati',
            cta: {
                label: 'Gioca',
                url: '/',
            },
        },
        {
            title: 'Raccontaci di te e dei tuoi gusti e rispondi ai sondaggi',
            description:
                'Ogni tanto ti faremo qualche domanda su di te o sulle tue abitudini di shopping oppure ti invieremo dei quiz e dei sondaggi. Se vuoi risponderci, c’è una pioggia di punti per te!',
            points: "1000",
            cta: {
                label: 'Raccontaci di te',
                url: '/',
            },
        },
        {
            title: 'Installa la PWA',
            description:
                'Installa la PWA e ricevi 800 punti D. Fai tap all’icona di Share, scegli ‘aggiungi alla Home, controlla il nome dell’app e fai tap su “aggiungi”. Che aspetti?',
            points: "800",
            cta: {
                label: 'Installa la PWA',
                url: '/',
            },
        },
        {
            title: 'Ricevi punti D a 6 mesi dall\'adesione al programma fedeltà Dfamily.',
            description:
                'La Dfamily premia i mesi da cui sei con noi. Sono già passati 6 mesi da quando hai aderito al programma fedeltà Dfamily? Per festeggiare, per te 200 punti D.',
            points: "200",
            cta: {
                label: '',
                url: '/',
            },
        },
        {
            title: 'Partecipa alle iniziative su DonnaD! Ogni 15 giorni riceverei ',
            description:
                'Accedi a DonnaD e partecipa alle iniziative e concorsi attivi. Accumulerai 100 punti D nell’arco dei 15 giorni dal tuo login. Che aspetti?',
            points: "100",
            cta: {
                label: '',
                url: '/',
            },
        },
        {
            title: 'Completa il tuo profilo!',
            description:
                'Completa il tuo profilo su MyDonnaD, entro 24 ore riceverai 500 punti D.',
            sub_description: 'Ricordati di completare i seguenti campi: <ul><li>• nome e cognome</li><li>• data di nascita (giorno, mese, anno)</li><li>• sesso</li><li>• indirizzo (regione, provincia, città, via e numero, cap)</li><li>• telefono di contatto (cellulare)</li><li>• informazione personale (livello di istruzione, professione)</li><li>• punto vendita preferito</li><li>• possiedi una lavastoviglie?</li><li>• il tuo nucleo familiare</li></ul>',
            points: "500",
            cta: {
                label: '',
                url: '/',
            },
        },
        {
            title: 'Gioca allo Specchio Magico e guadagna punti D ',
            description:
                'Partecipa allo Specchio Magico per accumulare punti D.',
            points: "300",
            cta: {
                label: '',
                url: '/',
            },
        },

    ],
};

export const PointsSections = [
    {
        title: '<h2>I prodotti Henkel da acquistare per accumulare 300 punti. Carica lo scontrino della spesa che contiene prodotti delle seguenti famiglie</h2>',
        items: [
            {
                title:
                    '<p><a href="/scontrini" title="Dixan">Dixan</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" title="Bio Presto">Bio Presto</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" title="Vernel">Vernel</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" title="Pril">Pril</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" title="Perlana">Perlana</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" title="Bref">Bref</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="L\'Acchiappacolore">L\'Acchiappacolore</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Schwarzkopf">Schwarzkopf</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Brillance">Brillance</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Coloreria Italiana">Coloreria Italiana</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Natural&#38;Easy">Natural&#38;Easy</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Palette">Palette</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="Gliss">Gliss</a></p>',
                times: '',
            },
            {
                title:
                    '<p> <a href="/scontrini" class="bold underlined" title="got2b">got2b</a></p>',
                times: '',
            },
        ],
    },
    {
        title: '<h2>Le attività sul sito di MyDonnaD</h2>',
        items: [
            {
                title: "<p>Porta un nuovo utente all'interno della community DonnaD</p>",
                times: "",
                points: "300",
            },
            {
                title: '<p>Carica il tuo scontrino</p>',
                times: '',
                points: 300,
            },
            {
                title: '<p>Partecipa al programma "Dfamily"</p>',
                times: '',
                points: 150,
            },
            {
                title: '<p>Raccontaci di te e dei tuoi gusti</p>',
                times: '',
                points: 1000,
            },
            {
                title:
                    "<p>L'utente che hai portato su DonnaD partecipa all'iniziativa e carica uno scontrino con prodotti Henkel validi</p>",
                times: '',
                points: 200,
            },
            {
                title:
                    "<p>Installa la PWA</p>",
                times: '',
                points: 800,
            },
            {
                title:
                    "<p>A 6 mesi dall’adesione al programma fedeltà Dfamily, ricevi punti D</p>",
                times: '',
                points: 200,
            },
            {
                title:
                    "<p>Fai login su DonnaD e partecipa a iniziative e concorsi per ricevere ogni 15 giorni nuovi punti D</p>",
                times: '',
                points: 100,
            },
            {
                title:
                    "<p>Completa il tuo profilo per ricevere punti D entro 24 ore</p>",
                times: '',
                points: 500,
            },
            
        ],
    },
];
