import jwtHelper from '../utils/jwtHelper.js';

const appReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PAGE': {
            let newState = {
                ...state,
                current_page: {
                    type: action.payload.type,
                },
            };
            if (action.payload.type !== 'article') {
                newState = { ...newState, current_article: null };
            }
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;
        }
        case 'SET_CURRENT_ARTICLE': {
            const newState = { ...state, current_article: action.payload };
            return newState;
        }
        case 'UPDATE_CURRENT_ARTICLE_USER_DATA': {
            const newState = {
                ...state,
                current_article: { ...state.current_article, data: action.payload },
            };
            return newState;
        }
        case 'SET_LOADING': {
            const newState = { ...state, loading: true };
            return newState;
        }
        case 'UNSET_LOADING': {
            const newState = { ...state, loading: false };
            return newState;
        }
        case 'USER_LOGGEDIN': {
            const newState = { ...state, loading: false, user: action.payload };
            localStorage.setItem('state', JSON.stringify(newState));
            window.userStatus = 'logged';
            return newState;
        }
        case 'USER_LOGIN': {
            if (window.gigya) {
                const newState = { ...state, loading: true };
                window.gigya.accounts.showScreenSet({
                    screenSet: process.env.REACT_APP_GIGYA_SECREEN_SET,
                    startScreen: 'gigya-login-screen',
                    onHide: action.payload.onHide,
                });
                return newState;
            } else {
                const newState = { ...state, loading: false };
                return newState;
            }
        }
        case 'USER_LOGOUT': {
            const newState = { ...state, loading: true, user: null, loyalty_status: null };
            window.gigya && window.gigya.accounts.logout();
            window.userStatus = 'anonymous';
            return newState;
        }
        case 'OPEN_PRIZE_MODAL': {
            const newState = { ...state, currentPrize: action.payload };
            return newState;
        }
        case 'CLOSE_PRIZE_MODAL': {
            const newState = { ...state, currentPrize: null };
            return newState;
        }
        case 'OPEN_INTERESTS': {
            const newState = { ...state, interestsModal: true };
            return newState;
        }
        case 'OPEN_TUTORIAL': {
            const newState = { ...state, tutorial: true };
            return newState;
        }
        case 'CLOSE_INTERESTS': {
            const newState = { ...state, interestsModal: false, tutorial: false };
            return newState;
        }
        case 'OPEN_APPBANNER': {
            const newState = { ...state, pwaBannerModal: true };
            return newState;
        }
        case 'CLOSE_APPBANNER': {
            const newState = { ...state, pwaBannerModal: false };
            return newState;
        }
        case 'TOGGLE_RECEIPTS': {
            const newState = { ...state, receipts: !state.receipts, receipt_info: action.payload };
            return newState;
        }
        case 'TOGGLE_SOCIALSHARE': {
            const newState = { ...state, socialshare: !state.socialshare, url: action.payload.url };
            return newState;
        }
        case 'TOGGLE_MESSAGE_ALERT': {
            const newState = { ...state, messageAlert: !state.messageAlert, message: action.payload.message };
            return newState;
        }
        case 'USER_LOGGEDOUT': {
            const newState = { ...state, user: null, loading: false };
            jwtHelper.set(null);
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;
        }
        case 'LOYALTY_STATE': {
            const newState = {
                ...state,
                loyalty_status: action.payload.isActive,
                can_get_prizes: action.payload.canGetPrizes,
                end_prizes: action.payload.endPrizes,
            };
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;
        }
        case 'REFRESH_USER_DATA': {
            const newState = { ...state, user: action.payload, loading: false };
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;
        }
        case 'SET_ALERT_MODAL': {
            const newState = { ...state, alert: action.payload };
            return newState;
        }
        case 'UNSET_ALERT_MODAL': {
            const newState = { ...state, alert: null };
            return newState;
        }
        case 'TOGGLE_SETTINGS': {
            const newState = { ...state, settings: !state.settings };
            return newState;
        }
        case 'ACCOUNT_DELETED': {
            const newState = { ...state, alert: null, user: null, loading: false };
            return newState;
        }
        case 'UPDATE_USER_DASHBOARD': {
            const newState = { ...state, user: { ...state.user, updated: Date.now() } };
            localStorage.setItem('state', JSON.stringify(newState));
            return newState;
        }

        default:
            return state;
    }
};

export default appReducer;
