import React, { useContext } from 'react';
import ArticleTease from '../ArticleTease';
import style from './style.module.scss';
import AppContext from '../../contexts/appContext';

import { Edit } from '../Icons';

const ArticlelList = ({ title, items, isEditable }) => {
    const { state, dispatch } = useContext(AppContext);

    return (
        <div>
            {items && items.length > 0 ? (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <h2 className={style.title}>{title}</h2>
                        {isEditable && state.user && (
                            <button
                                aria-label="preferenze"
                                onClick={() => dispatch({ type: 'OPEN_INTERESTS' })}
                            >
                                <Edit />
                            </button>
                        )}
                    </div>
                    <div className={style.el}>
                        {items.map((item, j) => {
                            return (
                                <div key={j} className={style.article}>
                                    <ArticleTease {...item} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <span></span>
            )}
        </div>
    );
};

export default ArticlelList;
