import React from 'react';
import classNames from 'classnames';

import LaztImg from '../LazyImg';
import style from './style.module.scss';

import Cta from '../Cta';
import LinkTo from '../LinkTo';
import { Close } from '../Icons';

const PrizeModal = ({ image, content, title , visibility = false, code  ,onClick , onClose, type , shopping, disableClose}) => {
    const classes = classNames([style.prizemodal], {
        [style.active]: !!visibility,
    });

    return (
        <div className={classes}>
            <div
                className={
                    type === 'type_coupon_2' ||
                    type === 'type_coupon_3' ||
                    type === 'type_coupon_4' ||
                    type === 'type_coupon_5' ||
                    type === 'type_coupon_6'
                        ? style.mask + ' ' + style.mask2
                        : style.mask
                }
            >
                {!disableClose &&
                    <button className={style.close} onClick={onClose}>
                        <Close />
                    </button>
                }
                

                <div className={style.background}>
                    <LaztImg src="/imgs/receipts/bg.jpg" alt="background" />
                </div>
                <div
                    className={
                        type === 'type_coupon_2' ||
                        type === 'type_coupon_3' ||
                        type === 'type_coupon_4' ||
                        type === 'type_coupon_5' ||
                        type === 'type_coupon_6'
                            ? style.data + ' ' + style.data2
                            : style.data
                    }
                >
                    {image && <LaztImg src={image} alt="premio" />}
                    {title && <h3>{title}</h3>}

                    <div className={style.text} dangerouslySetInnerHTML={{ __html: content }}></div>

                    {!type ? (
                        <div className={style.actions}>
                            <Cta label="Richiedi Premio" onClick={onClick} />
                            <LinkTo label="Annulla" onClick={onClose} />
                        </div>
                    ) : (
                        <React.Fragment>
                            {type === 'type_coupon' ? (
                                <React.Fragment>
                                    <Cta
                                        label="Visualizza il coupon"
                                        url="/coupons"
                                        onClick={onClose}
                                    />
                                </React.Fragment>
                            ) : type === 'type_coupon_2' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="RICHIEDI IL TUO PREMIO"
                                        onClick={onClick}
                                    />
                                    <p style={{ color: '#fff', fontSize: '14px' }}>
                                        <b>ATTENZIONE</b>: per la generazione del codice
                                        potrebbero volerci alcuni minuti, se non lo trovi subito,
                                        ricontrolla più tardi.
                                    </p>
                                </React.Fragment>
                            ) : type === 'type_coupon_3' ? (
                                <React.Fragment>
                                    <Cta label="CHIUDI" onClick={onClose} />
                                </React.Fragment>
                            ) : type === 'type_coupon_4' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="Utilizza codice"
                                        onClick={onClick}
                                    />
                                    <div className={style.text}>
                                        <p
                                            style={{
                                                color: '#fff',
                                                fontSize: '15px',
                                                lineHeight: '18px',
                                                padding: '2rem 0rem',
                                            }}
                                        >
                                            Hai paura di perderlo?<br></br>Non ti preoccupare, lo
                                            puoi trovare anche nella tua area personale «I miei
                                            coupon» nella sezione «codici»
                                        </p>
                                    </div>
                                    <Cta
                                        modifiers={['buono']}
                                        label="VAI AI MIEI PREMI"
                                        url="/coupons"
                                        onClick={onClose}
                                    ></Cta>
                                </React.Fragment>
                            ) : type === 'type_coupon_5' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="Utilizza codice"
                                        onClick={onClick}
                                    />
                                   <p style={{
                                                color: '#fff',
                                                fontSize: '15px',
                                                lineHeight: '18px',
                                            }}
                                        >{shopping}</p>
                                    <div className={style.text}>
                                        <p
                                            style={{
                                                color: '#fff',
                                                fontSize: '15px',
                                                lineHeight: '18px',
                                                padding: '2rem 0rem',
                                            }}
                                        >
                                            Hai paura di perderlo?<br></br>Non ti preoccupare, lo
                                            puoi trovare anche nella tua area personale «I miei
                                            coupon» nella sezione «codici»
                                        </p>
                                    </div>
                                    <Cta
                                        modifiers={['buono']}
                                        label="VAI AI MIEI PREMI"
                                        url="/coupons"
                                        onClick={onClose}
                                    ></Cta>
                                </React.Fragment>
                            ) : type === 'type_coupon_6' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="RICHIEDI IL TUO PREMIO"
                                        onClick={onClick}
                                    ></Cta>
                                    <p style={{ color: '#fff', fontSize: '14px' }}>
                                        <b>ATTENZIONE</b>: per la generazione del buono sconto
                                        potrebbero volerci alcuni minuti, se non lo trovi subito,
                                        ricontrolla più tardi.
                                    </p>
                                </React.Fragment>
                            ): type === 'type_coupon_7' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="VAI AL FORM"
                                        onClick={onClick}
                                    ></Cta>
                                    <div className={style.text}>
                                        <p
                                            style={{
                                                color: '#fff',
                                                fontSize: '15px',
                                                lineHeight: '18px',
                                                padding: '2rem 0rem',
                                            }}
                                        >
                                            *attenzione: la richiesta verrà accettata dopo aver compilato il form.
                                        </p>
                                    </div>
                                </React.Fragment>
                            ): type === 'type_coupon_8' ? (
                                <React.Fragment>
                                    <Cta
                                        modifiers={['buono']}
                                        label="CHIUDI"
                                        url="/"
                                        onClick={onClose}
                                    ></Cta>
                                </React.Fragment>
                            ): type === 'type_code' ? (
                                <React.Fragment>
                                    <p className={style.text}>{code}</p>
                                    <Cta label="Chiudi" onClick={onClose} />
                                </React.Fragment>
                            ) : (
                                <Cta label="Chiudi" onClick={onClose} />
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PrizeModal;
