import React from 'react';
import style from './style.module.scss';
import QuickAccessNav from '../QuickAccessNav';
import {useLocation} from "react-router-dom";

const StickyNav = ({ onUploadTicketClick, loyalty, loyalty_prizes}) => {

    const location = useLocation();

    return location.pathname === '/' && (
        <div className={style.rs22 + ' ' + style.el__HeaderNav}>
            <QuickAccessNav
                type="inline"
                onUploadTicketClick={onUploadTicketClick}
                loyalty={loyalty}
                loyalty_prizes={loyalty_prizes}
            />
        </div>
    );
};

export default StickyNav;
