import React from 'react';
import style from './style.module.scss';

import Logo from '../Logo'
import Cta from '../Cta'

const ErrorBlock = () => {
    return (
        <div className={style.el}>
            <div className={style.head}>
                <Logo />
                <p className={style.title}>Ops, qualcosa è andato storto...</p>
            </div>
            <div className={style.actions}>
                <Cta label="torna alla home" url='/' />
            </div>

        </div>
    );
}

export default ErrorBlock;
