import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
// import articlesHelper from '../../utils/articlesHelper';

import style from './style.module.scss';

import AppContext from '../../contexts/appContext';
import LazyImg from '../../components/LazyImg';
import LoginWall from '../../components/LoginWall';
import ArticleGallery from '../../components/ArticleGallery';
import Footer from '../../components/Footer';
import ArticleReactions from '../../components/ArticleReactions';
import ArticleList from '../../components/ArticleList';
import ArticlePagePreview from '../../components/ArticlePagePreview';
import ErrorBlock from '../../components/ErrorBlock';
import BrandCards from '../../components/BrandCards';

import {
    Articles as ArticlesAPI,
    UserArticles as UserArticlesAPI,
    CustomToken as CustomTokenAPI,
    UserActions as UserActionsAPI,
} from '../../Api.js';

const Article = () => {
    let { id } = useParams();
    const { state, dispatch } = useContext(AppContext);
    const [item, setItem] = useState(null);
    const [error, setError] = useState(false);
    const [latest, setLatest] = useState(null);
    const [readArticles, setReadArticles] = useState([]);
    const [loadingReactions, setLoadingReactions] = useState(false);
    const [articleUserData, setArticleUserData] = useState({});
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' }, undefined, () => {
        window._neoHenkel && window._neoHenkel.B.refresh();
    });

    const onCtaLoginClick = () => {
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                onHide: () => {
                    dispatch({ type: 'UNSET_LOADING', payload: null });
                },
            },
        });
    };

    const textClear = (text) => {
        return text.replace("&#039;", "'");
    };

    const onReactionClick = (type) => {
        setLoadingReactions(true);
        UserArticlesAPI.post('', {
            drupal_nid: id,
            reaction_type: type,
        })
            .then(({ data }) => {
                setLoadingReactions(false);
                setArticleUserData(data);
                dispatch({
                    type: 'UPDATE_CURRENT_ARTICLE_USER_DATA',
                    payload: data.data || null,
                });
            })
            .catch(({ response }) => {
                if (response.data.error.code === 204) {
                    //User alraedy interacted with post
                    UserArticlesAPI.patch(`/${state.current_article.data.id}`, {
                        drupal_nid: state.current_article.nid,
                        reaction_type: type,
                    }).then(({ data }) => {
                        setArticleUserData(data);
                        dispatch({
                            type: 'UPDATE_CURRENT_ARTICLE_USER_DATA',
                            payload: data.data || null,
                        });
                        setLoadingReactions(false);
                    });
                }
            })
            .then(() => {
                if (typeof window.dataLayer != 'undefined') {
                    window.dataLayer.push({
                        event: 'PWA',
                        category: 'PWA',
                        action: 'Reaction given on article',
                        label: `${type} - ${state.current_article.slug}`,
                    });
                }
            });
    };

    useEffect(() => {
        let timeout = null;

        //RESET DATA ON ID CHANGE
        setItem(null);
        dispatch({ type: 'SET_CURRENT_ARTICLE', payload: null });
        dispatch({ type: 'SET_PAGE', payload: { type: 'article' } });

        //GET ARTICLE DATA
        ArticlesAPI.get(`/node-${id}.json`)
            .then(({ data }) => {
                setTimeout(function () {

                    setItem(data.data);
                    dispatch({
                        type: 'SET_CURRENT_ARTICLE',
                        payload: data.data,
                    });
                    window.instgrm.Embeds.process();

                    if (state.user) {
                        UserArticlesAPI.get('', {
                            params: {
                                'filter[drupal_nid]': id,
                            },
                        }).then(({ data }) => {
                            setArticleUserData(data);
                            dispatch({
                                type: 'UPDATE_CURRENT_ARTICLE_USER_DATA',
                                payload: data.data[0] || null,
                            });
                        });

                        if (data.data.suggested) {
                            UserActionsAPI.get('', {
                                params: {
                                    'filter[action_id]': 'article-read',
                                    'filter[idata][in]': data.data.suggested.map((a) => a.nid),
                                },
                            }).then(({ data }) => {
                                setReadArticles((a) => [
                                    ...a,
                                    ...data.data.map((a) => a.metadata.drupal_nid),
                                ]);
                            });
                        }
                    }

                    //START ADV
                    setTimeout(() => {
                        if(isDesktop){
                            window._neoHenkel.B.setLocationBase(data.data.adv_pfx)
                            .setFormat('fmt_personalarea_article_henkel')
                            .addPosition('970x250', 'DotnAd_mh_u')
                            .addPosition('300x600_right1', 'DotnAd_box_right1')
                            .addPosition('300x250_right2', 'DotnAd_box_right2')
                            .addPosition('300x250_right3', 'DotnAd_box_right3')
                            .addPosition('inr_u', 'DotnAd_inr_u')
                            .addPosition('ovl_u', 'DotnAd_ovl_u')
                            .refresh();
                        }
                        else{
                            window._neoHenkel.B.setLocationBase(data.data.adv_pfx)
                            .setFormat('fmt_personalarea_article_henkel')
                            .addPosition('970x250', 'DotnAd_mh_u')
                            .addPosition('300x250_right2', 'DotnAd_box_right2')
                            .addPosition('inr_u', 'DotnAd_inr_u')
                            .addPosition('ovl_u', 'DotnAd_ovl_u')
                            .refresh();
                        }
                    }, 1500);
                }, 300);
            })
            .catch((error) => {
                setError(true);
            });

        if (!!state.user) {
            CustomTokenAPI.post('', {
                data: id,
            }).then(({ data }) => {
                timeout = setTimeout(() => {
                    CustomTokenAPI.post('/validate', {
                        data: id,
                        token: data[0].token,
                    }).then(({ data }) => {
                        if (typeof window.dataLayer != 'undefined' && !!state.current_article) {
                            window.dataLayer.push({
                                event: 'PWA',
                                category: 'PWA',
                                action: 'Article read',
                                label: `${state.current_article.slug}`,
                            });
                        }
                    });
                }, 31000);
            });

            return () => {
                //clear timeout on unmount
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }
    }, [id, dispatch, state.user]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //GET ARTICLE DATA
        ArticlesAPI.get(`/latest.json`).then(({ data }) => {
            setLatest(data.data);

            if (!!state.user) {
                UserActionsAPI.get('', {
                    params: {
                        'filter[action_id]': 'article-read',
                        'filter[idata][in]': data.data.map((a) => a.nid),
                    },
                }).then(({ data }) => {
                    setReadArticles((a) => [...a, ...data.data.map((a) => a.metadata.drupal_nid)]);
                });
            }
        });
    }, [state.user]);


    return (
        <section>
            <Helmet>{!!item && <title>{item.title} | DonnaD</title>}</Helmet>
            {!!item && (
                <div>
                    <div className={style.masterAdv}>
                        <div id="DotnAd_mh_u"></div>
                    </div>
                    <div className={style.cols}>
                        <div className={style.main}>
                            <article className={style.el} id="page">
                                <div className={style.hero}>
                                    <div className={style.intro}>
                                        {item.category && item.category.title && (
                                            <strong className={style.caption}>
                                                {item.category.title}
                                            </strong>
                                        )}
                                        <h1
                                            className={style.title}
                                            dangerouslySetInnerHTML={{
                                                __html: item.title,
                                            }}
                                        />
                                        {item.estimated_read_time && <span className={style.time}>{`LEGGI IN ${item.estimated_read_time}'`}</span>}
                                    </div>
                                    <div className={style.cover}>
                                        <LazyImg src={item.image} alt={item.title} />
                                    </div>
                                </div>
                                <div
                                    className={style.body}
                                    dangerouslySetInnerHTML={{
                                        __html: item.text,
                                    }}
                                />
                                {item.gallery && item.gallery.length > 0 && (
                                    <ArticleGallery items={item.gallery} />
                                )}
                                {!isDesktop && (
                                    <div className={style.masterAdv}>
                                        <div id="DotnAd_box_right2"></div>
                                    </div>
                                )}
                                <div className={style.loginWallWrapper}>
                                    <ArticleReactions
                                        data={articleUserData}
                                        loading={loadingReactions || !state.user}
                                        onReactionClick={onReactionClick}
                                    />
                                    {!state.user && (
                                        <div className={style.reactionWall}>
                                            <LoginWall onCtaClick={onCtaLoginClick} />
                                        </div>
                                    )}
                                </div>
                            </article>

                            <div
                                className={classNames(style.loginWallWrapper, {
                                    [style.hiddenarea]: !state.user,
                                })}
                            >
                                {latest && (
                                    <div className={style.related}>
                                        <ArticleList
                                            title="Gli ultimi articoli"
                                            items={latest
                                                .filter((f) => f.nid !== id)
                                                .map((f) => {
                                                    return {
                                                        id: f.nid,
                                                        slug: f.slug,
                                                        read: readArticles.indexOf(f.nid) !== -1,
                                                        thumbnail: f.image,
                                                        caption: f.category.title,
                                                        title: f.title,
                                                        labels: f.labels,
                                                        time: f.estimated_read_time,
                                                    };
                                                })}
                                        />
                                    </div>
                                )}
                                {!state.user && latest && (
                                    <LoginWall onCtaClick={onCtaLoginClick} />
                                )}
                            </div>

                            <div
                                className={classNames(style.loginWallWrapperRelated, {
                                    [style.hiddenarea]: !state.user,
                                })}
                            >
                                {item.suggested && (
                                    <div className={style.related}>
                                        <ArticleList
                                            title="Articoli Correlati"
                                            items={item.suggested.map((f) => {
                                                return {
                                                    id: f.nid,
                                                    slug: f.slug,
                                                    read: readArticles.indexOf(f.nid) !== -1,
                                                    thumbnail: f.image,
                                                    caption: textClear(f.category.title),
                                                    title: f.title,
                                                    labels: f.labels,
                                                    time: f.estimated_read_time,
                                                };
                                            })}
                                        />
                                    </div>
                                )}
                                {!state.user && item.suggested && (
                                    <LoginWall onCtaClick={onCtaLoginClick} />
                                )}
                            </div>

                            <BrandCards />
                        </div>
                        {isDesktop && (
                            <div className={style.sidebar}>
                                <div id="DotnAd_box_right1"></div>
                                <div className={style.banners}>
                                    <div id="DotnAd_box_right2"></div>
                                    <div id="DotnAd_box_right3"></div>
                                </div>
                            </div>
                        )}
                        {!isDesktop && (
                            <div className={style.sidebar} style={{display: 'none'}}>
                                <div id="DotnAd_box_right1"></div>
                                <div className={style.banners}>
                                    <div id="DotnAd_box_right3"></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!item && !error && <ArticlePagePreview />}
            {!!error && <ErrorBlock />}
            <Footer />
        </section>
    );
};

export default Article;
