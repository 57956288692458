import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import style from './style.module.scss';

import { isLoyaltyActive } from '../../utils';
import Avatar from '../Avatar';
import LoyaltyNavigationCard from '../LoyaltyNavigationCard';
import AppContext from '../../contexts/appContext';
import { Close, Logout } from '../Icons';

const Menu = ({ links, service, user, onCloseClick }) => {
    const { state, dispatch } = useContext(AppContext);

    const onLogout = (e) => {
        e.preventDefault();
        dispatch({ type: 'USER_LOGOUT', payload: null });
    };

    const getLoyaltyMenuItems = () => {
        const menuItems = [
           /*
            {
                label: 'le mie attività',
                url: '/attivita',
                modifier: 'negative',
                onClick: () => onCloseClick(),
            },*/
            {
                label: 'come guadagnare punti',
                url: '/come-guadagnare-punti',
                modifier: 'negative',
                onClick: () => onCloseClick(),
            },
            {
                label: 'Richiedi premi',
                url: '/premi',
                modifier: 'negative',
                onClick: () => onCloseClick(),
            }
        ];

        return menuItems;
    };

    return (
        <div >
            
            <div className={style.el}>
                <div className={style.backdrop} onClick={onCloseClick}></div>
                <div className={style.wrapper}>
                <div className={style.header}>
                    Account
                    <span className={style.icon} onClick={onCloseClick}>
                        <Close />
                    </span>
                </div>
                <div className={style.content}>
                    {user && (
                        <div className={style.user}>
                            <div className={style.avatar}>
                                <Avatar
                                    type="avatar-menu"
                                    image={user.thumbnailURL || user.photoURL}
                                    data={user.data}
                                />
                            </div>
                            <div className={style.usercontent}>
                                <div
                                    className={style.username}
                                >{`${user.firstName} ${user.lastName || ''}`}</div>
                            </div>
                        </div>
                    )}
                    <ul className={style.links}>
                        {links.map((l, k) => {
                            if (l.target === 'app') {
                                return (
                                    <li key={k}>
                                        <Link onClick={onCloseClick} to={l.href}>
                                            {l.label}
                                        </Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={k}>
                                        <a href={l.href}>{l.label}</a>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                    <ul className={style.links}>
                        {service.map((l, k) => {
                            if (l.target === 'app') {
                                return (
                                    <li key={k}>
                                        <Link onClick={onCloseClick} to={l.href}>
                                            {l.label}
                                        </Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={k}>
                                        <a href={l.href}>{l.label}</a>
                                    </li>
                                );
                            }
                        })}
                    </ul>

                    {user && isLoyaltyActive(state) && (
                        <LoyaltyNavigationCard items={getLoyaltyMenuItems()} />
                    )}

                    <a
                        className={style.banner}
                        href="https://www.donnad.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src="/imgs/donnad_logo_black.svg" alt="donnad" />
                        <span>vai al sito principale</span>
                    </a>

                    <div className={style.logout}>
                        <span onClick={onLogout}>
                            Logout
                            <Logout />
                        </span>
                    </div>
                </div>
                </div>
            </div>






            <div className={style.mobileMenu}>
                <div className={style.backdrop} onClick={onCloseClick}></div>
                <div className={style.wrapper}>
                    <div className={style.header}>
                        Account
                        <span className={style.icon} onClick={onCloseClick}>
                            <Close />
                        </span>
                    </div>
                    <div className={style.content}>
                        {user && (
                            <div className={style.user}>
                                <div className={style.avatar}>
                                    <Avatar
                                        type="avatar-menu"
                                        image={user.thumbnailURL || user.photoURL}
                                        data={user.data}
                                    />
                                </div>
                                <div className={style.usercontent}>
                                    <div
                                        className={style.username}
                                    >{`${user.firstName} ${user.lastName || ''}`}</div>
                                </div>
                            </div>
                        )}
                        <ul className={style.links}>
                            {links.map((l, k) => {
                                if (l.target === 'app') {
                                    return (
                                        <li key={k}>
                                            <Link onClick={onCloseClick} to={l.href}>
                                                {l.label}
                                            </Link>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={k}>
                                            <a href={l.href}>{l.label}</a>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                        <ul className={style.links}>
                            {service.map((l, k) => {
                                if (l.target === 'app') {
                                    return (
                                        <li key={k}>
                                            <Link onClick={onCloseClick} to={l.href}>
                                                {l.label}
                                            </Link>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={k}>
                                            <a href={l.href}>{l.label}</a>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                        <div className={style.logout}>
                            <span onClick={onLogout}>
                                Logout
                                <Logout />
                            </span>
                        </div>
                        {user && isLoyaltyActive(state) && (
                            <LoyaltyNavigationCard items={getLoyaltyMenuItems()} />
                        )}

                        <a
                            className={style.banner}
                            href="https://www.donnad.it/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/imgs/donnad_logo_black.svg" alt="donnad" />
                            <span>vai al sito principale</span>
                        </a>

                        
                    </div>
                </div>
            </div>
        </div>
        






        
    );
};

export default Menu;
