import React, { useState } from 'react';
import style from './style.module.scss';

import Accordion from '../Accordion';

const AccordionList = ({items}) => {

    const [accordionActive, setAccordionActive] = useState(false);

    return (
        <div className={style.wrapper}>
            {items.map((item,j) =>
                <Accordion
                    key={j}
                    onClick={() => setAccordionActive(accordionActive !== j ? j : false)} isActive={accordionActive === j}
                    {...item}
                /> )}
        </div>
    );
}

export default AccordionList;
