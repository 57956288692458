import React, { useState } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import { Locker, Check } from '../Icons';

import Cta from '../Cta';

const EventCard = ({
    date,
    title,
    text,
    description,
    images,
    cta,
    modifiers,
    textColor,
    bgColor,
    id,
    canRedeem = false,
    redeemPoints = false,
    ctaResult,
}) => {
    const [cardOnRead, setCardOnRead] = useState(false);

    let styleCard = [style.card];

    if (modifiers) {
        styleCard.push(modifiers.map((modifier) => style[modifier]));
    }

    if (bgColor) {
        bgColor[1]
            ? (bgColor = `linear-gradient(180deg, rgb(${bgColor[0]}) 0%, rgb(${bgColor[1]}) 100%`)
            : (bgColor = `rgb(${bgColor[0]})`);
    }

    const classes = classNames(styleCard, {
        [style.active]: true,
    });

    return (
        <div style={{ background: bgColor }} className={classes}>
            {images && (
                <picture className={style.image}>
                    {images[1] && (
                        <source media="(min-width: 601px)" srcSet={images[1].data.full_url} />
                    )}
                    <img src={images[0]} alt="backgroundImage" />
                </picture>
            )}
            <div style={{ color: textColor }} className={style.data}>
                {date && (
                    <p className={style.date}>
                        {new Date(date).toLocaleString('it-IT', {
                            month: 'long',
                            day: '2-digit',
                            year: 'numeric',
                        })}
                    </p>
                )}
                {title && (
                    <h3 className={style.title} dangerouslySetInnerHTML={{ __html: title }}></h3>
                )}
                {text && <p className={style.text} dangerouslySetInnerHTML={{ __html: text }}></p>}

                <div
                    className={style.content}
                    dangerouslySetInnerHTML={{ __html: description }}
                ></div>
            </div>
            {!cardOnRead ? (
                <React.Fragment>
                    {canRedeem ? (
                        <Cta
                            label={cta.label}
                            onClick={(e) => {
                                setCardOnRead(true);
                                redeemPoints && redeemPoints(id);
                            }}
                            modifiers={cta.modifiers}
                        />
                    ) : (
                        <div className={style.ct}>
                            <Cta
                                label="Scopri come ottenere punti"
                                modifiers={['primary-negative', 'iconized']}
                                icon={<Locker />}
                                url="/faq"
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={style.ct}>
                    {ctaResult && (
                        <Cta
                            label={ctaResult.text}
                            modifiers={['primary-negative', 'iconized']}
                            icon={ctaResult.type === 'lock' ? <Locker /> : <Check />}
                            url={ctaResult.url}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default EventCard;
