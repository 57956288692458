import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/appContext';
import style from './style.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import HeadingPage from '../../components/HeadingPage';
import AccordionList from '../../components/AccordionList';
import Footer from '../../components/Footer';
import Cta from '../../components/Cta';
import LinkTo from '../../components/LinkTo';
// import LoyaltyCard from '../../components/LoyaltyCard';

import { Sections } from './data';

const Faq = () => {
    const { dispatch } = useContext(AppContext);
    const [currentSection, setCurrentSection] = useState(0);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'faq' } });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Faq | DonnaD</title>
            </Helmet>
            <div className="content">
                <HeadingPage
                    title="<h1>Hai bisogno di aiuto?</h1>"
                    text='Qui trovi le risposte alle domande che la nostra community ci rivolge più di frequente sul programma fedeltà <b>"Dfamily"</b>'
                />
            </div>

            <Swiper
                className="faq-navigation is-hidden-tablet-from"
                centeredSlides={true}
                spaceBetween={20}
                slidesPerView="auto"
                onSlideChange={(swiper) => setCurrentSection(swiper.activeIndex)}
            >
                {Sections &&
                    Sections.map(({ title }, i) => (
                        <SwiperSlide key={i}>
                            <button>{title}</button>
                        </SwiperSlide>
                    ))}
            </Swiper>

            <div className="content">
                {Sections &&
                    Sections.map(({ title, items }, i) => (
                        <div
                            className={classNames([style.list], {
                                [style.active]: currentSection === i,
                            })}
                            key={i}
                        >
                            <p className={style.title}>{title}</p>
                            <AccordionList items={items} />
                        </div>
                    ))}

                <div className={style.contact}>
                    <p>
                        Non hai trovato
                        <br />
                        la risposta che cercavi?
                        <br />
                        Vediamo cosa possiamo fare per te!
                    </p>
                    {/* <Cta label="Contattaci" url="https://www.donnad.it/contatti" isBlank={true} /> */}
                    <LinkTo {...{label: 'Contattaci',
                                arrow: false,
                                url: 'https://www.donnad.it/contatti',
                                modifier: "big_secondary",}} />
                </div>
                <div className={style.contact}>
                    <span>
                        Operazione a premi valida dal 04/03/2024 al 13/12/2024. Montepremi 265.815€ IVA inclusa non scorporabile.
                    </span>
                    <LinkTo {...{label: 'SCARICA QUI IL REGOLAMENTO',
                                inApp: false,
                                arrow: false,
                                url: 'https://concorsi.donnad.it/brand/donnad/loyalty-2024/regolamento.pdf',
                                modifier: "big_secondary",}} />
                </div>

                {/* <div className={style.banner}>
                    <LoyaltyCard
                        title={['Torna anche quest’anno il programma di DonnaD che premia la tua fedeltà!']}
                        cta={{
                            label: 'partecipa',
                            url : '.',
                            modifiers : [
                                'loyalty'
                            ]
                        }}
                    />
                </div> */}
            </div>

            <Footer />
        </div>
    );
};

export default Faq;
