import React, { useEffect, useState, useContext } from 'react';
import dateFormat from 'dateformat';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import AppContext from '../../contexts/appContext';
import { Tickets as TicketsAPI, TicketDetail as TicketDetailAPI } from '../../Api.js';
import { isLoyaltyActive } from '../../utils';

import ArticleListPreview from '../../components/ArticleListPreview';
import ScontriniList from '../../components/ScontriniList';
import Cta from '../../components/Cta';
import LazyImg from '../../components/LazyImg';
import Footer from '../../components/Footer';
import style from './style.module.scss';
import LinkTo from '../../components/LinkTo';

const ReceiptsList = () => {
    
    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { state, dispatch } = useContext(AppContext);

    const takeTicketDetail = async (ticketID) => {
        try {
            const a = await TicketDetailAPI.get(`/${ticketID}_response.json`);
            return a.data.data;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'receipts' } });

        if (window.DDNewsletterAddon) {
            window.DDNewsletterAddon.open();
        }

        if (state.user || state.receipts === false) {
            if (state.receipts) {
                return;
            }
            TicketsAPI.get('/tickets', {
                /* params: {
                    sort: '-created_on',
                }, */
            }).then(({ data }) => {
                console.log(data);
                data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
                const resTickets = () =>
                    Promise.all(
                        data.map(async (d) => ({
                            status: d.status,
                            date: dateFormat(d.created_on, 'dd/mm/yy'),
                            id: d.operation_id,
                            points: d.points,
                            image: d.file_url,
                            change_counter: d.change_counter,
                            receipt_number: d.receipt_data && d.receipt_data.number ? d.receipt_data.number : null,
                            receipt_amount: d.receipt_data && d.receipt_data.amount ? d.receipt_data.amount : null,
                            receipt_date: d.receipt_data && d.receipt_data.date ? d.receipt_data.date : null,
                            upload_id: d.upload_id,
                            //details: await takeTicketDetail(d.operation_id),
                        }))
                    );

                resTickets().then((res) => {
                    setReceipts(res);
                    setLoading(false);
                });
            });
        }
    }, [state.receipts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.el}>
            <div className={style.wrapper}>
                <Helmet>
                    <title>Scontrini | DonnaD</title>
                </Helmet>
                <div className={style.header}>
                    <div>
                    <h2 className={style.title}>Archivio scontrini</h2>
                        <p className={style.text}>
                            {isLoyaltyActive(state) && state.loyalty_status === true ? (
                                <React.Fragment>
                                    Carica i tuoi scontrini e <b>guadagna 600 punti D</b>. Vuoi sapere che caratteristiche devono avere i tuoi scontrini per essere accettati? Naviga le nostre{' '}
                                    <Link to="/faq">FAQ</Link>.
                                    {/* <br />
                                    Ti ricordiamo che per ogni prodotto Henkel all’interno dello
                                    scontrino <b>conquisti 300 punti</b>.
                                    <br />
                                    <br />
                                    Vuoi sapere che caratteristiche devono avere i tuoi{' '}
                                    <b>scontrini per essere accettati</b>? Naviga le nostre{' '}
                                    <Link to="/faq">FAQ</Link>. */}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    Ti ricordiamo che per{' '}
                                    <Link to="/faq">diventare Club Member</Link> devi caricare
                                    almeno 2 scontrini validi al mese. Vuoi sapere che
                                    caratteristiche devono avere i tuoi scontrini per essere
                                    accettati? Naviga le nostre <Link to="/faq">FAQ</Link>.
                                </React.Fragment>
                            )}
                        </p>
                        <div className={style.logos}>
                            <LazyImg src="/imgs/receipts/henkel-brands.png" alt="Logos" />
                        </div>
                        {isLoyaltyActive(state) && (
                            <Cta
                                label={
                                    receipts.length
                                        ? 'Carica un nuovo scontrino'
                                        : 'Carica il tuo primo scontrino'
                                }
                                onClick={() => dispatch({ type: 'TOGGLE_RECEIPTS' })}
                            />
                        )}

                    </div>
                </div>

                {!loading ? (
                    receipts.length > 0 ? (
                        <ScontriniList items={receipts} />
                    ) : (
                        <div className={style.nores}>
                            <p>Non hai ancora caricato scontrini.</p>
                            <LinkTo
                                url={isLoyaltyActive(state) ? '/come-guadagnare-punti' : '/faq'}
                                label="Scopri perchè è importante"
                                arrow={true}
                                modifier="secondary"
                            />
                        </div>
                    )
                ) : (
                    <ArticleListPreview />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ReceiptsList;