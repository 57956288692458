import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

import { ArrowRight } from '../Icons';

const LinkTo = ({ label, url, arrow = false, modifier, onClick, inApp = true}) => {
    const classes = classNames({
        [style.linkto]: true,
        [style[modifier]]: !!modifier,
    });

    return url ? (
        inApp ? (
            <Link onClick={onClick} className={classes} to={url}>
                <span>{label}</span>
                {/* {arrow && (
                    <span className={style.icon}>
                        <ArrowRight />
                    </span>
                )} */}
            </Link>
        ) : (
            <a className={classes} href={url}>
                <span>{label}</span>
                {/*arrow && (
                    <span className={style.icon}>
                        <ArrowRight />
                    </span>
                )*/}
            </a>
        )
    ) : (
        <button className={classes} onClick={onClick}>
            <span>{label}</span>
            {arrow && (
                <span className={style.icon}>
                    <ArrowRight />
                </span>
            )}
        </button>
    );
};

export default LinkTo;
