import React, { useEffect, useContext, useState } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import Cta from '../Cta';
import LinkTo from '../LinkTo';

const LoyaltySwitch = (props) => {

    const [selected, setSelected] = useState(1);

    const sendDataToParent = (selected) => {
        // Chiama la funzione passata dal genitore e passa i dati come argomento
        setSelected(selected);
        props.loyatySwitch(selected);
        window.location.href = window.location.href.split('#')[0] + '#loyalty-section';
    };

    return (
        <div className={style.loyaltyswitch_container}>
            <div className={style.loyaltyswitch} id="loyalty-switch">
                <button onClick={() => sendDataToParent(1)} className={selected==1?style.selected:''}>GUADAGNA PUNTI D</button>
                <button onClick={() => sendDataToParent(2)} className={selected==2?style.selected:''}>AZIONI COMPLETATE</button>
                <button onClick={() => sendDataToParent(3)} className={selected==3?style.selected:''}>RICHIEDI I PREMI</button>
            </div>
        </div>
    );
};

export default LoyaltySwitch;
