import React from 'react';

// import {Facebook, Instagram, Telephone, Email} from '../Icons';
// import Logo from '../Logo';

import style from './style.module.scss';

const Footer = () => {
    return (
        <footer className={style.rs22 + ' ' + style.footer}>
            <div className={style.footer__header}>
                <div className={style.col__footer}>
                    <div className={style.col__footer__top_wrapper}>
                        <a href="/" className={style.footer__logo} title="Donnad - amica fidata">
                        </a>
                        <div className={style.footer__social}>
                            <div className={style.main_menu__social__in}>
                                <span>SEGUI DONNAD SU</span>
                                <ul>
                                    <li className={style.social_item}>
                                        <a className={style.icon + ' ' + style.facebook} title="Facebook"
                                           href="https://www.facebook.com/DonnaD.it">
                                        </a>
                                    </li>

                                    <li className={style.social_item}>
                                        <a className={style.icon + ' ' + style.instagram} title="Instagram"
                                           href="https://www.instagram.com/donnad/">
                                        </a>
                                    </li>

                                    <li className={style.social_item}>
                                        <a className={style.icon + ' ' + style.pinterest} title="Pinterest"
                                           href="https://www.pinterest.it/donnad_it/">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={style.footer__contact}>
                            <a href="/contatti" className={style.footer__cta + ' ' + style.footer__cta__email} title="Contattaci">
                                <span>scrivici</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.custom_row + ' ' + style.section__footer + ' ' + style.footer__bottom}>
                <div className={style.col + ' ' + style.col__footer + ' ' + style.bg__corporate}>
                    <ul className={style.footer__navigation}>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/contatti`} title="CONTATTI">CONTATTI</a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/privacy`} title="PRIVACY">PRIVACY</a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/cookie-policy`} title="COOKIE POLICY">COOKIE POLICY</a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/termini-di-utilizzo`} title="TERMINI DI UTILIZZO">TERMINI DI UTILIZZO</a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/imprint`} title="IMPRINT">IMPRINT</a>
                        </li>
                        <li>
                            <a href={`${process.env.REACT_APP_DONNAD_DOMAIN}/investi-su-donnad`} title="INVESTI SU DONNAD">INVESTI SU DONNAD</a>
                        </li>
                    </ul>
                    <div className={style.footer__copyright}>
                        <span>&copy;DonnaD 2024 Henkel Italia S.r.l. | P. IVA 09299750969</span>
                        <span>Tutti i diritti riservati</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
