/* eslint-disable */

export const isClubMember = data => {
    if(data) {
        return data.membership && data.membership.status === 'club'
    } else {
        return false;
    }
}

export const isLoyaltyActive = (state,lite = false) => {
    var loyalty_version = `${process.env.REACT_APP_LOYALTY_VERSION}`;
    if(state.user &&
        state.user.data &&
        state.user.data.loyalty &&
        state.user.data.loyalty[loyalty_version] &&
        !!state.user.data.loyalty[loyalty_version].acceptanceDate
    ) {
        if(!!lite) {
            return state.can_get_prizes === true
        } else {
            return state.loyalty_status === true
        }
    } else {
        return false;
    }
};

export const getMonday = d => {
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export const getSunday = d => {
    const day = d.getDay();
    const diff = d.getDate() + (7 - day) - (day === 0 ? -7 : 0); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isOnLine = () => !!navigator.onLine;

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


export const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

export const isTouch = (() =>  (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)))();

export const getOffsetTop = elem => {
    var offsetTop = 0
    do {
        if (!isNaN(elem.offsetTop)) {
            offsetTop += elem.offsetTop
        }
    } while (elem = elem.offsetParent)
    return offsetTop
}

export const randomID = () => 'session_' + Math.random().toString(36).substr(2, 9);
