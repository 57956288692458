import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ClipboardJS from 'clipboard';
import { useMediaQuery } from 'react-responsive';

import Cta from '../Cta';
import LoyaltyTitle from '../LoyaltyTitle';
import { Loyalty as LoyaltyAPI } from '../../Api';
import { getOffsetTop } from '../../utils';
import LazyImg from '../LazyImg';

import AppContext from '../../contexts/appContext';

import style from './style.module.scss';

const LoyaltyCard = ({ logged, type, url, scrollTo }) => {
    const { dispatch } = useContext(AppContext);
    const banner = useRef(null);
    const [copied, setCopied] = useState(false);
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' }, undefined, () => {
        window._neoHenkel && window._neoHenkel.B.refresh();
    });

    const btn = useRef(null);

    const onClick = () => {
        if (logged) {
            dispatch({ type: 'SET_LOADING' });
            LoyaltyAPI.post('/subscribe').then(({ data }) => {
                if (window.gigya) {
                    window.gigya.accounts.getAccountInfo({
                        callback: (response) => {
                            if (response.status === 'OK') {
                                setTimeout(() => {
                                    dispatch({
                                        type: 'REFRESH_USER_DATA',
                                        payload: {
                                            data: response.data,
                                            ...response.profile,
                                        },
                                    });
                                }, 2500);
                            } else {
                                console.log('An error occurred');
                            }
                        },
                    });
                }
            });
        } else {
            dispatch({
                type: 'USER_LOGIN',
                payload: {
                    onHide: () => {
                        dispatch({ type: 'UNSET_LOADING', payload: null });
                    },
                },
            });
        }
    };

    const onClickSocialShare = () => {
        dispatch({ type: 'TOGGLE_SOCIALSHARE', payload: { url: url } });
    };

    useEffect(() => {
        /* let CP;
        if (btn.current) {
            CP = new ClipboardJS(btn.current, {
                target: (trigger) => trigger.querySelector('input'),
            });
            CP.on('success', (e) => setCopied(true));
            return () => {
                CP.destroy();
            };
        } */

        if (!!scrollTo) {
            setTimeout(() => {
                window.scrollTo(0, getOffsetTop(banner.current) - 100);
            }, 2000);
        }
    }, [scrollTo]);

    const cardClasses = classNames({
        [style.card]: true,
        [style.friends]: type === 'friends',
        [style.bgred]: type !== 'friends',
    });

    const desktopClasses = classNames({
        [style.background2]: true,
        [style.desktopImage]: true,
    });
    const mobileClasses = classNames({
        [style.background2]: true,
        [style.mobileImage]: true,
    });

    const classes = classNames({
        [style.el]: true,
        [style.friends]: type === 'friends',
    });

    return (
        <div className={classes} ref={banner}>
            <div className={cardClasses}>
                <div className={style.anchor} id="friends"></div>
                <div className={style.image}>
                    <div className={desktopClasses}>
                        {type === 'friends' ? (
                            <LazyImg src="/imgs/texture/invita-gli-amici.png" alt="backgroundImage" />
                        ) : (
                            <LazyImg  src="/imgs/texture/welcome-dfamily-2024.png" alt="backgroundImage" />
                            
                        )}
                    </div>
                    <div className={mobileClasses}>
                        {type === 'friends' ? (
                            <LazyImg src="/imgs/texture/invita-gli-amici.png" alt="backgroundImage" />
                        ) : (
                            <LazyImg  src="/imgs/texture/welcome-dfamily-mobile-2024.png" alt="backgroundImage" />
                            
                        )}
                    </div>
                </div>
                <div className={style.content}>
                    {type === 'friends' ? (
                        <div className={style.friends} id="friends">
                            <p>
                                Invita i tuoi amici a registrarsi a DonnaD!
                                <span>Guadagna punti e conquista nuove sfide.</span>
                            </p>
                            <Cta label="Condividi" onClick={onClickSocialShare} />                      
                        </div>
                    ) : (
                        <div className={style.dfamily}>
                            <h2 className={style.title}>Unisciti alla Dfamily, il programma fedeltà di DonnaD!</h2>
                            <p className={style.body}>
                                Clicca su «Partecipa alla Dfamily» e ricevi i primi punti D di benvenuto.
                            </p>
                            <Cta label="Partecipa alla Dfamily" modifiers={['white']} onClick={onClick} />
                        </div>
                    )}
                </div>
            </div>
         {/*    <small>
                Operazione a premi valida dal 08/03/2021 alle ore 15.00 sino al 14/12/2021 alle ore
                15.00. Montepremi Parte Operazione a premio 613.600 € (iva esclusa). Regolamento
                completo{' '}
                <a
                    href={`${process.env.PUBLIC_URL}/regolamento_chi_trova_un_amica_trova_un_tesoro_2021.pdf`}
                >
                    qui.
                </a>
            </small> */}
        </div>
    );
};

export default LoyaltyCard;
