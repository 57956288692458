import React from 'react';

// const user = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).user : null;
const user = null;
const appContext = React.createContext({
    user: user,
    loading: true,
    interestsModal: false,
    currentPrize: null,
    alert: null,
});

export default appContext;
