import React, { useState } from 'react';
import style from './style.module.scss';

const CheckboxLabel = ({ name = 'checkbox', label, checked, emit}) => {
    const [currentChecked, setCurrentChecked] = useState(checked);

    return (
        <label className={style.el} htmlFor={name}>
            <input type="checkbox" value={label} name={name} checked={currentChecked} onChange={(e) => {
                setCurrentChecked(e.target.checked);
                if(emit) emit(e.target.checked);
            } } />
            <span className={style.checkmark}>
                {label}
            </span>
        </label>
    );
}

export default CheckboxLabel;
