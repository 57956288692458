import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import LazyImg from '../LazyImg';

import 'swiper/swiper.scss';
import './style.module.scss';

const ArticleGallery = ({items}) => {
    return (
        <div className="article-gallery-wrapper">
            <Swiper spaceBetween={8}>
                {items && items.map((i,j) => <SwiperSlide key={j}><LazyImg src={i.image} alt={i.alt}/></SwiperSlide>)}
            </Swiper>
        </div>
    );
}

export default ArticleGallery;
