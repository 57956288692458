import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import 'react-datetime/css/react-datetime.css';
import 'moment/locale/it';

import SwiperCore, { Pagination, Parallax } from 'swiper';

import style from './style.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { Close, Minus, Info } from '../Icons';

import './gigya-form.css';
import Cta from '../Cta';

SwiperCore.use([Pagination, Parallax]);

class MessageAlert extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            showMessage: false,
            isMobile: window.innerWidth < 1024,
        };
    }


    componentDidUpdate(prevProps) {
        if (prevProps.context.state.messageAlert !== this.props.context.state.messageAlert) {
            if (!!this.props.context.state.messageAlert) {
                disableBodyScroll(this.wrapperRef.current);
            } else {
                enableBodyScroll(this.wrapperRef.current);
            }
        }  
          
        if(this.props.context.state.message && this.props.context.state.message.gigya_form){
            window.gigya.accounts.showScreenSet({
                screenSet: this.props.context.state.message.gigya_form.screenSet,
                startScreen: this.props.context.state.message.gigya_form.startScreen,
                containerID: this.props.context.state.message.gigya_form.containerID,
            });
            var props = this.props;
            window.addEventListener('gigyaScreensetAfterSubmit', function (e) {
                if(e.returnValue){
                    //chiudo il form
                    props.context.dispatch({ type: 'TOGGLE_MESSAGE_ALERT', payload: { message: null } });  
                }
            });
        }

    }


    render() {
        const { state, dispatch } = this.props.context;
        return (
            <React.Fragment>
                <CSSTransition in={state.messageAlert} timeout={200} classNames="popup" unmountOnExit>
                    <div className={style.wrapper} ref={this.wrapperRef}>
                        <div className={style.container}>
                            <div className={style.inner}>
                                <span
                                    className={style.close}
                                    onClick={() => dispatch({ type: 'TOGGLE_MESSAGE_ALERT', payload: { message: null } })}
                                >
                                    <Close />
                                </span>
                                {state.message &&                                
                                    <div className={style.section}>                     
                                        <h3 className={style.title}>{state.message.title}</h3>
                                        <p className={style.description}>{state.message.description}</p>
                                        {state.message.gigya_form && <div className={style.form} id={state.message.gigya_form.containerID}></div>}
                                        {state.message.cta && <Cta label={state.message.cta.label} onClick={state.message.cta.onClick} modifiers={state.message.cta.modifiers}/>}                      
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }
}

export default MessageAlert;
