import React, {useState, useEffect, useContext} from 'react';
import { Helmet } from "react-helmet";

import { Messaging } from "../../firebase.js";

import {Notifications as NotificationsAPI, NotificationsHide as NotificationsHideAPI, Firebase as FirebaseAPI} from '../../Api.js';

import Notification from "../../components/Notification";
import Cta from "../../components/Cta";
import ArticleListPreview from "../../components/ArticleListPreview";

import AppContext from '../../contexts/appContext';

import style from './style.module.scss';

const Notifications = () => {

    const { dispatch, state } = useContext(AppContext);
    const [fetched, setFetched] = useState(false);
    const [userTokens, setUserTokens] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const supported = () => 'PushManager' in window;

    const saveToken = token => {
        FirebaseAPI.post('/register', {
            "token": token
        }).then(({data}) => {
            dispatch({type: 'UNSET_LOADING'});
        });
    }

    const deleteToken = () => {
        dispatch({type: 'SET_LOADING'});
        FirebaseAPI.delete('/remove', {
            data: { 'token': localStorage.getItem('fbToken') }
        }).then(({data}) => {
            // console.log(data);
            localStorage.removeItem('fbToken');
            dispatch({type: 'UNSET_LOADING'});
        });
    }

    const onNotificationRemove = id => {
        dispatch({type: 'SET_LOADING'});
        NotificationsHideAPI.post('',{
            'notification': id
        }).then(({data}) => {
            NotificationsAPI.get().then(({data}) => {
                setNotifications(data.data)
                dispatch({type: 'UNSET_LOADING'});
            })
        }).catch(error => {
            console.log(error);
            dispatch({type: 'UNSET_LOADING'});
        })
    }

    const hasLocalToken = () => {
        return !!localStorage.getItem('fbToken');
    }

    const activateNotifications = () => {
        if (!supported()) {
            return;
        }
        dispatch({type: 'SET_LOADING'});
        Messaging.getToken()
        .then((currentToken) => {
            if (currentToken) {
                localStorage.setItem('fbToken', currentToken);
                if(userTokens.indexOf(currentToken) === -1) {
                    saveToken(currentToken);
                } else {
                    dispatch({type: 'UNSET_LOADING'});
                }
            } else {
                console.log('No Instance ID token available. Request permission to generate one.');
                dispatch({type: 'UNSET_LOADING'});
            }
        })
        .catch(err => {
            console.log("Unable to get permission to notify.", err);
            dispatch({type: 'UNSET_LOADING'});
        });
    }

    useEffect(() => {

        dispatch({type: 'SET_PAGE', payload: { type: 'notifications' }})

        NotificationsAPI.get().then(({data}) => {
            setFetched(true);
            setNotifications(data.data)
            if(data.data.length > 0) {
                localStorage.setItem('last_note',data.data[0].created_on)
            }
        });

        FirebaseAPI.get().then(({data}) => {
            const tokens = data.data.map(d => d.deviceToken);
            setUserTokens(data.data);
            if(hasLocalToken()) {
                //if token not in user tokens array refresh token
                if(tokens.indexOf(localStorage.getItem('fbToken')) === -1) {
                    activateNotifications()
                }
            }
        });

    },[dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.el}>
            <Helmet>
                <title>Notifiche | DonnaD</title>
            </Helmet>
            {!hasLocalToken() && supported() &&  <div className={style.settings}>
                <p><strong>Le notifiche push sono disattivate.</strong>Attiva ora le notifiche push per sapere quando uno scontrino è stato processato.</p>
                <Cta label="Attivale subito" onClick={activateNotifications}/>
            </div>}
            {state.settings && hasLocalToken() && supported() && <div>
                <p className={style.title}>Impostazioni</p>
                <div className={style.settings}>
                    <p><strong>Le notifiche push sono attive.</strong>Riceverai notifiche push ogni volta che uno scontrino viene processato.</p>
                    <Cta label="Disattiva Noitifiche" modifiers={['secondary']} onClick={deleteToken}/>
                </div>
            </div>}
            <div>
                <p className={style.title}>Le mie notifiche</p>
                {!!fetched ? (notifications.length > 0 ?
                        <div className={style.wrapper}>
                            {
                                notifications.map(item =>
                                <Notification
                                    key={item.id}
                                    title={item.title}
                                    text={item.body}
                                    onRemoveClick={ () => onNotificationRemove(item.id) }
                                />)
                            }
                        </div> :
                    <div className={style.nores}>
                        <h2>Al momento, non hai alcuna notifica.</h2>
                        <p>In questa sezione, potrai trovare comunicazioni legate alle tue azioni, avvisi in tempo reale, aggiornamenti su nuove iniziative o funzionalità e molto altro. <strong>Restiamo in contatto!</strong></p>
                    </div>)
                : <ArticleListPreview/> }
            </div>
        </div>
    );
}

export default Notifications;
