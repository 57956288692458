import React,{useState,useEffect,useContext} from 'react';
import AppContext from '../../contexts/appContext';
import style from './style.module.scss';

import {UserActions as UserActionsAPI} from '../../Api.js';

const ArticleTimeBar = ({id}) => {
    const { state } = useContext(AppContext);
    const [progress,setProgress] = useState(30);
    const [status,setStatus] = useState(null);

    useEffect(() => {
        if(id) {
            let interval = null;
            interval = setInterval(() => {
                setProgress((p) => p - 1);
            },1000)

            UserActionsAPI.get('', {
                params: {
                    'filter[action_id]' : 'article-read',
                    'filter[idata]' : id
                }
            }).then(({data}) => {
                if(data.data.length === 0) {
                    setStatus('notread')
                }
            })

            return () => {
                clearInterval(interval);
                setProgress(30);
            }
        }

    }, [id])

    return (
        <React.Fragment>
        {
            !!state.user  && state.current_page && state.current_page.type === 'article' && status === 'notread' ? <div className={style.timer}>
                {progress > 0 && <p>Tempo di lettura</p>}
                {progress <= 0 && <p>Hai letto quest'articolo</p>}

                <span className={style.progress}><span style={{width:`${100 - progress*100/30}%`}}></span></span>
                {progress > 0 && <span className={style.number}>{progress}</span>}
                {progress <= 0 && <span className={style.check}><svg viewBox="0 0 14 10"><path d="M12.3.3c.4-.4 1-.4 1.4 0 .4.4.4.9.1 1.3L5.7 9.7c-.4.4-.9.4-1.3.1l-.1-.1-4-4c-.4-.4-.4-1 0-1.4.4-.4.9-.4 1.3-.1l.1.1L5 7.6 12.3.3z"/></svg></span>}
            </div> : <span></span>
        }
        </React.Fragment>
    );
}

export default ArticleTimeBar;


