import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Present, List, Megaphone, Tachometer, CheckBox, Friend, Faq } from '../Icons';
import style from './style.module.scss';

const QuickAccessNav = ({ type, onUploadTicketClick, loyalty, loyalty_prizes }) => {
    const classes = classNames(style.rs22, style.el__QuickAccessNav, {
        [style.inline]: type === 'inline',
    });

    const goToActions = (switch_index) => {
        let container = document.getElementById("loyalty-switch");
                
        console.log('Go to actions ' + style.loyaltyswitch);
        console.log(container.children);

        let children = container.children;
        console.log('Button');
        console.log(children);

        children[switch_index].click();
    }

    return (
        <ul className={classes}>
            {/* <li>
                <a href={`https://www.donnad.it/lp/universo-donnad`}>
                    <Megaphone />
                    <span>Le iniziative</span>
                </a>
            </li> */}
            {/* {loyalty && (
                <li>
                    <Link to={`/come-guadagnare-punti`}>
                        <Tachometer />
                        <span>iniziative DFamily</span>
                    </Link>
                </li>
            )} */}
            {loyalty && (
                <li>
                    <a href={process.env.REACT_APP_DONNAD_DOMAIN + "/lp/dfamily-survey"} target="_blank">
                        <CheckBox />
                        <span>Quiz e Sondaggi</span>
                        
                    </a>
                </li>
            )}
            {loyalty && (
                <li onClick={onUploadTicketClick}>
                    <List />
                    <span>Carica scontrino</span>
                </li>
            )}
            {loyalty && (
                <li>
                    <Friend />
                    <a onClick={() => goToActions(0)} href="#friends">
                        <span>Invita un amico</span>
                    </a>
                </li>
            )}
            {loyalty_prizes && (
                <li>
                    <a onClick={() => goToActions(2)} href="#loyalty-section">
                        <Present />
                        <span>Richiedi i premi</span>
                    </a>
                </li>
            )}
            {loyalty_prizes && (
                <li>
                    <Link to={`/faq`}>
                        <Faq />         
                        <span>FAQ</span>
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default QuickAccessNav;
