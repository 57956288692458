import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import style from './style.module.scss';

const AccountDeleted = () => {

    useEffect(() => {

    },[])


    return (
        <div>
            <Helmet>
                <title>Account Rimosso | DonnaD</title>
            </Helmet>
            <div className={style.surveyWrapper}>
                <iframe width="100%" height="100%" title="survey" frameBorder="0" allowtransparency="true" src="https://www.surveymonkey.com/r/W88S37B?embedded=1"/>
            </div>
        </div>
    );
}

export default AccountDeleted;
