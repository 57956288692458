import React from 'react';
import style from './style.module.scss';


const ArticlelList = () => {

    return (
        <div className={style.wrapper}>
            {[].map.call('ilbaffodiferro',(b,j) => <span key={j}></span>)}
        </div>
    );
}

export default ArticlelList;
