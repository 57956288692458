import React, { useState } from 'react';
import classNames from 'classnames';
import style from './style.module.scss';

import Cta from '../Cta';
import { Close } from '../Icons';

const Alert = ({
    text,
    visibility = false,
    modifiers = [],
    primaryCta,
    secondaryCta = { label: 'Annulla' },
}) => {
    const [isVisible, setIsVisible] = useState(visibility);

    const classes = classNames(
        modifiers.map((modifier) => style[modifier]),
        {
            [style.el]: true,
            [style.active]: !!isVisible,
        }
    );

    return (
        <div className={classes}>
            <div className={style.inner}>
                <button
                    aria-label="chiudi"
                    onClick={() => {
                        setIsVisible(false);
                        secondaryCta.onClick && secondaryCta.onClick();
                    }}
                >
                    <Close />
                </button>
                {text && (
                    <div className={style.data} dangerouslySetInnerHTML={{ __html: text }}></div>
                )}
                {primaryCta && primaryCta.onClick && (
                    <div className={style.action}>
                        <Cta
                            label={secondaryCta.label}
                            modifiers={['primary']}
                            onClick={() => {
                                setIsVisible(false);
                                secondaryCta.onClick && secondaryCta.onClick();
                            }}
                        />
                        <Cta
                            label={primaryCta.label}
                            modifiers={['primary']}
                            onClick={primaryCta.onClick}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Alert;
