import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import AppContext from '../../contexts/appContext';
import { isOnLine } from '../../utils';

import { Articles as ArticlesAPI, UserActions as UserActionsAPI } from '../../Api.js';

import ArticleList from '../../components/ArticleList';
import LoginWall from '../../components/LoginWall';
import ArticleListPreview from '../../components/ArticleListPreview';

import style from './style.module.scss';

const Latest = ({ title, limit, hasPrivateContent }) => {
    const [feed, setFeed] = useState([]);
    const [readArticles, setReadArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        if (!isOnLine()) {
            setLoading(false);
        }

        ArticlesAPI.get('/latest.json')
            .then(({ data }) => {
                !limit ? setFeed(data.data) : setFeed(data.data.slice(0, limit));
                setLoading(false);
                if (!!state.user) {
                    UserActionsAPI.get('', {
                        params: {
                            'filter[action_id]': 'article-read',
                            'filter[idata][in]': data.data.map((a) => a.nid),
                        },
                    }).then(({ data }) => {
                        if(data){
                            setReadArticles(data.data.map((a) => a.metadata.drupal_nid));
                        }             
                    });
                }
            })
            .catch((error) => {
                error.response && console.log(error.response.status);
                setLoading(false);
            });
    }, [dispatch, limit, state.user]);

    const onCtaClick = () => {
        dispatch({
            type: 'USER_LOGIN',
            payload: {
                onHide: () => {
                    dispatch({ type: 'UNSET_LOADING', payload: null });
                },
            },
        });
    };

    return (
        <div className={classNames(style.articlesListWrapper, { [style.hiddenarea]: !state.user })}>
            {!loading ? (
                <ArticleList
                    title={title}
                    limit={limit}
                    isEditable={true}
                    items={feed.map((f) => {
                        return {
                            id: f.nid,
                            read: readArticles.indexOf(f.nid) !== -1,
                            slug: f.slug,
                            thumbnail: f.image,
                            caption: f.category.title,
                            title: f.title,
                            labels: f.labels,
                            time: f.estimated_read_time,
                        };
                    })}
                />
            ) : (
                <ArticleListPreview />
            )}
            {!!!state.user && hasPrivateContent && feed.length > 0 && (
                <LoginWall onCtaClick={onCtaClick} />
            )}
        </div>
    );
};

export default Latest;
