import React, { useState } from 'react';
import { without, find } from 'lodash';
import { SurveysAnswers as SurveysAnswersAPI } from '../../Api.js';
import { Close } from '../Icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './style.module.scss';

const Loyalty = ({ user, survey, questions, loyalty }) => {
    const [index, setIndex] = useState(1);
    const [instance, setInstance] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [baloon, setBaloon] = useState(true);
    const [answers, setAnswers] = useState(
        questions.map((q, k) => ({
            id: q.id,
            done: false,
            data: [],
        }))
    );

    const next = (question_id, answer) => {
        setLoading(true);
        SurveysAnswersAPI.post('', {
            user_id: user ? user.id : null,
            session_id: !user ? localStorage.getItem('session_id') : null,
            survey_id: survey.id,
            question_id: question_id,
            answer: answer ? answer : find(answers, (a) => a.id === question_id).data.join(','),
        }).then(({ data }) => {
            setIndex((i) => i + 1);
            instance.slideTo(index);
            setLoading(false);
            setAnswers((old_answers) => {
                return old_answers.map((a) => {
                    if (a.id === question_id) {
                        a.data.done = true;
                    }
                    return a;
                });
            });
        });
    };

    const close = () => {
        window.location.reload();
    };

    const open = () => {
        setIsOpen(true);
        document.body.classList.add('lock');
    };

    const onInputChange = (e, q, v) => {
        const target = e.target;
        const value = v;

        if (q.type === 'single') {
            setAnswers((old_answers) => {
                return old_answers.map((a) => {
                    if (a.id === q.id) {
                        a.data = [value];
                    }
                    return a;
                });
            });

            if (q.answers.length <= 2) {
                next(q.id, value);
            }
        }
        if (q.type === 'multiple') {
            setAnswers((old_answers) => {
                return old_answers.map((a) => {
                    if (a.id === q.id) {
                        if (target.checked === true) {
                            a.data.push(value);
                        } else {
                            a.data = without(a.data, value);
                        }
                    }
                    return a;
                });
            });
        }
        if (q.type === 'open') {
            setAnswers((old_answers) => {
                return old_answers.map((a) => {
                    if (a.id === q.id) {
                        if (!!target.value.trim()) {
                            a.data = [target.value.trim()];
                        } else {
                            a.data = [];
                        }
                    }
                    return a;
                });
            });
        }
    };

    const checkDisabledCta = (q) => {
        let disabled = false;

        if (!answers.length > 0) {
            return;
        }

        if (loading) {
            return style.disabled;
        }

        if (q.type === 'single' && q.answers.length <= 2) {
            disabled = false;
        } else {
            disabled = find(answers, (a) => a.id === q.id).data.length === 0;
        }

        if (disabled) {
            return style.disabled;
        } else {
            return style.enabled;
        }
    };

    return (
        <div className={style.wrapper}>
            {isOpen ? (
                <div className={style.container}>
                    <span className={style.backdrop}></span>
                    <div className={style.inner}>
                        <span className={style.close} onClick={close}>
                            <Close />
                        </span>
                        <div className={style.avatar}>
                            <img src="/imgs/surveys/avatar.png" alt="Survey" />
                        </div>
                        <div className={style.content}>
                            {index <= questions.length && (
                                <div
                                    className={style.counter}
                                >{`Domanda ${index} di ${questions.length}`}</div>
                            )}
                            <Swiper
                                onInit={setInstance}
                                simulateTouch={false}
                                allowTouchMove={false}
                                autoHeight={true}
                                watchOverflow={true}
                            >
                                {questions.map((q, k) => (
                                    <SwiperSlide key={k}>
                                        <div className={style.question}>
                                            <div className={style.title}>{q.question}</div>
                                            <div className={style.inputList}>
                                                {(q.type === 'multiple' ||
                                                    (q.type === 'single' &&
                                                        q.answers.length > 2)) &&
                                                    q.answers.map((a, j) => (
                                                        <span key={j} className={style.radio}>
                                                            {q.type === 'single' && (
                                                                <input
                                                                    onChange={(e) =>
                                                                        onInputChange(
                                                                            e,
                                                                            q,
                                                                            a.answer
                                                                        )
                                                                    }
                                                                    type="radio"
                                                                    name="ciao"
                                                                    value={a.id}
                                                                    id={a.id}
                                                                />
                                                            )}
                                                            {q.type === 'multiple' && (
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        onInputChange(
                                                                            e,
                                                                            q,
                                                                            a.answer
                                                                        )
                                                                    }
                                                                    name={a.id}
                                                                    value={a.id}
                                                                    id={a.id}
                                                                />
                                                            )}
                                                            <label htmlFor={a.id}>{a.answer}</label>
                                                        </span>
                                                    ))}
                                                {q.type === 'open' && (
                                                    <textarea
                                                        onChange={(e) => onInputChange(e, q)}
                                                        placeholder="Scrivi qui la risposta"
                                                    ></textarea>
                                                )}
                                            </div>
                                            <div className={`${style.ctas} ${checkDisabledCta(q)}`}>
                                                {q.type === 'single' && q.answers.length <= 2 && (
                                                    <div className={style.switch}>
                                                        {q.answers.map((a, j) => (
                                                            <span
                                                                key={j}
                                                                onClick={(e) =>
                                                                    onInputChange(e, q, a.answer)
                                                                }
                                                                className={style.btn}
                                                            >
                                                                {a.answer}
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                                {(q.type === 'multiple' ||
                                                    (q.type === 'single' && q.answers.length > 2) ||
                                                    q.type === 'open') && (
                                                    <div
                                                        onClick={() => next(q.id, null)}
                                                        className={style.next}
                                                    >
                                                        {`${
                                                            index === questions.length - 1
                                                                ? 'Continua'
                                                                : 'Fine'
                                                        }`}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                <SwiperSlide>
                                    <div className={style.question}>
                                        <div className={style.title}>
                                            <br />
                                            <br />
                                            Grazie di aver risposto!
                                            <br />
                                        </div>
                                        <div className={style.inputList}></div>
                                        <div className={style.ctas}>
                                            <div onClick={close} className={style.next}>
                                                Chiudi
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            ) : survey && questions.length > 0 ? (
                <div className={style.trigger}>
                    <div className={style.triggerWrapper} onClick={open}>
                        <img src="/imgs/surveys/avatar.png" alt="" />
                    </div>
                    {baloon && !!loyalty && (
                        <div className={style.baloon}>
                            <strong>Fatti premiare!</strong>
                            <p>
                                {/* Vuoi guadagnare nuovi punti? */}
                                Vogliamo conoscerti meglio
                                <br /> Rispondi a una domanda!
                            </p>
                            <span className={style.cta} onClick={open}>
                                Vai alla domanda
                            </span>
                            <span className={style.close} onClick={() => setBaloon(false)}>
                                <Close />
                            </span>
                        </div>
                    )}
                    {baloon && !loyalty && (
                        <div className={style.baloon}>
                            <strong>Ancora più vicina!</strong>
                            <p>
                                Vogliamo che DonnaD ti sia sempre più utile: hai tempo per
                                rispondere a una domanda?
                            </p>
                            <span className={style.cta} onClick={open}>
                                Fammi la domanda
                            </span>
                            <span className={style.close} onClick={() => setBaloon(false)}>
                                <Close />
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default Loyalty;
