import React from 'react';

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import TextureCard from '../TextureCard';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import './style.module.scss';

// install Swiper components
SwiperCore.use([Pagination]);

const PointsGallery = ({ items }) => {
    const CardStaticData = {
        cta: {
            label: 'label',
            url: '/',
        },
        image: {
            src: '/imgs/texture/red.jpg',
            name: 'smartphone',
        },
        modifiers: ['alwayscard'],
    };

    return (
        <div className="points-gallery-wrapper">
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {items &&
                    items.map((item, j) => (
                        <SwiperSlide key={j}>
                            <TextureCard
                                {...CardStaticData}
                                title={item.title}
                                textTitle={item.description}
                                text={item.sub_description}
                                info={item.info}
                                points={item.points}
                                // cta={item.cta}
                                cta={null}
                            />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default PointsGallery;
