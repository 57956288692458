import React from 'react';
import style from './style.module.scss';


const Spinner = () => {
    return (
        <div className={style.el}><div className={style.loader}></div></div>
    );
}

export default Spinner;
