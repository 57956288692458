import React from 'react';
import style from './style.module.scss';

import LinkTo from '../LinkTo';
import LoyaltyTitle from '../LoyaltyTitle'

const LoyaltyNavigationCard = ({items}) => {

    return (
        <div className={style.el}>
            <img src={`/imgs/texture/loyalty.png`} alt="backgroundImage" />
            <div className={style.head}>
               <h3 className={style.text}>Vai al programma fedeltà Dfamily</h3>
            </div>
            <div className={style.content}>
                {items.map((item,j) => <LinkTo key={j} {...item} /> )}
            </div>
        </div>
    );
}

export default LoyaltyNavigationCard;
