import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import Cta from '../Cta';
import LinkTo from '../LinkTo';

const Card = ({ action, completed }) => {

    const [showFullText, setShowFullText] = useState(false);
    const [maxLength, setMaxLength] = useState(90);
    const [description, setDescription] = useState('');
    const [fullText, setFullText] = useState(completed?action.completed.description:action.description);
    const [longText, setLongText] = useState(false);
    const [coutdown, setCountdown] = useState('');

    useEffect(() => {

        if(fullText.length>maxLength){
            setDescription(fullText.substring(0, maxLength) + '...');
            setShowFullText(false);
            setLongText(true);
        }else{
            setDescription(fullText);
            setShowFullText(true);
        }

        setInterval(() => {
            
            const futureDate = new Date(action.expiration_date);
            const currentDate = new Date();
            const difference = futureDate - currentDate;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setCountdown(`<div><p>Termina tra</p><div><span>${days}</span><span>Giorni</span></div><div><span>${hours}</span><span>Ore</span></div><div><span>${minutes}</span><span>Min</span></div><div><span>${seconds}</span><span>Sec</span></div></div>`);

        }, 1000);

    }, []);

    const toggleShowFullText = () => {
        if(showFullText){
            setDescription(fullText.substring(0, maxLength) + '...');
            setShowFullText(false);         
        }else{
            setDescription(fullText);
            setShowFullText(true);           
        }
    };

    return (
        <div className={style.card_container}>
            <span className={style.id} id={action.id}></span>
            <div className={longText && showFullText ? style.card + ' ' + style.long : style.card} >
                {action.new && 
                    <div className={style.new}>
                        <LazyImg src="/imgs/texture/new-flag.svg" alt="new" />
                    </div>
                }
                <div className={style.image}>
                    <LazyImg src={action.image && (action.image.src)} alt="backgroundImage" />
                </div>
                <div className={style.content}>
                    <div className={style.header}>
                        <p className={style.category}>{action.category}</p>
                        <p className={style.points}>{!completed ? action.points : action.completed.points} Punti D</p>
                    </div>
                    {!completed &&
                        <div className={style.body}>
                            <p className={style.title}>{action.title}</p>
                            <p className={style.description}>{description}{longText?<button onClick={() => toggleShowFullText()}>{showFullText ? 'Leggi di meno' : 'Leggi di più'}</button>:''}</p>
                        </div>
                    }
                    {completed &&
                        <div className={style.body}>
                            <p className={style.title}>{action.completed.title}</p>
                            <p className={style.description}>{description}{longText?<button onClick={() => toggleShowFullText()}>{showFullText ? 'Leggi di meno' : 'Leggi di più'}</button>:''}</p>
                            {action.completed.cta && <Cta {...action.completed.cta} />}
                        </div>
                    }                 
                    <div className={style.footer}>
                        {!completed &&
                            <div>
                                {action.cta && <Cta {...action.cta} />}
                                {action.progress && <p className={style.progress}>{action.progress}</p>}
                                {action.expiration_date && <p className={style.coutdown} dangerouslySetInnerHTML={{ __html: coutdown }}></p>}
                                {action.event_name=="concorsi" && <div className={style.flag}><LazyImg src="/imgs/texture/flag_concorsi.png" alt="flag_concorsi" /></div>}
                            </div>
                        }
                        {completed && 
                            <div className={style.completed}>
                                {action.completed.date && 
                                    <p className={style.date}>
                                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.71367 12.1946L0.0136719 6.76187L1.43867 5.40368L5.71367 9.47825L14.8887 0.733398L16.3137 2.09159L5.71367 12.1946Z" fill="#1C1B1F"/>
                                        </svg>
                                        <strong>COMPLETATA</strong>&nbsp;
                                        <span>{action.completed.date}</span>
                                    </p>
                                }
                            </div>
                        }                     
                    </div>               
                </div>
            </div>
        </div>
    );
};

export default Card;
