import React from 'react';
import Cta from '../Cta';
import LazyImg from '../LazyImg';
import { useMediaQuery } from 'react-responsive';

import style from './style.module.scss';

const RewardCard = ({ image, description, name, points, cta, modifiers, page = '', backgroundColor }) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' }, undefined, () => {
        window._neoHenkel && window._neoHenkel.B.refresh();
    });

    let styleCard = [style.card];

    if (modifiers) {
        styleCard.push(modifiers.map((modifier) => style[modifier]));
    }

    if(!backgroundColor) {
        backgroundColor = 'none';
    }

    return (
            <div className={style.card}>
                <div className={style.image}>
                    {
                        <div style={{ backgroundColor: backgroundColor }}>
                            <LazyImg src={image} alt="backgroundImage" />
                        </div>
                    }
                </div>
                <div className={style.content}>
                    <h2 className={page == 'activities' || page == 'prizes' ? style.title2 : style.title}>
                        <span>{name}</span>
                        {page != 'activities' && page != 'prizes' ? (
                            <React.Fragment>
                                <b style={{ color: '#000' }}>Valore:</b> {points} punti D
                            </React.Fragment>
                        ) : (
                            ''
                        )}
                    </h2>
                    <p
                        className={page == 'activities' ? style.body2 : style.body}
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                    {cta && <Cta {...cta} />}
                </div>
            </div>
    );
};

export default RewardCard;
