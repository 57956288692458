import React, { useEffect, useState, useContext } from 'react';
import dateFormat from 'dateformat';
import { find } from 'lodash';

import { getMonday, getSunday, fixDateForAllBrowsers } from '../../utils';

import AppContext from '../../contexts/appContext';
import { Horoscope as HoroscopeAPI, Zodiac as ZodiacAPI } from '../../Api.js';
import Horoscope from '../../components/Horoscope';

import style from './style.module.scss';

const HoroscopeHp = ({ onFeedbackSent }) => {
    const [horoscope, setHoroscope] = useState(null);
    const [userHoroscope, setUserHoroscope] = useState(null);
    const [loading, setLoading] = useState(true);
    const [friends, setFriends] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [signs, setSigns] = useState([]);
    const { state, dispatch } = useContext(AppContext);

    const onFriendAdd = (data, cb) => {
        setLoading(true);
        // const headers = !!state.user ? { 'GigyaJWT': jwtHelper.get() } : null
        HoroscopeAPI.post('/friends_horoscope', data)
            .then(({ data }) => {
                cb && cb();
                refreshHoroscope();
            })
            .catch((error) => {
                cb && cb();
            });
    };

    const onFriendRemove = (id, cb) => {
        setLoading(true);
        HoroscopeAPI.delete(`/friends_horoscope/${id}`).then(({ data }) => {
            refreshHoroscope();
            cb && cb();
        });
    };

    const onFriendSelect = (sign_id) => {
        refreshHoroscope(sign_id);
    };

    const onFeedbackClick = (value) => {
        setLoading(true);
        HoroscopeAPI.post('/horoscope_feedback', {
            feedback: value,
        })
            .then(({ data }) => {
                setLoading(false);
                setFeedback(data);
                onFeedbackSent();
            })
            .then(() => {
                if (typeof window.dataLayer != 'undefined') {
                    window.dataLayer.push({
                        event: 'PWA',
                        category: 'PWA',
                        action: 'Horoscope vote',
                        label: `${userHoroscope.sign} - ${value ? 'ok' : 'ko'}`,
                    });
                }
            });
    };

    const refreshHoroscope = (sign_id) => {
        setLoading(true);

        const today = dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss');

        ZodiacAPI.get('', {
            params: {
                fields: '*,image.data.full_url',
            },
        })
            .then(({ data }) => {
                let signs = data.data;
                setSigns(data.data);
                const config = {
                    params: {
                        'filter[starts_on][lte]': today,
                        'filter[ends_on][gte]': today,
                        'filter[zodiacal_sign_id]': sign_id || null,
                    },
                };
                if (!state.user) {
                    config.headers = null;
                }

                HoroscopeAPI.get('/horoscope', config)
                    .then(({ data }) => {
                        if (data.data.length > 0) {
                            let sign = find(signs, (s) => s.slug === data.data[0].zodiacal_sign_id);
                            setHoroscope({
                                sign: sign,
                                content: data.data[0],
                            });
                            if (!sign_id) {
                                setUserHoroscope({
                                    name: 'il mio segno',
                                    sign: sign.name,
                                    image: sign.image.data.full_url,
                                });
                            }
                        }

                        if (!!state.user) {
                            HoroscopeAPI.get('/friends_horoscope')
                                .then(({ data }) => {
                                    const friends = data.data.map((f) => {
                                        return {
                                            id: f.id,
                                            sign_id: f.zodiacal_sign_id,
                                            name: f.friend_name,
                                            image: find(signs, (s) => s.slug === f.zodiacal_sign_id)
                                                .image.data.full_url,
                                        };
                                    });
                                    setFriends(friends);
                                    if (!!state.user) {
                                        HoroscopeAPI.get('/horoscope_feedback', {
                                            params: {
                                                'filter[created_on][gte]': dateFormat(
                                                    getMonday(new Date()),
                                                    'yyyy-mm-dd'
                                                ),
                                                'filter[created_on][lte]': dateFormat(
                                                    getSunday(new Date()),
                                                    'yyyy-mm-dd'
                                                ),
                                            },
                                        }).then(({ data }) => {
                                            setFeedback(data);
                                            setLoading(false);
                                        });
                                    }
                                })
                                .catch((error) => {
                                    setLoading(false);
                                });
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch(({ response }) => {
                        if(response){
                            dispatch({
                                type: 'SET_ALERT_MODAL',
                                payload: {
                                    text: `<p><strong>Si è verificato un problema</strong></p><p>${response.data.error.message}</p>`,
                                    modifiers: ['error'],
                                    secondaryCta: {
                                        onClick: () => dispatch({ type: 'UNSET_ALERT_MODAL' }),
                                    },
                                },
                            });
                            setLoading(false);
                        }                  
                    });
            })
            .catch((error) => {
                console.log('error');
                setLoading(false);
            });
    };

    const myPerfectDate = (d) => {
        const breakDate = d.split('/');
        const date = `${breakDate[1]}/${breakDate[0]}/20 00:00:00`;
        return (
            new Date(fixDateForAllBrowsers(date)).getDate() +
            ' ' +
            new Date(fixDateForAllBrowsers(date)).toLocaleString('it-IT', { month: 'short' })
        );
    };

    useEffect(() => {
        refreshHoroscope();
    }, [state.user]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${style.horoscopeWrapper} ${loading ? style.loading : undefined}`}>
            {horoscope && (
                <Horoscope
                    isLogged={!!state.user}
                    signs={signs}
                    // isClubMember={state.user && isClubMember(state.user.data)}
                    isClubMember={true}
                    label={`${
                        !!state.user ? 'Oroscopo della settimana' : 'Oroscopo dei nati oggi'
                    }`}
                    title={horoscope.sign.name}
                    image={{ name: horoscope.sign.name, url: horoscope.sign.image.data.full_url }}
                    text={horoscope.content.description}
                    onFriendAdd={onFriendAdd}
                    onFriendRemove={onFriendRemove}
                    onFriendSelect={onFriendSelect}
                    userHoroscope={userHoroscope}
                    feedback={feedback}
                    onFeedbackClick={onFeedbackClick}
                    zodiacs={friends}
                    labelDate={`${myPerfectDate(horoscope.sign.period_starts_on)} - ${myPerfectDate(
                        horoscope.sign.period_ends_on
                    )}`}
                />
            )}
        </div>
    );
};

export default HoroscopeHp;
