import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { Swiper, SwiperSlide } from 'swiper/react';

import PointsCard from '../PointsCard';
import TicketCard from '../TicketCard';
import GiftCard from '../GiftCard';

import {
    Tickets as TicketsAPI,
    Loyalty as LoyaltyAPI,
    LoyaltyActive as LoyaltyActiveAPI,
} from '../../Api.js';

import style from './style.module.scss';

const UserDashboard = ({ onPointsTicketClick, updated, username, points }) => {
    //const [points, setPoints] = useState(null);
    const [endPrizesDate, setEndPrizesDate] = useState(null);
    const [isLoyaltyActive, setIsLoyaltyActive] = useState(false);
    //const [ticket, setTicket] = useState(null);
    const [ticket, setTicket] = useState('not_ticket_yet');
    useEffect(() => {
        /* LoyaltyAPI.get('/status').then(({ data }) => {
            setPoints(data.data.points);
        }); */
        LoyaltyActiveAPI.get('/is-active').then(({ data }) => {
            setEndPrizesDate(data.data.endPrizes);
            setIsLoyaltyActive(data.data.isActive);
        });
    }, [updated]);

    const onTicketClick = (e) => {
        //  window.scrollBy(0, 400);
        const target = document.getElementById('anchor');

     //   element.scrollIntoView();
     if (window.scrollTo) {
        e.preventDefault();
        window.scrollTo({"behavior": "smooth", "top": target.offsetTop});
    }
    };

    return (
        <div className={style.el}>
            <div className={style.wrapper}>
                <div className={style.userBlock}>
                    <h2>Ciao, {username}</h2>
                    <p>
                        Completa le azioni per guadagnare punti D e aggiudicarti fantastici premi!<br></br>
                        Potrebbero essere necessari alcuni minuti prima che i punti delle tue azioni vengano aggiornati.
                        <br></br>
                        Per maggiori informazioni consulta il <a href="https://concorsi.donnad.it/brand/donnad/loyalty-2024/regolamento.pdf" target="_blank">regolamento</a>.
                    </p>
                </div>
                <div className={style.dashboard}>
                    <PointsCard  link="#loyalty-section" label="i tuoi punti D" value={points} />
                </div>
            </div>
        </div>
    );
};

export default UserDashboard;
