import React from 'react';
import ClassNames from 'classnames';
import {keys,isArray} from 'lodash';

import style from './style.module.scss';

import {Heart, Star, Like, Cloud, Smile} from '../Icons';


const ArticleReactions = ({data,loading,onReactionClick}) => {
    const btns = [
        {
            icon: Heart,
            slug: 'love',
            label: 'Emozionante'
        },{
            icon: Star,
            slug: 'brilliant',
            label: 'Che top!'
        },{
            icon: Like,
            slug: 'like',
            label: 'Mi piace'
        },{
            icon: Cloud,
            slug: 'toughtful',
            label: 'Fa riflettere!'
        },{
            icon: Smile,
            slug: 'lol',
            label: 'LOL'
        },
    ];

    const classes = ClassNames({
        [style.el] : true,
        [style.loading] : loading,
        [style.lock] : keys(data.sum).length > 0
    });

    return (
        <div className={classes}>
            <strong className={style.title}>E tu cosa ne pensi? Scegli una reaction</strong>
            <ul>
                {btns.map((btn,j) => {
                    let itemClass;
                    if(isArray(data.data) && data.data.length > 0) {
                        if (data.data[0].reaction_type === btn.slug) {
                            itemClass = style.active;
                        }
                    } else {
                        if (data.data && data.data.reaction_type === btn.slug) {
                            itemClass = style.active;
                        }
                    }
                    return <li className={itemClass} key={j} onClick={() => onReactionClick(btn.slug)}>
                            <div><btn.icon/></div>
                            {keys(data.sum).length > 0 && <span>{data.sum[btn.slug] ? `${data.sum[btn.slug]|0}%` : '0%'}</span>}
                            <strong>{btn.label}</strong>
                    </li>})}
            </ul>
        </div>
    );
}

export default ArticleReactions;

