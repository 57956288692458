import React from 'react';
import Cta from '../Cta/';
import { Locker } from '../Icons/';

import style from './style.module.scss';

const LoginWall = ({ onCtaClick }) => {
    return (
        <div className={style.el}>
            <Locker />
            <strong>
                Vuoi leggere altri articoli? <em>Registrati a DonnaD</em>!
            </strong>
            <Cta label="Accedi o Registrati" modifiers={['primary-negative', 'iconized']} onClick={onCtaClick} />
        </div>
    );
};

export default LoginWall;
