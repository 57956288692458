import React from 'react';
import classNames from 'classnames';
import style from './style.module.scss';

const Avatar = ({ image, name, type, data, onClick, user }) => {
    const classes = classNames({
        [style.el]: true,
        [style[type]]: !!type,
    });
    return (
        <div className={classes} onClick={onClick}>
            <span className={style.badge} />
            {!image ? (
                <svg viewBox="0 0 18 20">
                    <path d="M3.5 20.0001H14.5V18.0001H3.5V20.0001ZM9 11.5001C8.02697 11.5001 5.8857 11.4735 3.98042 12.0069C3.02256 12.275 2.03655 12.707 1.27858 13.4299C0.490866 14.1812 0 15.2012 0 16.5001H2C2 15.7587 2.25913 15.2585 2.65892 14.8772C3.08845 14.4675 3.72744 14.1546 4.51958 13.9328C6.1143 13.4864 7.97303 13.5001 9 13.5001V11.5001ZM9 13.5001C10.027 13.5001 11.8857 13.4864 13.4804 13.9328C14.2726 14.1546 14.9116 14.4675 15.3411 14.8772C15.7409 15.2585 16 15.7587 16 16.5001H18C18 15.2012 17.5091 14.1812 16.7214 13.4299C15.9634 12.707 14.9774 12.275 14.0196 12.0069C12.1143 11.4735 9.97303 11.5001 9 11.5001V13.5001ZM0 16.5001C0 18.4331 1.567 20.0001 3.5 20.0001V18.0001C2.67157 18.0001 2 17.3285 2 16.5001H0ZM16 16.5001C16 17.3285 15.3284 18.0001 14.5 18.0001V20.0001C16.433 20.0001 18 18.4331 18 16.5001H16ZM12 5C12 6.65685 10.6569 8 9 8V10C11.7614 10 14 7.76142 14 5H12ZM9 8C7.34315 8 6 6.65685 6 5H4C4 7.76142 6.23858 10 9 10V8ZM6 5C6 3.34315 7.34315 2 9 2V0C6.23858 0 4 2.23858 4 5H6ZM9 2C10.6569 2 12 3.34315 12 5H14C14 2.23858 11.7614 0 9 0V2Z"/>
                </svg>
            ) : (
                <div className={style.image}>
                    <img alt={name} src={image} />
                </div>
            )}
        </div>
    );
};

export default Avatar;
