import React, { useContext, useEffect} from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import AppContext from '../../contexts/appContext';
import { Errors } from './errors';
import { PointsLoading, ErrorIcon, Tick } from '../Icons';

const ScontrinoCard = ({ id, status, date, points, image, details, change_counter, receipt_number, receipt_amount, receipt_date, upload_id }) => {

    const { dispatch } = useContext(AppContext);
    let classes = classNames(style.card, style[status]);
    const products = (products) => {
        const pd = products.map((item, i) => item.brand);
        return pd.filter((v, i) => pd.indexOf(v) === i);
    };

    const editReceipt = () => {
        dispatch({ type: 'TOGGLE_RECEIPTS', payload: {id: id, status: status, date: date, points: points, image: image, details: details, receipt_number: receipt_number, receipt_amount: receipt_amount, receipt_date: receipt_date, upload_id: upload_id } });
    };

    return (
        <div className={classes}>
            <img src={`/imgs/scontrini/${status}-bg.jpg`} alt="backgroundImage" />
            <div className={style.ticket}>
                <div className={style.receipt}>
                    <img src={image} alt="scontrino" /> 
                </div>
                {/* <img src={`/imgs/scontrini/scontrino.svg`} alt="scontrino" /> */}
                {/* {points > 0 && <Tick />}
                {status === 'new' && <PointsLoading />}
                {status === 'invalid' && <ErrorIcon />} */}
            </div>
            <div className={style.content}>   
                {
                    <div>
                        {receipt_number &&<p className={style.number}>n. {receipt_number}</p>}   
                        {/* {receipt_number &&<p className={style.number}>{receipt_amount}€</p>} */}
                        {/* {receipt_number &&<p className={style.number}>{receipt_date}</p>} */}
                    </div>
                }
                {points > 0 && (
                    <p className={style.points}>
                        {points} <span>punti</span>
                    </p>
                )}
                {status === 'new' && <p className={style.label}>In elaborazione...</p>}
                {status === 'valid' && <p className={style.label}>Accettato</p>}
                {status === 'invalid' && <p className={style.label}>Non accettato</p>}
                {status === 'appealable' && <p className={style.label}>Non accettato</p>}
                <p className={style.date}>{date}</p>
                {id && (
                    <p className={style.id}>
                        Codice Operazione:
                        <br /> {id}
                    </p>
                )}

                {details &&
                    details.validation.status &&
                    details.validation.receipt.products.length > 0 && (
                        <p className={style.list}>
                            {products(details.validation.receipt.products).map((item, i) => {
                                if (i > 2) {
                                    return false;
                                }
                                return (
                                    <span key={i}>
                                        {i < 3 && i !== 0 && <span>, </span>}
                                        {item.replace('_', ' ')}
                                    </span>
                                );
                            })}
                            {products(details.validation.receipt.products).length > 3 && (
                                <span> e altri...</span>
                            )}
                        </p>
                    )}

                {details && !details.validation.status && (
                    <p className={style.list}>
                        {Errors[details.validation.reason.id] || 'Scontrino non valido'}
                    </p>
                )}
                {status === 'invalid' && change_counter <= 2 && receipt_number && receipt_amount && receipt_date && <button className={style.editbutton} onClick={editReceipt}>Modifica</button>}
                {change_counter > 2 && <p>Numero di tentativi massimi raggiunto</p>}
            </div>
        </div>
    );
};

export default ScontrinoCard;
