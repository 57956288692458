import React from 'react';
import style from './style.module.scss';
import Cta from '../Cta';

import LazyImg from '../LazyImg';

const ArticleCard = ({ title, text, tag, image, link, cta_text }) => {
    return (
        <div className={style.card}>
            <a href={link} target="_blank" rel="noopener noreferrer">
                <div className={style.image}>
                    <LazyImg src={image.src} alt={image.alt} />
                </div>
                <div className={style.content}>
                    {tag && <div className={style.tag}>{tag}</div>}
                    <h3>{title}</h3>
                    {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
                    {cta_text && (
                        <div className={style.controls}>
                            <Cta label={cta_text} />
                        </div>
                    )}
                </div>
            </a>
        </div>
    );
};

export default ArticleCard;
