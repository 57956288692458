import React from 'react';
import style from './style.module.scss';

const OfflineBanner = ({onClick, title, text, isActive}) => {
    return (
        <div className={style.wrapper}>
            <div>
                Ops! Sembra che tu sia offline, alcuni contenuti potrebbero risultare non raggiungibili.
            </div>
        </div>
    );
}

export default OfflineBanner;


