import React from 'react';

import {WhatsApp,Facebook,Pinterest,Twitter,Mail} from '../Icons';
import style from './style.module.scss';

const ShareBar = ({url,onShareClick,append}) => {

    const utmParams = source => {
        const chain = append ? '&' : '?';
        return encodeURIComponent(`${chain}utm_source=My%20DonnaD&utm_medium=PWA%20article&utm_campaign=PWA%20share%20article&utm_term=${source}`);
    }

    return (
        <div className={style.el}>
            <ul className={style.list}>
                <li onClick={() => onShareClick('whatsapp')}><a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=${url}${utmParams('whatsapp')}`}><WhatsApp/></a></li>
                <li onClick={() => onShareClick('facebook')}><a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer.php?u=${url}${utmParams('facebook')}`}><Facebook/></a></li>
                <li onClick={() => onShareClick('pinterest')}><a target="_blank" rel="noopener noreferrer" href={`https://pinterest.com/pin/create/bookmarklet/?url=${utmParams('pinterest')}`}><Pinterest/></a></li>
                <li onClick={() => onShareClick('twitter')}><a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?url=${url}${utmParams('twitter')}`}><Twitter/></a></li>
                <li onClick={() => onShareClick('email')}><a target="_blank" rel="noopener noreferrer" href={`mailto:?subject=Leggi questo articolo&body=${url}${utmParams('email')}`}><Mail/></a></li>
            </ul>
        </div>
    );
}

export default ShareBar;



