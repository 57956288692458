import React from 'react';
import style from './style.module.scss';

export const Close = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Close}>
            <svg className={style.icon_el__Close} viewBox="0 0 17 17">
                <path d="M16.428 16.195l-.079.088a.93.93 0 01-1.316.004l-6.5-6.5-6.567 6.567a.927.927 0 01-1.228.074l-.088-.079a.93.93 0 01-.005-1.316l6.568-6.567-6.501-6.5A.928.928 0 01.716.65.93.93 0 012.032.645l6.5 6.501L14.968.712a.928.928 0 011.316.004.93.93 0 01.004 1.316L9.853 8.466l6.5 6.501c.335.334.361.86.075 1.228z" />
            </svg>
        </i>
    );
};

export const ArrowRight = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__ArrowRight}>
            <svg className={style.icon_el__ArrowRight}>
                <path
                    d="M1.5 11l5-5-5-5"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </i>
    );
};

export const ArrowBack = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__ArrowBack}>
            <svg className={style.icon_el__ArrowBack} viewBox="0 0 24 24">
                <path d="M18 23.1c-.4.4-1.1.5-1.6.1l-.1-.1L6 12.9c-.4-.4-.5-1.1-.1-1.6l.1-.2L16.3.9c.5-.5 1.2-.5 1.7 0 .4.4.5 1.1.1 1.6l-.1.1L8.6 12l9.4 9.4c.4.4.4 1.1 0 1.7z" />
            </svg>
        </i>
    );
};

export const ArrowDown = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__ArrowDown}>
            <svg className={style.icon_el__ArrowDown} viewBox="0 0 11 7">
                <path
                    d="M10.614.79a.563.563 0 01.037.755l-.037.04-4.773 4.773a.563.563 0 01-.755.037l-.04-.037L.273 1.585a.563.563 0 01.755-.832l.04.037 4.375 4.375L9.82.79a.563.563 0 01.755-.037l.04.037z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Bookmark = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Bookmark}>
            <svg className={style.icon_el__Bookmark} viewBox="0 0 24 24">
                <path d="M6 19.7c-.1.1-.2.1-.2-.1V4.9c0-.9.5-1.4 1.5-1.4h9.4c1 0 1.5.5 1.5 1.4v14.7c0 .2-.1.2-.2.1l-5.5-5.1c-.2-.2-.4-.2-.5-.2-.1 0-.3 0-.5.2L6 19.7zM5.3 22c.5 0 .8-.2 1.8-1.2l4.8-4.5.1-.1s.1 0 .1.1l4.8 4.5c1 .9 1.3 1.2 1.8 1.2.6 0 1.1-.4 1.1-1.2V4.9c0-1.9-1-2.9-3.1-2.9H7.2c-2 0-3.1 1-3.1 2.9v15.9c.1.8.6 1.2 1.2 1.2z" />
            </svg>
        </i>
    );
};

export const Bookmarked = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Bookmarked}>
            <svg className={style.icon_el__Bookmarked} viewBox="0 0 24 24">
                <path d="M6.7 21.5l5.1-4.8c.1 0 .1-.1.1-.1s.1 0 .1.1l5.1 4.8c1 1 1.4 1.3 1.9 1.3.7 0 1.2-.4 1.2-1.3v-17c0-2.1-1.1-3.1-3.3-3.1h-10c-2.2 0-3.3 1-3.3 3.1v17c0 .9.5 1.3 1.2 1.3.5-.1.9-.3 1.9-1.3z" />
            </svg>
        </i>
    );
};

export const Share = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Share}>
            <svg className={style.icon_el__Share}>
                <path
                    d="M3.84577 22.5h11.30846c2.06055 0 3.09577-1.03675 3.09577-3.09045V9.4606c0-2.0537-1.03522-3.09045-3.09577-3.09045h-2.75069V7.9751h2.72101c.97606 0 1.5382.53838 1.5382 1.57513v9.7697c0 1.03676-.56214 1.57514-1.5382 1.57514h-11.259c-.98595 0-1.5283-.53838-1.5283-1.57513v-9.7697c0-1.03676.54235-1.57514 1.5283-1.57514h2.7309V6.37015H3.84578C1.78522 6.37015.75 7.4069.75 9.4606v9.94895C.75 21.46325 1.78522 22.5 3.84577 22.5m5.65908-7.80564c.42402 0 .77899-.35892.77899-.77766V3.67844l-.05917-1.4953.66047.70784 1.49861 1.61494c.13811.15945.34508.23928.53246.23928.40423 0 .71962-.2991.71962-.70784 0-.20927-.08864-.36872-.23664-.51837L10.06698.26909C9.8697.06982 9.70212 0 9.50485 0c-.20697 0-.37456.06982-.57183.26909l-3.3324 3.2499c-.14781.14965-.23645.3091-.23645.51837 0 .40874.2956.70784.70973.70784.18738 0 .40424-.07983.54235-.23928l1.49861-1.61494.66047-.70784-.05917 1.4953V13.9167c0 .41874.36487.77766.78869.77766"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Logout = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Logout}>
            <svg className={style.icon_el__Logout} viewBox="0 0 31 28">
                <path d="M16.2 0c2.3 0 4.1 1.8 4.2 4v4.7H18V4.2c0-.9-.7-1.7-1.6-1.7H4.3c-.9 0-1.7.7-1.7 1.6v19.1c0 .9.7 1.7 1.6 1.7h12.1c.9 0 1.7-.7 1.7-1.6v-4.6h2.5v4.5c0 2.3-1.8 4.1-4 4.2H4.3c-2.3 0-4.1-1.8-4.2-4V4.2C0 2 1.8.1 4 0h12.2zm9 8.2l.1.1 5 4.5c.5.5.5 1.3.1 1.8l-.1.1-5 4.5c-.5.5-1.3.4-1.8-.1-.4-.5-.4-1.2 0-1.7l.1-.1 2.6-2.3h-17c-.6 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.3-1.2h17l-2.6-2.3c-.5-.4-.5-1.1-.2-1.7l.1-.1c.4-.6 1.1-.6 1.6-.3z" />
            </svg>
        </i>
    );
};

export const Edit = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Edit}>
            <svg className={style.icon_el__Edit} viewBox="0 0 18 18">
                <g fillRule="evenodd">
                    <path d="M16.08014 2.89409l-1.2728-1.2728c-.96654-.96654-2.53362-.96654-3.50017 0L4.37373 8.55473l-.01748 4.79045 4.79045-.01748 6.93344-6.93343c.96655-.96655.96655-2.53364 0-3.50018zm-.9546.9546l.05062.05399c.38802.44189.37115 1.11522-.05061 1.537l-6.5398 6.53978-2.8746.01082.0105-2.87428 6.54011-6.54011c.43934-.43934 1.15166-.43934 1.591 0l1.27279 1.2728z" />
                    <rect x=".45" y="15.75" width="17.1" height="1.35" rx=".675" />
                </g>
            </svg>
        </i>
    );
};

export const Plus = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Plus}>
            <svg className={style.icon_el__Plus}>
                <path
                    d="M9 0c.62132 0 1.125.50021 1.125 1.12598L10.124 7.875h6.75002c.57744 0 1.05336.43056 1.1184.993L18 9c0 .62132-.50021 1.125-1.12598 1.125H10.124l.001 6.74902C10.125 17.49588 9.62565 18 9 18c-.62132 0-1.125-.50021-1.125-1.12598L7.874 10.125H1.12598C.50412 10.125 0 9.62565 0 9c0-.62132.50021-1.125 1.12598-1.125H7.874l.001-6.74902c0-.57744.43056-1.05336.993-1.1184L9 0z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Minus = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Minus}>
            <svg className={style.icon_el__Minus} viewBox="0 0 24 4">
                <path
                    d="M22.499.5c.77 0 1.404.574 1.49 1.324L24 2c0 .828-.667 1.5-1.501 1.5H1.5C.672 3.5 0 2.834 0 2 0 1.172.667.5 1.501.5H22.5z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Check = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Check}>
            <svg className={style.icon_el__Check} viewBox="0 0 14 10">
                <path
                    d="M12.293.293a1 1 0 011.498 1.32L5.707 9.708a1 1 0 01-1.32.083l-.094-.083-4-4a1 1 0 011.32-1.498l.094.083L5 7.586 12.293.293z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Heart = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Heart}>
            <svg className={style.icon_el__Heart} viewBox="0 0 24 24">
                <path
                    className={style.fill_corporate}
                    d="M12 4l-.1-.1c-2.4-1.6-5.7-1.4-7.8.6-2.4 2.4-2.5 6.2-.1 8.7l7.5 7.7c.3.3.9.3 1.2 0l7.5-7.7c2.3-2.4 2.3-6.1 0-8.5-2.3-2.2-5.7-2.5-8.2-.7zm-.7 1.6l.2.1c.3.3.8.3 1.2 0C14.5 4 17.3 4 19 5.8V6c1.5 1.7 1.5 4.3-.1 6L12 19.1 5.1 12c-1.7-1.8-1.7-4.6.1-6.3 1.7-1.6 4.3-1.6 6.1-.1z"
                />
                <path
                    className={style.fill_white}
                    d="M11.3 5.6l.2.1c.3.3.8.3 1.2 0C14.5 4 17.3 4 19 5.8V6c1.5 1.7 1.5 4.3-.1 6L12 19.1 5.1 12c-1.7-1.8-1.7-4.6.1-6.3 1.7-1.6 4.3-1.6 6.1-.1z"
                />
            </svg>
        </i>
    );
};

export const Star = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Star}>
            <svg className={style.icon_el__Star} viewBox="0 0 24 24">
                <path
                    className={style.fill_corporate}
                    d="M15.1 8l-2.5-5c-.2-.4-.6-.5-1-.3-.2.1-.3.2-.3.3L8.9 8c-.2.2-.4.4-.6.4l-5.5.8c-.4.1-.7.4-.6.9 0 .2.1.3.2.4l4 3.9c.2.2.3.4.2.7l-.9 5.5c-.1.4.2.8.6.9.2 0 .3 0 .5-.1l4.9-2.6c.2-.1.5-.1.7 0l4.9 2.6c.4.2.8.1 1-.3.1-.1.1-.3.1-.5l-.9-5.5c0-.2 0-.5.2-.7l4-3.9c.3-.3.3-.8 0-1.1-.1-.1-.3-.2-.4-.2l-5.5-.8c-.3 0-.5-.2-.7-.4zM9.7 9.7L12 5.1l2.3 4.7 5.1.7-3.7 3.6.9 5.1-4.6-2.4-4.6 2.4.9-5.1-3.7-3.6 5.1-.8z"
                />
                <path
                    className={style.fill_white}
                    d="M9.7 9.7L12 5.1l2.3 4.6 5.2.8-3.8 3.6.9 5.2-4.6-2.5-4.6 2.5.9-5.2-3.8-3.6z"
                />
            </svg>
        </i>
    );
};

export const Like = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Like}>
            <svg className={style.icon_el__Like} viewBox="0 0 24 24">
                <path
                    className={style.fill_corporate}
                    d="M15.8 18.8c1.1 0 2-.7 2.3-1.6v-.1l1.3-7.6v-.2c0-.4-.3-.7-.7-.8h-4.5V2.3L9.6 8.4H6.3v10.4h9.5zM4.7 8.4H2.3V6.8h6.5l4.1-5.6c.5-.7 1.5-.8 2.2-.3.4.3.6.7.6 1.1V6.7h2.9c1.3 0 2.3 1 2.4 2.3v.6l-1.3 7.6c-.4 1.7-1.9 2.9-3.7 3H2.3v-1.6h2.4V8.4z"
                />
                <path
                    className={style.fill_white}
                    d="M15.8 18.8c1.1 0 2-.7 2.3-1.6v-.1l1.3-7.6v-.2c0-.4-.3-.7-.7-.8h-4.5V2.3L9.6 8.4H6.3v10.4h9.5z"
                />
            </svg>
        </i>
    );
};

export const Cloud = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Cloud}>
            <svg className={style.icon_el__Cloud} viewBox="0 0 24 24">
                <path
                    className={style.fill_corporate}
                    d="M23.5 12.4c.3-.8.5-1.6.5-2.4 0-3-2.5-5.5-5.5-5.5h-.2c-.8 0-1.6.3-2.3.6l-.2-.1c-1-.8-2.2-1.3-3.6-1.3H12c-1.2 0-2.4.5-3.3 1.3l-.2.1c-.8-.4-1.7-.6-2.6-.6C2.9 4.5.4 7 .4 10v.2c0 .7.2 1.4.5 2.1-.3.7-.5 1.5-.5 2.3 0 3 2.5 5.5 5.5 5.5h.2c.8 0 1.6-.3 2.3-.6l.1.1c1 .8 2.2 1.3 3.6 1.3h.2c1.2 0 2.4-.5 3.3-1.3l.1-.1c.8.4 1.7.6 2.6.6 3 0 5.5-2.5 5.5-5.5v-.2c.2-.7 0-1.4-.3-2zm-1.6.3c.3.6.5 1.3.5 2 0 2.2-1.8 3.9-3.9 3.9-.8 0-1.6-.3-2.3-.7-.3-.2-.8-.2-1.1.1-.7.9-1.8 1.4-3 1.4s-2.2-.5-3-1.4c-.3-.3-.7-.4-1.1-.1-.7.5-1.4.7-2.3.7-2.2 0-3.9-1.8-3.9-3.9 0-.7.2-1.4.5-2 .1-.2.1-.5 0-.8-.3-.6-.5-1.3-.5-2C1.9 7.8 3.7 6 5.9 6c.8 0 1.6.3 2.3.7.3.3.7.2 1-.1.7-.9 1.8-1.4 3-1.4s2.2.5 3 1.4c.3.3.7.4 1.1.1.6-.4 1.4-.7 2.2-.7 2.2 0 3.9 1.8 3.9 3.9 0 .7-.2 1.4-.5 2-.1.3-.1.6 0 .8z"
                />
                <path
                    className={style.fill_white}
                    d="M12.2 5.2c1.2 0 2.2.5 3 1.4.3.3.7.4 1.1.1.6-.4 1.4-.7 2.2-.7 2.2 0 3.9 1.8 3.9 3.9 0 .7-.2 1.4-.5 2-.1.2-.1.5 0 .8.3.6.5 1.3.5 2 0 2.2-1.8 3.9-3.9 3.9-.8 0-1.6-.3-2.3-.7-.3-.2-.8-.2-1.1.1-.7.9-1.8 1.4-3 1.4s-2.2-.5-3-1.4c-.3-.3-.7-.4-1.1-.1-.7.5-1.4.7-2.3.7-2.2 0-3.9-1.8-3.9-3.9 0-.7.2-1.4.5-2 .1-.2.1-.5 0-.8-.3-.6-.5-1.3-.5-2C1.9 7.8 3.7 6 5.9 6c.8 0 1.6.3 2.3.7.3.3.7.2 1-.1.7-.9 1.8-1.4 3-1.4z"
                />
                <path
                    className={style.fill_corporate}
                    d="M8.2 11.2c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2S7 13.1 7 12.4c.1-.7.6-1.2 1.2-1.2z"
                />
                <path
                    className={style.fill_corporate}
                    d="M12.2 11.2c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2.5-1.2 1.2-1.2z"
                />
                <path
                    className={style.fill_corporate}
                    d="M16.1 11.2c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2.6-1.2 1.2-1.2z"
                />
            </svg>
        </i>
    );
};

export const Smile = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Smile}>
            <svg className={style.icon_el__Smile} viewBox="0 0 24 24">
                <path
                    className={style.fill_corporate}
                    d="M23.1 9.1H.9c-.5 0-.9.4-.9.9 0 6.6 5.4 12 12 12s12-5.4 12-12c0-.5-.4-.9-.9-.9zm-.9 1.8v.1c-.5 5.2-4.9 9.2-10.2 9.2h-.3c-5.2-.1-9.4-4.1-9.9-9.2v-.1h20.4zM7.9 4.2c.3.2.6.5.8.8.3.4.2 1-.2 1.3-.4.2-.9.1-1.2-.2V6c-.1-.1-.2-.2-.4-.3-.5-.4-1.2-.3-1.6.2l-.1.1c-.3.4-.8.5-1.2.3-.5-.3-.6-.9-.3-1.3.9-1.4 2.8-1.7 4.2-.8zm8.2 0c1.4-.9 3.3-.6 4.2.8.3.4.2 1-.2 1.2s-1 .2-1.2-.2l-.1-.1c-.4-.5-1.1-.6-1.7-.2-.1.1-.3.2-.3.3l-.1.1c-.3.3-.8.4-1.2.2-.4-.3-.5-.9-.2-1.3.2-.3.5-.6.8-.8z"
                />
                <path
                    className={style.fill_white}
                    d="M22.2 10.9v.1c-.5 5.2-4.9 9.2-10.2 9.2h-.3c-5.2-.1-9.4-4.1-9.9-9.2v-.1h20.4z"
                />
            </svg>
        </i>
    );
};

export const PointsLoading = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__PointsLoading}>
            <svg className={style.icon_el__PointsLoading} viewBox="0 0 35 8">
                <g fillRule="evenodd">
                    <circle cx="4" cy="4" r="4" />
                    <circle cx="17" cy="4" r="4" />
                    <circle cx="31" cy="4" r="4" />
                </g>
            </svg>
        </i>
    );
};

export const ErrorIcon = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__ErrorIcon}>
            <svg className={style.icon_el__ErrorIcon} viewBox="0 0 20 20">
                <path
                    d="M17.367 20c-.79 0-1.316-.266-1.842-.79l-5.526-5.526-5.526 5.527c-1.052 1.052-2.631 1.052-3.684 0-1.052-1.053-1.052-2.632 0-3.685L6.315 10 .79 4.474C-.263 3.42-.263 1.842.79.789 1.842-.263 3.421-.263 4.473.79L10 6.316 15.525.789c1.052-1.052 2.631-1.052 3.684 0 1.055 1.053 1.055 2.632 0 3.685L13.685 10l5.524 5.526c1.055 1.053 1.055 2.632 0 3.685-.527.523-1.053.789-1.842.789"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Tick = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Tick}>
            <svg className={style.icon_el__Tick} viewBox="0 0 24 18">
                <path
                    d="M20.811 1a2.107 2.107 0 00-1.462.656c-3.321 3.395-6.646 6.777-9.972 10.172l-4.886-4.16a2.1 2.1 0 00-2.998.278 2.2 2.2 0 00.272 3.057l6.382 5.425c.846.72 2.092.66 2.87-.136 3.778-3.862 7.56-7.704 11.346-11.573.838-.84.851-2.213.028-3.069a2.107 2.107 0 00-1.58-.65"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Facebook = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Facebook}>
            <svg className={style.icon_el__Facebook} role="img" viewBox="0 0 24 24">
                <title>Facebook icon</title>
                <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
            </svg>
        </i>
    );
};

export const Twitter = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Twitter}>
            <svg className={style.icon_el__Twitter} role="img" viewBox="0 0 24 24">
                <title>Twitter icon</title>
                <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
            </svg>
        </i>
    );
};

export const Pinterest = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Pinterest}>
            <svg className={style.icon_el__Pinterest} role="img" viewBox="0 0 24 24">
                <title>Pinterest icon</title>
                <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" />
            </svg>
        </i>
    );
};

export const WhatsApp = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__WhatsApp}>
            <svg className={style.icon_el__WhatsApp} role="img" viewBox="0 0 24 24">
                <title>WhatsApp icon</title>
                <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
            </svg>
        </i>
    );
};

export const Mail = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Mail}>
            <svg className={style.icon_el__Mail} viewBox="0 0 25 25">
                <path d="M1.9 3.377c-.9 0-1.6.9-1.6 2.1v14c0 1.2.7 2.1 1.7 2.1h21.1c.9 0 1.7-.9 1.7-2.1v-14c0-1.2-.7-2.1-1.7-2.1H1.9zm1.7 3.2c.2 0 .3.1.4.2l7.7 8.9c.5.6 1.2.6 1.7 0l7.6-9c.2-.3.6-.2.8 0s.2.7 0 1l-4.3 4.9 4.3 4.6c.2.1.3.4.2.7s-.2.5-.4.6c-.2.1-.4 0-.6-.2l-4.4-4.7-2.6 3c-.9 1-2.3 1-3.2 0l-2.6-3-4.2 4.7c-.1.2-.4.3-.6.2-.2-.1-.4-.3-.4-.6s.1-.5.2-.7l4.2-4.5-4.2-4.9c-.1-.2-.2-.5-.1-.8.1-.2.3-.4.5-.4z" />
            </svg>
        </i>
    );
};

export const Locker = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Locker}>
            <svg className={style.icon_el__Locker} viewBox="0 0 24 24">
                <path d="M14.9 5.3c0-1.6-1.3-2.9-2.9-2.9-1.6 0-2.8 1.2-2.9 2.8v2.1H7.4v-2c0-2.5 2-4.6 4.6-4.6 2.5 0 4.5 2 4.6 4.4v2.2h1.1c2 0 3 1 3.1 2.9v10.1c0 2-1 3-2.9 3.1H6.4c-2 0-3-1-3.1-2.9V10.3c0-2 1-3 2.9-3.1h8.7V5.3zm2.7 3.5H6.4c-.9 0-1.5.5-1.5 1.4V20c0 1 .5 1.5 1.4 1.6H17.6c.9 0 1.5-.5 1.5-1.4V10.4c0-1-.5-1.5-1.4-1.6h-.1zM12 14.9c.7 0 1.3.5 1.4 1.2l.5 3.7h-3.7l.5-3.7c0-.7.6-1.2 1.3-1.2z" />
            </svg>
        </i>
    );
};

export const Instagram = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Instagram}>
            <svg className={style.icon_el__Instagram} viewBox="0 0 30 30">
                <path
                    d="M15.682-.001c3.455.002 3.996.021 5.502.09 1.597.073 2.687.326 3.641.697a7.353 7.353 0 012.657 1.73 7.354 7.354 0 011.73 2.657c.371.955.625 2.045.698 3.642.064 1.411.085 1.975.09 4.887v2.595c-.005 2.912-.026 3.475-.09 4.887-.073 1.597-.327 2.687-.697 3.641a7.354 7.354 0 01-1.73 2.657 7.354 7.354 0 01-2.658 1.73c-.954.371-2.044.625-3.64.698-1.413.064-1.976.085-4.888.09h-2.595c-2.912-.005-3.476-.026-4.887-.09-1.597-.073-2.687-.327-3.642-.697a7.354 7.354 0 01-2.657-1.73 7.353 7.353 0 01-1.73-2.658c-.37-.954-.624-2.044-.697-3.64-.069-1.507-.088-2.048-.09-5.503v-1.365C0 10.862.02 10.32.089 8.815c.073-1.597.326-2.687.697-3.642a7.353 7.353 0 011.73-2.657A7.353 7.353 0 015.173.786C6.128.416 7.218.162 8.815.09c1.506-.069 2.047-.088 5.502-.09zm.326 2.703H13.99c-3.092.003-3.615.021-5.053.087-1.463.067-2.257.311-2.786.517-.7.272-1.2.597-1.724 1.122a4.647 4.647 0 00-1.122 1.724c-.206.529-.45 1.323-.517 2.786-.066 1.438-.084 1.96-.087 5.053v2.017c.003 3.092.021 3.615.087 5.053.067 1.462.311 2.257.517 2.785.272.7.597 1.2 1.122 1.725.525.525 1.024.85 1.724 1.122.529.206 1.323.45 2.786.517 1.486.068 1.994.085 5.371.087h1.38c3.378-.002 3.886-.02 5.372-.087 1.462-.067 2.257-.311 2.785-.517.7-.272 1.2-.597 1.725-1.122a4.647 4.647 0 001.122-1.725c.206-.528.45-1.323.517-2.785.068-1.486.085-1.995.087-5.371v-1.381c-.002-3.377-.02-3.885-.087-5.371-.067-1.463-.311-2.257-.517-2.786-.272-.7-.597-1.2-1.122-1.724a4.647 4.647 0 00-1.725-1.122c-.528-.206-1.323-.45-2.785-.517-1.438-.066-1.96-.084-5.053-.087zm-1.009 4.594a7.703 7.703 0 110 15.406 7.703 7.703 0 010-15.406zM15 10a5 5 0 100 10 5 5 0 000-10zm8.008-4.807a1.8 1.8 0 110 3.6 1.8 1.8 0 010-3.6z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const YT = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__YT}>
            <svg className={style.icon_el__YT} viewBox="-390 394 18 12.3">
                <path d="M-372 403.3V397s0-3-3.1-3h-11.8s-3.1 0-3.1 3v6.3s0 3 3.1 3h11.8s3.1 0 3.1-3m-5.5-3.1l-5.9 3.4v-6.7l5.9 3.3" />
            </svg>
        </i>
    );
};

export const Telephone = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Telephone}>
            <svg className={style.icon_el__Telephone} viewBox="0 0 17 28">
                <path d="M3.425.304C1.538.304 0 1.804 0 3.644V23.69c0 1.84 1.538 3.34 3.425 3.34h9.965c1.887 0 3.425-1.5 3.425-3.34V3.644c0-1.84-1.538-3.34-3.425-3.34H3.425zm0 1.215h9.965c1.112 0 2.012.781 2.16 1.822H1.265c.148-1.04 1.048-1.822 2.16-1.822zm-2.18 3.037H15.57v17.007H1.246V4.556zm0 18.222H15.57v.91c0 1.19-.962 2.127-2.18 2.127H3.425c-1.218 0-2.18-.938-2.18-2.126v-.911zm7.163.607a.923.923 0 00-.935.911c0 .503.419.911.935.911a.923.923 0 00.934-.91.923.923 0 00-.934-.912z" />
            </svg>
        </i>
    );
};

export const Email = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Email}>
            <svg className={style.icon_el__Email} viewBox="-385 391.1 28.8 19.1">
                <path d="M-356.6 392.6c-.2-.4-.5-.7-.9-1-.5-.4-1.2-.6-1.9-.6H-382c-.7 0-1.3.2-1.9.6-.4.3-.6.6-.9 1-.3.4-.4.9-.4 1.5V407c0 1.7 1.4 3.1 3.1 3.1h22.6c1.7 0 3.1-1.4 3.1-3.1v-12.9c.2-.5.1-1-.2-1.5zm-25.3-.3h22.6c.3 0 .5.1.8.2l-11.4 8.7c-.2.2-.5.3-.7.3-.3 0-.5-.1-.7-.3l-11.4-8.7c.2-.1.5-.2.8-.2zm24.5 14.8c0 1-.8 1.8-1.8 1.8h-22.6c-1 0-1.8-.8-1.8-1.8v-12.9c0-.2.1-.5.1-.7l11.5 8.8c.4.3 1 .5 1.5.5s1.1-.2 1.5-.5l11.5-8.8c.1.2.1.4.1.7v12.9z" />
            </svg>
        </i>
    );
};

export const Bell = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Bell}>
            <svg className={style.icon_el__Bell} viewBox="0 0 32 36">
                <path
                    d="M13.446 30c0 1.57.54 3.57 3.054 3.57 2.515 0 3.037-2 3.037-3.57H22c0 3.313-2.462 6-5.5 6S11 33.313 11 30h2.446zm-.53-23.728c-4.143 1.292-7.009 5.056-7.009 9.362v5.609c0 1.575-.313 2.855-1.929 2.855-.54 0-1.978.336-1.978 1.95C2 27.665 3.439 28 3.983 28h24.034c.542 0 1.983-.473 1.983-1.951 0-1.478-1.44-1.951-1.978-1.951-1.619 0-1.929-1.276-1.929-2.855v-5.61c0-4.303-2.866-8.069-7.008-9.361l-1.132-.354V3.897C17.953 2.85 17.078 2 16 2c-1.077 0-1.953.852-1.953 1.897v2.021l-1.132.354zm7.909-1.793c4.513 1.86 7.552 6.198 7.552 11.109v6.776c2.024.151 3.623 1.801 3.623 3.812C32 28.287 30.239 30 28.07 30H3.93C1.76 30 0 28.29 0 26.176c0-2.01 1.597-3.661 3.623-3.812v-6.776c0-4.912 3.039-9.25 7.552-11.109C11.295 1.986 13.412 0 16 0c2.59 0 4.706 1.987 4.825 4.48z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const StarCheck = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__StarCheck}>
            <svg className={style.icon_el__StarCheck} viewBox="0 0 30 30">
                <path
                    className={style.fill__green}
                    d="m28.9 11.1-8.8-1.8-4.4-7.8c-.1-.1-.2-.2-.3-.3-.4-.2-.9-.1-1.1.3l-4.4 7.8-8.8 1.8c-.1 0-.3.1-.4.2-.3.3-.4.8-.1 1.1l6.1 6.6-1 8.9c0 .1 0 .3.1.4.2.4.7.6 1.1.4l8.2-3.7 8.2 3.7c.1.1.3.1.4.1.4 0 .8-.4.7-.9l-1-8.9 6.1-6.6c.1-.1.2-.2.2-.4 0-.4-.3-.8-.8-.9zm-10.3 3.5-4.3 4.3c-.2.2-.5.2-.7 0h-.1l-2.1-2.1c-.2-.2-.2-.5 0-.8.2-.2.5-.2.7 0h.1l1.8 1.8 3.9-3.9c.2-.2.5-.2.8 0 .1.1.1.5-.1.7z"
                />
            </svg>
        </i>
    );
};

export const StarLock = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__StarLock}>
            <svg className={style.icon_el__StarLock} viewBox="0 0 30 30">
                <path
                    className={style.fill__mint}
                    d="m15 25-8 3.6c-.4.2-.9 0-1-.4-.1-.1-.1-.3-.1-.4l1-8.8-6-6.5c-.3-.3-.3-.8.1-1.1.1-.1.2-.2.4-.2l8.6-1.8 4.4-7.7c.2-.4.7-.5 1.1-.3.1.1.2.2.3.3l4.4 7.7 8.6 1.8c.4.1.7.5.6.9 0 .1-.1.3-.2.4l-6 6.5 1 8.8c0 .4-.3.8-.7.9-.1 0-.3 0-.4-.1z"
                />
                <path
                    className={style.fill__grey_green}
                    d="m-4.3-8.2c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v.1.8h-.7v-.8c0-1.1.9-1.9 1.9-1.9s1.9.8 1.9 1.9v.1.8h.4c.8 0 1.3.4 1.3 1.2v.1 4.2c0 .8-.4 1.3-1.2 1.3h-.1-4.8c-.8 0-1.3-.4-1.3-1.2v-.1-4.2c0-.8.4-1.3 1.2-1.3h.1 3.6v-1zm1.1 1.5h-4.8c-.4 0-.6.2-.6.6v.1 4.1c0 .4.2.6.6.7h4.8c.4 0 .6-.2.6-.6v-.1-4.1c0-.5-.2-.7-.6-.7zm-2.4 2.6c.3 0 .6.2.6.5l.2 1.6h-1.6l.2-1.6c.1-.3.3-.5.6-.5z"
                    transform="translate(20.575221 20.358407)"
                />
            </svg>
        </i>
    );
};

export const DropDownArrow = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__DropDownArrow}>
            <svg className={style.icon_el__DropDownArrow} viewBox="0 0 17 17">
                <path d="M8.5 16.6C4.1 16.6.4 13 .4 8.5.4 4.1 4 .4 8.5.4c4.4 0 8.1 3.6 8.1 8.1 0 4.4-3.7 8.1-8.1 8.1zm0-15c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9z" />
                <path d="M8.5 11.7c-.1 0-.3-.1-.4-.2L4.4 7.8c-.3-.2-.3-.6 0-.8.2-.2.6-.2.8 0l3.4 3.4L11.9 7c.2-.2.6-.2.8 0 .2.2.2.6 0 .8L9 11.5c-.2.1-.4.2-.5.2z" />
            </svg>
        </i>
    );
};

export const Info = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Info}>
            <svg className={style.icon_el__Info} viewBox="0 0 22 22">
                <path
                    d="M11 .25C16.937.25 21.75 5.063 21.75 11S16.937 21.75 11 21.75.25 16.937.25 11 5.063.25 11 .25zm0 1.5a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm0 7.578a.75.75 0 01.743.648l.007.102v6.966a.75.75 0 01-1.493.102l-.007-.102v-6.966a.75.75 0 01.75-.75zm0-4a.75.75 0 01.743.648l.007.102v.973a.75.75 0 01-1.493.102l-.007-.102v-.973a.75.75 0 01.75-.75z"
                    fillRule="evenodd"
                />
            </svg>
        </i>
    );
};

export const Push = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Push}>
            <svg className={style.icon_el__Push} viewBox="0 0 28 28">
                <circle className={style.fill_corporate} cx="22" cy="6" r="6" />
                <path d="M12.5 28C2.69 28 0 25.31 0 15.5S2.69 3 12.5 3h1.91a.5.5 0 110 1h-1.87C3.26 4 1 6.26 1 15.5S3.26 27 12.5 27 24 24.74 24 15.5v-1.87a.5.5 0 01.48-.52.51.51 0 01.52.48v1.91C25 25.31 22.31 28 12.5 28z" />
            </svg>
        </i>
    );
};

export const Gear = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Gear}>
            <svg className={style.icon_el__Gear} viewBox="0 0 59.1 59.1">
                <path d="M55.5 23.9l-4.2-.3c-.5-1.8-1.2-3.5-2.2-5.2l2.8-3.2c1.3-1.5 1.1-3.8-.3-5.2L49 7.4c-1.4-1.4-3.7-1.5-5.2-.2L40.5 10c-1.6-1-3.4-1.7-5.2-2.2L35 3.6c0-2-1.7-3.6-3.7-3.6h-3.7c-2 0-3.7 1.6-3.8 3.6l-.3 4.2C21.7 8.3 20 9 18.4 10l-3.2-2.8c-1.6-1.3-3.9-1.2-5.3.2L7.3 10c-1.4 1.4-1.5 3.7-.1 5.2l2.8 3.2c-1 1.6-1.7 3.4-2.2 5.2l-4.2.3C1.6 24 0 25.7 0 27.7v3.7c0 2 1.5 3.7 3.5 3.9l4.2.3c.5 1.8 1.2 3.5 2.2 5.2L7.1 44c-1.3 1.5-1.2 3.8.2 5.1l2.6 2.6c1.4 1.4 3.7 1.5 5.2.2l3.2-2.8c1.6 1 3.4 1.7 5.2 2.2l.3 4.2c.1 2 1.8 3.6 3.8 3.6h3.7c2 0 3.7-1.5 3.9-3.6l.3-4.2c1.8-.5 3.5-1.2 5.2-2.2l3.2 2.8c.7.6 1.6.9 2.5.9.9 0 1.9-.4 2.7-1.1l2.6-2.6c1.5-1.3 1.6-3.6.2-5.2l-2.8-3.2c1-1.6 1.7-3.4 2.2-5.2l4.2-.3c2-.1 3.6-1.8 3.6-3.8v-3.7c0-2-1.6-3.7-3.6-3.8zm-6.9 8.8l-.3 1.1C47.8 36 47 38 45.7 40l-.6.9 4.2 4.9c.2.3.3.8-.1 1.1l-2.7 2.7c-.1.1-.1.2-.5.2-.3 0-.4-.1-.6-.2l-4.9-4.3-.8.7c-2 1.3-4 2.2-6.3 2.6l-1 .3-.4 6.5c0 .4-.4.7-.8.7h-3.7c-.4 0-.8-.3-.8-.7l-.4-6.5-1.1-.3C23 48.1 21 47.3 19 46l-.9-.6-4.9 4.2c-.3.2-.8.3-1.1-.1l-2.6-2.6c-.2-.2-.3-.4-.3-.6 0-.1 0-.3.2-.5l4.3-4.9-.7-.9c-1.3-2-2.2-4-2.6-6.3l-.3-1-6.5-.4c-.4 0-.7-.4-.7-.8v-3.7c0-.4.3-.8.7-.8l6.5-.4.3-1.1c.5-2.2 1.3-4.3 2.6-6.2l.6-.9-4.2-4.9c-.2-.3-.3-.8.1-1.1l2.6-2.6c.3-.3.8-.3 1.1-.1l4.9 4.3.9-.6c2-1.3 4-2.1 6.3-2.6l1-.3.4-6.5c0-.4.4-.7.8-.7h3.7c.4 0 .8.3.8.7l.4 6.5 1.1.3c2.2.5 4.2 1.3 6.2 2.6l.9.6 4.9-4.2c.3-.2.8-.3 1.1.1l2.6 2.6c.3.3.4.8.1 1.1L45 18.5l.6.9c1.3 2 2.1 4 2.6 6.3l.3 1 6.5.4c.4 0 .7.4.7.8v3.7c0 .4-.3.8-.7.8l-6.4.3z" />
                <path d="M29.5 20.1c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5zm0 16c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5S36 26 36 29.6s-2.9 6.5-6.5 6.5z" />
            </svg>
        </i>
    );
};

export const Friend = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Friend}>
            <svg className={style.icon_el__Friend} viewBox="0 0 122.88 91.99">
            <path d="M45.13,35.29h-0.04c-7.01-0.79-16.42,0.01-20.78,0C17.04,35.6,9.47,41.91,5.02,51.3 c-2.61,5.51-3.3,9.66-3.73,15.55C0.42,72.79-0.03,78.67,0,84.47c1.43,9.03,12.88,6.35,13.85,0l1.39-18.2 c0.21-2.75,0.4-4.61,1.51-7.23c0.52-1.23,1.15-2.28,1.89-3.15l0.69,33.25l-0.39,2.78h31.49l-0.42-3.1l0.61-36.67 c3.2-1.29,5.96-1.89,8.39-1.99c-0.12,0.25-0.25,0.5-0.37,0.75c-2.61,5.51-3.3,9.66-3.73,15.55c-0.86,5.93-1.32,11.81-1.29,17.61 c1.43,9.03,12.88,6.35,13.85,0l1.39-18.2c0.21-2.75,0.4-4.61,1.51-7.23c0.52-1.23,1.15-2.28,1.89-3.15l0.69,33.25l-0.46,3.24h31.62 l-0.48-3.55l0.49-28.62v0.56l0.1-4.87c0.74,0.87,1.36,1.92,1.89,3.15c1.12,2.62,1.3,4.48,1.51,7.23l1.39,18.2 c1.34,8.68,13.85,8.85,13.85,0c0.03-5.81-0.42-11.68-1.29-17.61c-0.43-5.89-1.12-10.04-3.73-15.55 c-4.57-9.65-10.48-14.76-19.45-15.81c-5.53-0.45-14.82,0.06-20.36-0.1c-1.38,0.19-2.74,0.47-4.06,0.87 c-3.45-0.48-8.01-1.07-12.56-1.09C54.76,34.77,48.15,35.91,45.13,35.29L45.13,35.29z M88.3,0c9.01,0,16.32,7.31,16.32,16.32 c0,9.01-7.31,16.32-16.32,16.32c-9.01,0-16.32-7.31-16.32-16.32C71.98,7.31,79.29,0,88.3,0L88.3,0z M34.56,0 c9.01,0,16.32,7.31,16.32,16.32c0,9.01-7.31,16.32-16.32,16.32s-16.32-7.31-16.32-16.32C18.24,7.31,25.55,0,34.56,0L34.56,0z"/>
            </svg>
        </i>
    );
};

export const Faq = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Faq}>
            <img src="\imgs\app_icons\faq-icon.svg" alt="FaqIcon" />
        </i>
    );
};

export const List = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__List}>
            <svg className={style.icon_el__List} viewBox="0 0 24 24">
                <path d="M12.9 21.8l2.6-2.1 2 1.6c.2.2.6.3.9.3.8 0 1.4-.6 1.4-1.4V1.9H4.3v18.4c0 .3.1.6.3.9l.1.1c.5.5 1.3.6 1.9.1l2-1.6 2.6 2.1c.4.3 1.2.3 1.7-.1zm-.9-1.4l-2.6-2.1-.1-.1c-.5-.3-1.2-.3-1.6.1l-1.8 1.4V3.5H18v16.2l-1.8-1.4c-.5-.4-1.2-.4-1.7 0L12 20.4z" />
                <path d="M12.8 14.1c.4 0 .8.3.8.7v.1c0 .5-.4.8-.8.8H8.4c-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8h4.4zm2.8-4.1c.4 0 .8.3.8.7v.1c0 .5-.4.8-.8.8H8.4c-.5 0-.8-.4-.8-.8s.4-.8.8-.8h7.2zm0-4.1c.4 0 .8.3.8.7v.1c0 .5-.4.8-.8.8H8.4c-.4 0-.8-.4-.8-.8 0-.5.4-.8.8-.8h7.2z" />
            </svg>
        </i>
    );
};

export const Home = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Home}>
            <svg className={style.icon_el__Home} viewBox="0 0 24 24">
                <path
                    className={style.fill__corporate}
                    d="M11.4 2.1c.3-.3.8-.3 1.2 0l7.2 5.8c.8.7 1.3 1.6 1.3 2.7v8.2c0 1.9-1.5 3.4-3.4 3.4H6.4c-1.9 0-3.4-1.5-3.4-3.4v-8.2c0-1 .5-2 1.3-2.7l7.1-5.8zM12 4L5.4 9.4c-.4.3-.6.7-.6 1.2v8.2c0 .9.7 1.6 1.6 1.6h11.2c.9 0 1.6-.7 1.6-1.6v-8.2c0-.5-.2-.9-.6-1.2L12 4zm0 9.8c.7 0 1.2.6 1.2 1.2v3.8h-2.5V15c.1-.7.6-1.2 1.3-1.2z"
                />
            </svg>
        </i>
    );
};

export const Present = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Present}>
            <svg className={style.icon_el__Present} viewBox="0 0 40 40">
                <path d="M34.7 13.8c-.6-.6-1.3-.9-2.2-.9h-1.8c.5-.8.7-1.7.7-2.6 0-1.3-.5-2.5-1.4-3.5-.9-1-2-1.5-3.3-1.5-1.2 0-2.4.4-3.4 1.3-.8.8-1.9 2.1-3.1 4.1-1.2-2-2.2-3.3-3.1-4.1-1-.9-2.1-1.3-3.4-1.3-1.3 0-2.4.5-3.3 1.5-.9 1-1.4 2.1-1.4 3.5 0 .9.2 1.8.7 2.6H8.1c-.8 0-1.6.3-2.2.9S5 15.2 5 16v5.6c0 .3.1.6.3.9s.5.3.9.3h.7V31c0 .8.3 1.6.9 2.2s1.3.9 2.2.9h20.6c.8 0 1.6-.3 2.2-.9s.9-1.3.9-2.2v-8.2h.7c.3 0 .6-.1.9-.3s.3-.5.3-.9V16c-.1-.8-.4-1.6-.9-2.2zm-18.4 9v8.9H10c-.2 0-.4-.1-.5-.2s-.2-.3-.2-.5v-8.2h7zm2.5-9.9h-5c-.6 0-1.1-.2-1.5-.7-.5-.5-.7-1.1-.7-1.8s.2-1.3.7-1.8c.4-.5 1-.7 1.6-.7.5 0 .9.1 1.2.3.4.3 1 .8 1.5 1.5.6.6 1.3 1.8 2.2 3.2zM7.4 16c0-.2.1-.4.2-.5s.3-.2.5-.2h8.2v5.1H7.4V16zm14.5-.7v16.4h-3.2V15.3h3.2zm11.3.7v4.4h-8.9v-5.1h8.2c.2 0 .4.1.5.2s.2.3.2.5zm-4.9-3.8c-.4.5-1 .7-1.6.7h-4.9c.9-1.5 1.6-2.6 2.2-3.3.6-.8 1.1-1.3 1.5-1.5.3-.2.7-.3 1.2-.3.6 0 1.1.2 1.5.7.5.5.7 1.1.7 1.8s-.2 1.4-.6 1.9zm3 10.6V31c0 .2-.1.4-.2.5s-.3.2-.5.2h-6.3v-8.9h7z" />
            </svg>
        </i>
    );
};

export const Tachometer = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Tachometer}>
            <svg className={style.icon_el__tachometer} viewBox="0 0 40 40">
                <path d="M19.6 4C10.5 4 3 11.5 3 20.6c0 9.2 7.5 16.6 16.6 16.6 9.2 0 16.6-7.5 16.6-16.6C36.3 11.5 28.8 4 19.6 4zm0 30.5c-7.6 0-13.8-6.2-13.8-13.8C5.8 13 12 6.8 19.6 6.8c7.6 0 13.8 6.2 13.8 13.8.1 7.7-6.1 13.9-13.8 13.9z" />
                <path d="M22.2 16.3l-1.9 1.9c-.5-.2-1-.2-1.5-.1-.7.2-1.3.6-1.7 1.2-.8 1.3-.5 3 .8 3.8.5.3 1 .4 1.5.4.9 0 1.8-.4 2.3-1.2.5-.7.6-1.6.3-2.5l1.9-1.9c.2-.2.3-.5.3-.8 0-.3-.1-.6-.4-.8-.5-.4-1.2-.4-1.6 0zM19.8 21c-.2.2-.5.2-.7.1-.2-.2-.2-.5 0-.7.1-.1.2-.1.3-.1.1 0 .2 0 .3.1.2.1.2.4.1.6zM19.6 11.6c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4zM19.6 29.8c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.7-.6-1.4-1.4-1.4zM27.2 12.3c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.7-1.4-1.4-1.4zM26.9 27c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3zM30 19.4c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4c.8 0 1.4-.6 1.4-1.4s-.7-1.4-1.4-1.4zM12.3 14.9c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4zM12.4 26.8c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3.1-.7-.5-1.3-1.3-1.3zM9.2 19.4c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4c.8 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4z" />
            </svg>
        </i>
    );
};

export const CheckBox = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__CheckBox}>
            <svg viewBox="0 0 100 100">
                <path d="m67.51 31.48-20.73 20.72z"/>
                <path d="m24.51 40.65c-2.96 2.96-2.96 7.75 0 10.71l16.91 16.91c2.96 2.96 7.75 2.96 10.71 0l41.45-41.45c2.96-2.96 2.96-7.75 0-10.71-2.96-2.96-7.75-2.96-10.71 0l-15.37 15.37-20.73 20.73s0 0 0 0l-11.56-11.56c-2.96-2.96-7.75-2.96-10.71 0z"/>
                <path d="m74.9 52.58v23.62c0 2.41-1.95 4.36-4.36 4.36h-52.4c-2.41 0-4.36-1.95-4.36-4.36v-52.4c0-2.41 1.95-4.36 4.36-4.36h54.34c1.16 0 2.27-.46 3.08-1.28 2.75-2.75.8-7.44-3.08-7.44h-63.06c-2.41 0-4.36 1.95-4.36 4.36v69.84c0 2.41 1.95 4.36 4.36 4.36h69.84c2.41 0 4.36-1.95 4.36-4.36v-32.34c0-3.88-4.7-5.83-7.44-3.08-.82.82-1.28 1.93-1.28 3.08z"/>
            </svg>
        </i>
    );
};

export const Ticket = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Ticket}>
            <svg
                className={style.icon_el__Ticket + ' ' + style.fill__corporate}
                viewBox="0 0 40 40"
            >
                <path d="M36 9.5H4c-.8 0-1.5.7-1.5 1.5v4.1c0 .7.5 1.4 1.3 1.5 2.6.4 3.7 1.6 3.7 3.9s-1.1 3.4-3.7 3.8c-.7.1-1.3.7-1.3 1.5V30c0 .8.7 1.5 1.5 1.5h32c.8 0 1.5-.7 1.5-1.5V11c0-.8-.7-1.5-1.5-1.5zm-1.5 19H16.8v-1c0-.7-.6-1.2-1.2-1.2s-1.2.6-1.2 1.2v1H5.5V27c3.2-.9 5-3.2 5-6.5 0-3.4-1.8-5.7-5-6.6v-1.4h8.8v2c0 .7.6 1.2 1.2 1.2s1.2-.6 1.2-1.2v-2h17.8v16z" />
                <path d="M25 25.2c.4 0 .7-.1 1-.4l6-7c.2-.2.3-.5.3-.8 0-.4-.1-.7-.4-.9-.5-.4-1.3-.4-1.8.1l-6 7c-.2.2-.3.5-.3.8 0 .4.1.7.4.9.2.3.5.3.8.3z" />
                <circle cx="25.5" cy="17.5" r="1.5" />
                <circle cx="30.5" cy="23.5" r="1.5" />
                <path d="M15.5 16.8c-.7 0-1.2.6-1.2 1.2v5c0 .7.6 1.2 1.2 1.2s1.2-.6 1.2-1.2v-5c.1-.7-.5-1.2-1.2-1.2z" />
            </svg>
        </i>
    );
};

export const Megaphone = () => {
    return (
        <i className={style.icon + ' ' + style.icon_wrapper__Megaphone}>
            <svg className={style.icon_el__Megaphone} viewBox="0 0 40 40">
                <path d="m35.6 20.1-3-3.5c.7-1.2 1.1-2.4 1.1-3.7 0-3.9-3.1-7-7-7-1 0-1.9.2-2.8.5l-2.6-3.2c-.3-.4-.8-.6-1.4-.5-.5.1-1 .3-1.3.8C13.1 14.1 8.9 20.8 6.1 23c-2.9 2.4-2.6 5.8-.8 7.9C6.4 32.2 7.9 33 9.6 33c1.3 0 2.5-.4 3.7-1.4.1-.1.3-.2.4-.3l4.4 5.4c.3.4.7.5 1.3.5.3 0 .7-.1 1.1-.3.4-.3.5-.7.5-1.3 0-.3-.1-.7-.3-1.1l-4.1-5c3.7-1.9 9.8-4.3 18.3-7.1.5-.2.8-.5 1.1-1.1.1-.3 0-.8-.4-1.2zM26.8 9c2.1 0 3.8 1.7 3.8 3.8 0 .3-.1.7-.2 1.1L26.3 9h.5zM11.3 29.3c-1.6 1.3-3 .4-3.6-.2-.2-.3-1.5-1.9.4-3.5l.4-.4 3.2 3.8s-.3.1-.4.3zm3.2-2.1-3.7-4.4c2.5-3.2 5.8-8.4 9.7-15.7l11.1 13.3c-7.8 2.5-13.5 4.9-17.1 6.8z" />
            </svg>
        </i>
    );
};
