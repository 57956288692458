import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Zodiac as ZodiacAPI } from '../../Api.js';

import style from './style.module.scss';

const Horoscope = ({ logged }) => {
    const { sign } = useParams();
    const [item, setItem] = useState(null);

    useEffect(() => {
        ZodiacAPI.get('', {
            params: {
                fields: '*,image.data.full_url',
                'filter[slug]': sign,
            },
        })
            .then(({ data }) => {
                setItem(data.data[0]);
                if (window.gigya) {
                    window.gigya.accounts.showScreenSet({
                        screenSet: process.env.REACT_APP_GIGYA_SECREEN_SET,
                        startScreen: 'gigya-register-screen-complete',
                        containerID: 'horoscope-register-container',
                    });
                }
            })
            .catch((error) => {
                console.log('Error fetching data');
            });
    }, [sign]);

    return (
        <div className={style.wrapper}>
            <Helmet>
                <title>Oroscopo | DonnaD</title>
            </Helmet>
            {!!item && (
                <div className={style.content}>
                    <div>
                        <div className={style.body}>
                            <h2>{item.name}</h2>
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                        <div className={style.image}>
                            <div>
                                <img src={item.image.data.full_url} alt={item.name} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!!item && !logged && (
                <div className={style.login}>
                    <div id="horoscope-register-container"></div>
                </div>
            )}
        </div>
    );
};

export default Horoscope;
