import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
//import Maintenance from './Maintenance';
import { isTouch, isSafari } from './utils';

import { debugContextDevtool } from 'react-context-devtool';

import * as serviceWorker from './serviceWorker';

window.awsConf = {
    domain: `${process.env.REACT_APP_AWS_DOMAIN}`,
    env: `${process.env.REACT_APP_AWS_ENV}`,
};



ReactDOM.render(<App />, document.getElementById('root'));
//ReactDOM.render(<Maintenance />, document.getElementById('root'));



if (!isSafari()) {
    serviceWorker.register({
        onUpdate: (reg) => {
          const registrationWaiting = reg.waiting;
            console.log(reg);
            if (registrationWaiting) {
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
                registrationWaiting.addEventListener('statechange', (e) => {
                    if (e.target.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        },
    });
}

if (!isTouch) {
    document.body.classList.add('no-touch');
} else {
    document.body.classList.add('is-touch');
}

if (window._REACT_CONTEXT_DEVTOOL) {
    debugContextDevtool(document.getElementById('root'), {
        disable: process.env.NODE_ENV === 'production',
    });
}
