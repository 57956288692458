import React, { useEffect, useState, useContext } from 'react';
import style from './style.module.scss';

const AccordionComponent = ({ header, Component, items, points, preferredRewardId, updateCardRewardsAccordion }) => {

    const [isOpen, setIsOpen] = useState(true);

    const updateCardRewardsAccordionInner = () => {
        updateCardRewardsAccordion();
    };

    return (
        <div className={style.accordion_container}>
            <div className={style.accordion_icon}>
                
            </div>
            <button className={isOpen ? style.accordion + ' ' + style.active : style.accordion} onClick={() => setIsOpen(!isOpen)}>
                <span>{header}</span>
                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g transform={isOpen ? '' : 'rotate(180 8 5.5)'}>
                        <path d="M2.59375 10.1484L0.84375 8.39844L8.34375 0.898438L15.8438 8.39844L14.0938 10.1484L8.34375 4.39844L2.59375 10.1484Z" fill="white"/>
                    </g>
                </svg>
            </button>
            <div className={isOpen ? style.panel + ' ' + style.active : style.panel}>
                {items.length>0 && items.map((item,j) =>                                          
                    <Component  key={j} item={item} points={points} preferredRewardId={preferredRewardId} updateCardRewards={updateCardRewardsAccordionInner}/>                                           
                )}
                {items.length==0 && <div className={style.empty}>Desideri monitorare il tuo premio preferito? È facilissimo! Clicca sulla stellina accanto al premio che ti interessa nel catalogo per salvarlo come tuo obiettivo. Potrai così accedervi facilmente in qualsiasi momento e vedere quanti punti D ti mancano per poterlo richiedere.</div>}
            </div>
        </div>
    );
};

export default AccordionComponent;
