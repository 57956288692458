import React from 'react';
import { Link } from "react-router-dom";
import { ArrowBack, Share, Bookmark, Bookmarked, Gear } from '../Icons';
import style from './style.module.scss';

const ArticleHeader = ({article,loggedIn,onBookmarkClick,onShareClick,settings,onSettingsClick}) => {

    const getBookmark = () => {
        if (article && article.data && !!article.data.bookmarked) {
            return <Bookmarked/>
        } else if(article) {
            return <div><Bookmark/><span>Salva l’articolo ne "I miei segnalibri"</span></div>;
        }
    }

    return (
        <div className={style.el}>
            <Link className={style.back} to={`/`}><ArrowBack/></Link>
            <ul className={style.controls}>
                {loggedIn && <li onClick={onBookmarkClick}>{getBookmark()}</li>}
                {article && <li onClick={onShareClick}><Share/><span>Condividi l'articolo</span></li>}
                {settings && !!localStorage.getItem('fbToken') && <li onClick={onSettingsClick}><Gear/></li>}
            </ul>
        </div>
    );
}

export default ArticleHeader;


