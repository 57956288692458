import React, { useState } from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

import LazyImg from '../LazyImg';
import { Info } from '../Icons';

const PrizeCard = ({ image, points, text, title, active }) => {
    const [cardOnRead, setCardOnRead] = useState(active);

    const classes = classNames([style.card], {
        [style.active]: !!cardOnRead,
    });

    return (
        <div className={classes}>      
            <div className={style.wrapper}>
                {image && <LazyImg src={image} alt="image" />}
                {points && (
                    <p className={style.points}>
                        {points} <span>punti</span>
                    </p>
                )}
                {/* <div className={style.content}>
                    <div className={style.wrap}>
                        {title && (
                            <div
                                className={style.title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            ></div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                </div> */}
            </div>      
        </div>       
    );
};

export default PrizeCard;
