/*eslint constructor-super: 2*/
/*eslint-disable constructor-super*/

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import 'react-datetime/css/react-datetime.css';
import 'moment/locale/it';

import SwiperCore, { Pagination, Parallax } from 'swiper';

import style from './style.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import LazyImg from '../LazyImg';

import { Close, Minus, Info } from '../Icons';

SwiperCore.use([Pagination, Parallax]);

class SocialShare extends React.Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            showMessage: false,
            isMobile: window.innerWidth < 1024,
        };
        this.text = "Hey, unisciti alla Dfamily, il programma fedeltà DonnaD! Guadagna punti D per ottenere fantastici premi. È semplice: basta registrarsi su DonnaD usando questo link e aderire poi alla Dfamily. Non perdere questa opportunità!";
    }


    componentDidUpdate(prevProps) {
        if (prevProps.context.state.socialshare !== this.props.context.state.socialshare) {
            if (!!this.props.context.state.socialshare) {
                disableBodyScroll(this.wrapperRef.current);
            } else {
                enableBodyScroll(this.wrapperRef.current);
            }
        }
    }

    copyToClipoboard(url){
        navigator.clipboard.writeText(url);
        this.showMessage = true;
        this.setState({showMessage: true});
        setTimeout(() => {
            this.setState({showMessage: false});
        },2000);       
    }

    render() {
        const { state, dispatch } = this.props.context;
        return (
            <React.Fragment>
                <CSSTransition in={state.socialshare} timeout={200} classNames="popup" unmountOnExit>
                    <div className={style.wrapper} ref={this.wrapperRef}>
                        <div className={style.container}>
                            <div className={style.inner}>
                                <span
                                    className={style.close}
                                    onClick={() => dispatch({ type: 'TOGGLE_SOCIALSHARE', payload: { url: null } })}
                                >
                                    <Close />
                                </span>                                
                                <div className={style.section}>
                                    <h3 className={style.title}>Condividi con i tuoi amici il link e invitali a unirsi alla Dfamily di DonnaD per scoprire un mondo di premi fantastici!</h3>
                                    {this.state.showMessage? <p className={style.message}>Copiato!</p>:''}
                                    <div className={style.socials}>
                                        <div className={style.social}>
                                            <a href={"https://api.whatsapp.com/send/?text="+encodeURIComponent(this.text)+"+"+encodeURIComponent(state.url)+"type=custom_url&app_absent=0" + encodeURIComponent(state.url)} target="_blank" rel="noopener noreferrer">
                                                <LazyImg src="/imgs/socials/share-whatsapp.png" alt="whatsapp" />
                                            </a>
                                        </div>
                                        <div className={style.social}>
                                            {!this.state.isMobile?
                                                <a href={"http://www.facebook.com/dialog/send?app_id=377735531386088&link=" + encodeURIComponent(state.url) + "&redirect_uri=https://www.my.donnad.it/" + "&message=" + encodeURIComponent(this.text)} target="_blank" rel="noopener noreferrer">
                                                    {/* DESKTOP */}
                                                    <LazyImg src="/imgs/socials/share-facebook.png" alt="facebook" />   
                                                </a>:
                                                <a href={"fb-messenger://share/?link=" + encodeURIComponent(state.url) + "&app_id=" +encodeURIComponent("377735531386088") + "&redirect_uri=" + encodeURIComponent("https://www.my.donnad.it/") + "%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=" + encodeURIComponent("377735531386088")  + "&message=" + encodeURIComponent(this.text)} target="_blank" rel="noopener noreferrer">
                                                    {/* MOBILE */}                                        
                                                    <LazyImg src="/imgs/socials/share-facebook.png" alt="facebook" />   
                                                </a>
                                            }
                                        </div>
                                        <div className={style.social}>
                                            <a onClick={() => {this.copyToClipoboard(state.url)}}>
                                                <LazyImg src="/imgs/socials/share-copy.png" alt="copy" /> 
                                            </a>
                                        </div>
                                    </div>
                                {/* https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.growpla.com%2Fadmin%2Fusers%2F1&hashtag=%23growpla& */}
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }
}

export default SocialShare;
