import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../contexts/appContext';
import style from './style.module.scss';
import { Helmet } from 'react-helmet';
import { UserCustom as UserCustomAPI, Loyalty as LoyaltyAPI } from '../../Api.js';
import { concat } from 'lodash';

import ArticleListPreview from '../../components/ArticleListPreview';
import HeadingPage from '../../components/HeadingPage';
import PointsCard from '../../components/PointsCard';
import Cta from '../../components/Cta';
import Footer from '../../components/Footer';
import RewardCard from '../../components/RewardCard';
import Title from '../../components/Title';

const Activities = () => {
    const { state, dispatch } = useContext(AppContext);
    const [userPoints, setUserPoints] = useState(null);
    const [loadMore, setLoadMore] = useState(1);
    const [activities, setActivities] = useState([]);
    const [activitiesAviable, setActivitiesAvaiable] = useState(true);
    const [loading, setLoading] = useState(true);

    const actions = {
        'welcome': {
            title: 'Hai aderito al programma fedeltà Dfamily',
            description: 'Hai guadagnato <b>_points_ punti D</b> di benvenuto',
            image:
                'https://static.donnad.it/asset/actions/welcome.png?' +
                Math.floor(Math.random() * 100000),
        },
        'member get member': {
            title: 'Hai invitato un amico',
            description:
                'Per ogni amico invitato che ha aderito al programma Dfamily, hai guadagnato <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/member_get_member.png?' +
                Math.floor(Math.random() * 100000),
        },
        'receipt accepted': {
            title: 'Hai caricato uno scontrino',
            description:
                'Per ogni scontrino caricato e validato, hai guadagnato <b>_points_ punti D</b> <br>Vai all’archivio dei tuoi scontrini: <a href="https://my.donnad.it/scontrini"> https://my.donnad.it/scontrini</a>',
            image:
                'https://static.donnad.it/asset/actions/receipt.png?' +
                Math.floor(Math.random() * 100000),
        },
        'survey completed': {
            title: 'Hai risposto alle nostre domande',
            description:
                'Per ogni questionario relativo al programma fedeltà che hai compilato, hai guadagnato <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/survey.png?' +
                Math.floor(Math.random() * 100000),
        },
        'points login donnad': {
            title: 'Nelle ultime due settimane, hai effettuato l’accesso su DonnaD almeno una volta',
            description:
                'Hai guadagnato <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/iniziative.png?' +
                Math.floor(Math.random() * 100000),
        },
        'Loyalty Member Celebration': {
            title: 'La Dfamily ha festeggiato con te 6 mesi dall’adesione al programma fedeltà',
            description:
                'Come regalo, ti ha premiato con <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/six-mounts-loyalty.png?' +
                Math.floor(Math.random() * 100000),
        },

        'profile completed': {
            title: 'Grazie per aver completato il tuo profilo!',
            description:
                'Hai guadagnato <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/completa-profilo.png?' +
                Math.floor(Math.random() * 100000),
        },

        'Scratch game': {
            title: 'Hai giocato  allo Specchio Magico ',
            description:
                'Hai guadagnato <b>_points_ punti D</b>',
            image:
                'https://static.donnad.it/asset/actions/specchio-magico.png?' +
                Math.floor(Math.random() * 100000),
        },
    };

    const loadActivities = () => {
        if (!state.user || loadMore === null) {
            return;
        }
        UserCustomAPI.get('/history', {
            params: {
                _page: loadMore,
                _itemsOnPage: 10,
                type: 'PointsWereAdded',
            },
        })
            .then(({ data }) => {
                console.log(data);
                if(data.items.length === 0){
                    setActivitiesAvaiable(false);
                }else{
                    setActivities((activities) => concat(activities, data.items));
                    data.total.filtered
                    ? data.total.filtered / 10 == 0
                        ? setLoadMore(null)
                        : setLoadMore(loadMore + 1)
                    : setLoadMore(null);
                }          
            })
            .then(() => {
                setLoading(false);
            });
    };

    const dynamicDescription = (description, points) => {   
        return description.replace("_points_", points);
    };

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'prizes' } });

        setLoading(true);

        if (state.user) {
            LoyaltyAPI.get('/status').then(({ data }) => {
                setUserPoints(data.data.points);
            });
            loadActivities();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Trasforma i tuoi punti in premi | DonnaD</title>
            </Helmet>
            <div className="content">
                <HeadingPage
                    title="<h1>Le tue attività</h1>"
                    text="Tieni d'occhio le tue attività e il tuo saldo punti per richiedere i premi che preferisci."
                />

                <div className={style.pointscard}>
                    <PointsCard
                        modifiers={['centered']}
                        label={`Ciao ${state.user.firstName},<br />Il tuo saldo punti è`}
                        value={userPoints}
                        texture="centered"
                    />
                </div>

                <div className={style.activities}>
                    {activities.length > 0 ?   <Title title='<b style="display:block;margin-top:30px;text-align: center;">Hai compiuto le seguenti azioni:</b>'></Title> : ''}
                    <div className={style.cardContainer}>
                        {!loading ? (
                            activities.length > 0 ? (
                                activities.map(
                                    (ac, j) =>
                                        ac.flatVariables.comment &&
                                        ac.flatVariables.comment != 'mmm' &&
                                        actions[ac.flatVariables.comment] && (
                                            <RewardCard
                                                key={j}
                                                page="activities"
                                                image={actions[ac.flatVariables.comment].image}
                                                name={actions[ac.flatVariables.comment].title}
                                                description={dynamicDescription(
                                                    actions[ac.flatVariables.comment].description,
                                                    ac.flatVariables.points
                                                )
                                                }
                                                backgroundColor="#e1000f"
                                            ></RewardCard>
                                        )
                                )
                            ) : (
                                <div className={style.nores}>
                                    <p>
                                        Sembra che tu non abbia ancora completato delle attività.
                                        <br />
                                        Inizia subito a collezionare punti per riscattare fantastici
                                        premi!
                                    </p>
                                    <Cta
                                        label="Come guadagnare punti"
                                        url="/come-guadagnare-punti"
                                    />
                                </div>
                            )
                        ) : (
                            <ArticleListPreview />
                        )}
                        
                        {activitiesAviable? 
                            activities.length > 0 ? 
                            (<Cta label="Mostra di più" onClick={loadMore && loadActivities} />) : ('')
                        : <Title title='<b style="display:block;margin-top:30px;text-align: center;">Non ci sono altre azioni</b><br><br>'></Title>}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Activities;
